import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../common/ui/Field';
import Select from '../../common/ui/Select';
import ErrorMessage from '../../common/ui/ErrorMessage';

const InvoiceForm = (props) => {
  const { setFieldValue, invoice, companies, edit } = props;

  return (
    <Container>
      <Row>
        <Col>
          <div className="form-group row" style={{ alignItems: 'center' }}>
            <Field
              name="order_number"
              type="text"
              label="Order Number"
              placeholder="Mandatory"
              labelStyle={{ marginTop: '8px', width: '100px' }}
              disabled={edit}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <label className="form-field-label no-padding" style={{ marginTop: '8px', width: '15%' }}>
              Company
            </label>
            <Select
              options={companies.map((company) => {
                /* istanbul ignore next */
                return { value: company.id, label: company.name };
              })}
              onChange={(company) => {
                /* istanbul ignore next */
                setFieldValue('company', { value: company.value, label: company.label });
              }}
              className="col-sm-9 no-padding"
              value={invoice.company}
              disabled={edit}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="invoiced_at"
              type="date"
              label="Invoiced at"
              placeholder="Mandatory"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="due_at"
              type="date"
              label="Due at"
              placeholder="Mandatory"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      {edit &&
        <Row>
          <Col>
            <div className="form-group row" style={{ alignItems: 'center' }}>
              <label className="form-field-label no-padding" style={{ marginTop: '8px', width: '15%' }}>
                Payment Status
              </label>
              <Select
                options = {[
                  { value: null, label: "None" },
                  { value: "paid", label: "Paid" },
                  { value: "unpaid", label: "Unpaid" },
                  { value: "partial", label: "Partial" },
                ]}
                onChange={(status) => {
                  /* istanbul ignore next */
                  setFieldValue('payment_status', { value: status.value, label: status.label });
                }}
                className="col-sm-9 no-padding"
                value={invoice.payment_status}
              />
            </div>
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="total"
              type="number"
              label="Total (SGD)"
              placeholder="Mandatory"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="document_url"
              type="url"
              label="Invoice URL"
              placeholder="Mandatory"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoiceForm;
