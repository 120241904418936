import React from 'react';
import Table from '../../common/ui/Table';
import Invoice from './Invoice';

const InvoicesComponent = ({ collection, company }) => {
  return (
    <Table>
      <thead>
        <tr>
          <td>Order Number</td>
          <td>Reference</td>
          <td>Invoiced at</td>
          <td>Due at</td>
          <td>Status</td>
          <td>Total</td>
          <td>Invoice</td>
        </tr>
      </thead>
      <tbody>
        {collection.map((invoice) => (
          <Invoice invoice={invoice} company={company} />
        ))}
      </tbody>
    </Table>
  );
};

export default InvoicesComponent;
