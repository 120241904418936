const rules = {
  seller: {
    static: [
      'Dashboard:list',
      'MyProfile:list',
      'OrdersAndSales:list',
      'Products:list',
      'InventoryAndShipments:list',
      'AccountingAndPayment:list',
      'Marketing:list',
      'Delivery:list',
      'ContactAndFaq:list',
    ],
    dynamic: {
      /*"posts:edit": ({userId, postOwnerId}) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
        */
    },
  },
  admin: {
    static: [
      'Dashboard:list',
      'MyProfile:list',
      'OrdersAndSales:list',
      'Products:list',
      'InventoryAndShipments:list',
      'AccountingAndPayment:list',
      'Marketing:list',
      'Delivery:list',
      'ManageUsers:list',
      'ContactAndFaq:list',
      'AdminTools',
    ],
    dynamyc: {},
  },
};

export default rules;
