import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faQuestionCircle,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import NavLink from './NavLink';

const BottomLinks = ({ user, location, company, handleLogout, reduced }) => {
  if (user && user.validated) {
    return (
      <>
        {company && !reduced && (
          <h5
            style={{
              color: 'white',
              textAlign: 'center',
              paddingBottom: '10px',
              borderBottom: '1px solid white',
              maxWidth: '100%',
              overflowWrap: 'break-word',
            }}
          >
            {company.name}
          </h5>
        )}
        <NavLink
          user={user}
          location={location}
          perform="MyProfile:list"
          path="/users/edit"
          Icon={
            <FontAwesomeIcon
              icon={faUser}
              size="25"
              style={{ marginRight: '10px', minWidth: '20px' }}
            />
          }
          label="Profile"
        />
        <NavLink
          user={user}
          location={location}
          perform="ContactAndFaq:list"
          path="/contact_and_faq"
          Icon={
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="20"
              style={{ marginRight: '10px', minWidth: '20px' }}
            />
          }
          label="Contact Us"
        />
        <div className="navbar-nav">
          <Button
            className="nav-link"
            variant="link"
            onClick={(_) => handleLogout()}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="20"
              style={{ marginRight: '10px', minWidth: '20px' }}
            />
            <span className="nav-text">Log out</span>
          </Button>
        </div>
      </>
    );
  }
  return (
    <div className="navbar-nav">
      <Button
        className="nav-link"
        variant="link"
        onClick={(_) => handleLogout()}
      >
        <FontAwesomeIcon
          icon={faSignOutAlt}
          size="20"
          style={{ marginRight: '10px', minWidth: '20px' }}
        />
        <span className="nav-text">Log out</span>
      </Button>
    </div>
  );
};

export default BottomLinks;
