import React, { useState, useEffect } from "react";
import PaginateComponent from '../../common/ui/PaginateComponent';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../../common/ui/Button';
import { createNotification } from '../../common/notifications';
import InvoicesService from "../../../services/data/invoices_service";
import CompaniesService from "../../../services/data/companies_service";
import InvoicesTable from './InvoicesTable';
import InvoicesNew from './InvoicesNew';
import InvoiceEdit from './InvoiceEdit';
import InvoicesSearch from './InvoicesSearch';

const InvoicesComponent = (props) => {
    const [invoices, setInvoices] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loadingInvoices, setLoadingInvoices] = useState(true);
    const [showCreateInvoice, setShowCreateInvoice] = useState(false);
    const [showEditInvoice, setShowEditInvoice] = useState(false);
    const [invoiceId, setInvoiceId] = useState();
    const [searchTerm, setSearchTerm] = useState("");

    /* istanbul ignore next */
    const setLocalInvoices = (invoices) => {
        setInvoices(invoices);
    };

     /* istanbul ignore next */
    const setSearchField = (term) => {
        setSearchTerm(term);
    };

    /* istanbul ignore next */
    const loadInvoices = () => {
        InvoicesService.loadInvoices()
          .then((response) => {
            setInvoices(response.data.invoices);
            setLoadingInvoices(false);
            setAllInvoices(response.data.invoices);
          })
          .catch((error) => {
            console.log(error);
            setLoadingInvoices(false);
          });
    };

    /* istanbul ignore next */
    const loadCompanies = () => {
        CompaniesService.loadCompanies()
            .then((response) => {
                setCompanies(response.data.companies);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    /* istanbul ignore next */
    const showCreateInvoiceModal = () => {
        setShowCreateInvoice(true);
    };

    /* istanbul ignore next */
    const closeCreateInvoiceModal = () => {
        setShowCreateInvoice(false);
    };

    /* istanbul ignore next */
    const closeEditInvoiceModal = () => {
        setShowEditInvoice(false);
    };

    /* istanbul ignore next */
    const showEditInvoiceModal = (id) => {
        setShowEditInvoice(true);
        setInvoiceId(id);
    };

    /* istanbul ignore next */
    const handleDelete = (id) => {
        if (window.confirm('Do you really want to delete it')) {
            InvoicesService.deleteInvoice(id)
                .then((response) => {
                    createNotification('success', 'The invoice has been deleted');
                    loadInvoices();
                })
                .catch((error) => console.log(error));
        }
    };

    /* istanbul ignore next */
    const displaySearchBar = () => {
        return(
            <Row style={{ marginBottom: '15px' }}>
                <Col>
                    <InvoicesSearch
                        collection={invoices}
                        allCollection={allInvoices}
                        setCollection={setLocalInvoices}
                        setSearchField={setSearchField}
                        searchField={searchTerm}
                    />
                </Col>
            </Row>
        );
    };

    /* istanbul ignore next */
    const displayInvoices = () => {
        if (loadingInvoices) {
            return <div>Loading Invoices</div>;
        } 
        else {
            if (invoices && invoices.length > 0) {
                return (
                    <PaginateComponent
                        componentList={
                            <InvoicesTable
                                showEditInvoiceModal={showEditInvoiceModal}
                                handleDelete={handleDelete}
                            />
                        }
                        list={invoices}
                        perPage={10}
                    />
                );
            } 
            else {
                return <div>No Invoices</div>;
            }
        }
    };

    useEffect(() => {
        loadInvoices();
        loadCompanies();
    }, []);

    return (
        <Container fluid style={{ marginTop: '15px' }}>
          {displaySearchBar()}
          <Row>
            <Col>
              <Button
                variant="primary"
                style={{ marginBottom: '10px' }}
                onClick={() => showCreateInvoiceModal()}
              >
                Create Invoice
              </Button>
              <InvoicesNew
                showCreateInvoice={showCreateInvoice}
                closeCreateInvoiceModal={closeCreateInvoiceModal}
                loadInvoices={loadInvoices}
                companies={companies}
              />
              <InvoiceEdit
                showEditInvoice={showEditInvoice}
                closeEditInvoiceModal={closeEditInvoiceModal}
                invoiceId={invoiceId}
                loadInvoices={loadInvoices}
                companies={companies}
              />
              {displayInvoices()}
            </Col>
          </Row>
        </Container>
    );
};

export default InvoicesComponent;