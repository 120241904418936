import React from 'react';
import { Redirect } from 'react-router-dom';
import InnerLayout from '../layout/InnerLayout';
import { Container, Row, Col } from 'react-bootstrap';
import Can from '../roles/Can';
import Contact from './Contact';
import { Link } from 'react-router-dom';
import Faq from './Faq';

const ContactAndFaqComponent = (props) => {
  const { logOut, user, location, company } = props;
  if (user) {
    return (
      <>
        <Can
          role={user.type.toLowerCase()}
          perform="ContactAndFaq:list"
          yes={() => (
            <InnerLayout
              logOut={logOut}
              user={user}
              location={location}
              company={company}
            >
            <Row>
                <Col md="0" lg="2"></Col>
                <Col md="12" lg="8">
                  <p style={{paddingLeft: 15}} >Have you visited our 
                    <Link to="/users/edit#user-guide">{' User Guide'}{' '}</Link>
                    section?
                  </p>
                </Col>
                <Col md="0" lg="2"></Col>
              </Row>
              <Row>
                <Col md="0" lg="2"></Col>
                <Col md="12" lg="8">
                  <Contact />
                </Col>
                <Col md="0" lg="2"></Col>
              </Row>
            </InnerLayout>
          )}
          no={() => <Redirect to="/no_permissions" />}
        />
      </>
    );
  }
  /* istanbul ignore next */
  return null;
};

export default ContactAndFaqComponent;
