import axios from '../axios';
import BaseService from "./base_service";

/**
 * Class to manage requests to backend related to dashboard
 */
class DashboardService extends BaseService{
  /**
   * Gets dashboard data.
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getData(startDate = null, endDate = null, dateScope = "week") {
    return axios.get('/dashboard',  {params: { date_from: startDate, date_to: endDate, date_scope: dateScope }});
  }
}

export default DashboardService;
