import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../common/ui/Modal';
import Form from './AgreementEditForm';
import CompaniesService from '../../../services/data/companies_service';
import { createNotification } from '../../common/notifications';

const AgreementEditSchema = Yup.object().shape({
  name: Yup.string(),
  document: Yup.string(),
});

const AgreementEdit = ({
  showEditAgreement,
  closeEditAgreementModal,
  loadAgreements,
  company,
}) => {
  const handleUpdateSubmit = (values, actions) => {
    CompaniesService.updateAgreement(company.id, values)
      .then((response) => {
        actions.setSubmitting(false);
        createNotification('success', 'has been created', 'The agreement');
        loadAgreements();
        closeEditAgreementModal();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        createNotification(
          'error',
          'was not possible to create',
          'The agreement'
        );
        closeEditAgreementModal();
      });
  };

  return (
    <Formik
      initialValues={{
        name: company.name,
        document: '',
      }}
      validationSchema={AgreementEditSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleUpdateSubmit(values, actions);
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeEditAgreementModal}
          onCancel={closeEditAgreementModal}
          title="Edit Agreement"
          show={showEditAgreement}
        >
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            values={values}
            company={company}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default AgreementEdit;
