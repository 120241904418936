import React, { useState } from 'react';
import { formattedDate } from '../../common/dataTypes/dates';
import { formattedPrice } from '../../common/dataTypes/prices';
import PurchaseOrdersRowDetail from './PurchaseOrdersRowDetail';
import { Link } from 'react-router-dom';

const PurchaseOrdersTableRow = ({
    order,
    orderLineItems,
    products,
    companies,
    getCount,
    getAmount,
    getProductBySku,
}) => {
    const [visibleDetail, setVisibleDetail] = useState(false);

    const displayRow = () => {
        return(
            <>
                <tr className="orderRow" key={order.id} onClick={() => setVisibleDetail(!visibleDetail)}>
                    <td>{formattedDate(order.issued_at)}</td>
                    <td>{companies?.name}</td>
                    <td><Link to={`/purchase_orders/${order.id}`}>{order.order_number}</Link></td>
                    <td>{order.status}</td>
                    <td>{count}</td>
                    <td>{formattedPrice(getAmount(orderLineItems), 'sgd')}</td>
                </tr>
                <PurchaseOrdersRowDetail
                    visible={visibleDetail}
                    getProductBySku={getProductBySku}
                    orderLineItems={orderLineItems}
                    products={products}
                    count={getCount(orderLineItems)}
                />
            </>
        );
    };

    let count = getCount(orderLineItems);

    if (count > 0){
        return displayRow();
    }

    return null;
};

export default PurchaseOrdersTableRow;