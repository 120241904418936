import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Tabs, Tab } from 'react-bootstrap';
import * as Yup from 'yup';
import LoginService from '../../services/login';
import { createNotification } from '../common/notifications';
import Can from '../roles/Can';
import InnerLayout from '../layout/InnerLayout';
import Form from './EditUserForm';
import {
  MyCompany,
  MyAddresses,
  MyContacts,
  MyAccounting,
  MyAccountActivation,
} from './myProfile/MyProfileFiles';
import MyRegulatory from './myProfile/MyRegulatory';
import MyAgreement from './myProfile/MyAgreement';

const EditUserSchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string(),
  password_confirmation: Yup.string(),
  current_password: Yup.string(),
  name: Yup.string(),
  title: Yup.string(),
  phone: Yup.string(),
  passport: Yup.mixed(),
});

const EditUserComponent = (props) => {
  const [email, setEmail] = useState('');
  const [password] = useState('');
  const [passwordConfirmation] = useState('');
  const [currentPassword] = useState('');
  const [role, setRole] = useState('');
  const [name] = useState('');
  const [title] = useState('');
  const [phone] = useState('');
  const [passport] = useState('');
  const { user, logOut, location, company } = props;
  const myCompanyId = "company";
  const defaultTab = location.hash.substring(1) || myCompanyId;

  /* istanbul ignore next */
  const handlePasswordSubmit = (values, actions) => {
    LoginService.changePassword(values)
      .then((response) => {
        if (response.status === 200) {
          createNotification(
            'success',
            'You have changed your profile settings'
          );
        }
      })
      .catch((error) => {
        createNotification('danger', 'Update cannot be done');
      });
  };

  /* istanbul ignore next */
  useEffect(() => {
    LoginService.getUser().then((response) => {
      setEmail(response.data.email);
      setRole(response.data.type);
    });
  }, []);

  if (user) {
    return (
      <>
        <Can
          role={user.type.toLowerCase()}
          perform="MyProfile:list"
          yes={() => (
            <InnerLayout
              logOut={logOut}
              user={user}
              location={location}
              company={company}
            >
              <Tabs defaultActiveKey={defaultTab} id="edit_user_tab">
                <Tab eventKey={myCompanyId} title="My Company">
                  <MyCompany company={company} />
                </Tab>
                <Tab eventKey="contacts" title="Contacts">
                  <MyContacts company={company} />
                </Tab>
                <Tab eventKey="addresses" title="Addresses">
                  <MyAddresses />
                </Tab>
                <Tab eventKey="finance" title="Finance">
                  <MyAccounting company={company} />
                </Tab>
                <Tab eventKey="agreement" title="Agreement">
                  <MyAgreement company={company} />
                </Tab>
                <Tab eventKey="login" title="My Login">
                  <Formik
                    initialValues={{
                      user: {
                        email: email || '',
                        password: password || '',
                        password_confirmation: passwordConfirmation || '',
                        current_password: currentPassword || '',
                        name: name || '',
                        title: title || '',
                        phone: phone || '',
                        passport: passport || '',
                      },
                    }}
                    enableReinitialize
                    validationSchema={EditUserSchema}
                    onSubmit={(values, actions) =>
                      handlePasswordSubmit(values, actions)
                    }
                  >
                    {({ isSubmitting, values, setFieldValue, submitForm }) => (
                      <Form
                        isSubmitting={isSubmitting}
                        values={values}
                        role={role}
                        setFieldValue={setFieldValue}
                        handleSubmit={submitForm}
                      />
                    )}
                  </Formik>
                </Tab>
                <Tab eventKey="user-guide" title="User Guide">
                  <MyAccountActivation />
                </Tab>
              </Tabs>
            </InnerLayout>
          )}
          no={() => <Redirect to="/" />}
        />
      </>
    );
  }
  /* istanbul ignore next */
  return null;
};

EditUserComponent.propTypes = {
  currentUser: PropTypes.object,
};

export default EditUserComponent;
