import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import reducers, { DEFAULT_STATE } from './reducers/reducers';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import AppContainer from './components/AppContainer';
import { NotificationContainer } from 'react-notifications';

const App = (props) => {
  const { signedIn, resetPasswordToken, currentUser } = props;
  let middleware = [thunk];
  if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
  }
  const store = createStore(
    reducers,
    { ...DEFAULT_STATE, ...props },
    applyMiddleware(...middleware)
  );

  return (
    <Provider store={store}>
      <NotificationContainer />
      <AppContainer
        signedIn={signedIn}
        resetPasswordToken={resetPasswordToken}
        currentUser={currentUser}
      />
    </Provider>
  );
};

App.propTypes = {
  signedIn: PropTypes.any,
  resetPasswordToken: PropTypes.string,
  currentUser: PropTypes.object,
};

export default App;
