/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { css, jsx } from '@emotion/core';
import StorageService from '../../services/storage_service';
import Sidebar from './Sidebar';
import useReducedSidebar from '../../hooks/useReducedSidebar';
import {contentContainerStyles, contentSuperiorStyles, wrapperStyles} from './innerLayoutStyles';

const InnerLayoutComponent = (props) => {
  const { user, location, company, logOut } = props;
  const { reduced, setReduced } = useReducedSidebar();
  const innerClass = `${reduced ? 'expandedInner' : 'reducedInner'}`;

  /* istanbul ignore next */
  const handleLogout = () => {
    StorageService.clearBearer();
    StorageService.clearUserData();
    logOut();
    window.location.href = '/users/sign_in';
  };

  return (
    <div class="d-flex" css={wrapperStyles}>
      <Sidebar
        setReduced={setReduced}
        reduced={reduced}
        user={user}
        company={company}
        location={location}
        handleLogout={handleLogout}
      />
      <Container
        fluid
        className={innerClass}
        style={{
          background: '#E6E6E6',
        }}
        css={css`
          &.reducedInner {
            padding-left: 260px;
          }

          &.expandedInner {
            padding-left: 100px;
          }
        `}
      >
        <Row>
          <Col>
            <Container
              id="content-superior-container"
              fluid
              css={contentSuperiorStyles}
            >
              <Row>
                <Col>
                  <Container
                    fluid
                    id="content-container"
                    css={contentContainerStyles}
                  >
                    <Row>
                      <Col>{props.children}</Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InnerLayoutComponent;
