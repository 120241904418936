import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../common/ui/Label';
import { Container, Row, Col } from 'react-bootstrap';

const OverviewComponent = ({}) => {

  return (
    <Container fluid>
      <Row>
          <Col md="12" lg="8">
              <Label labelKey="delivery.overview"></Label>
          </Col>
          <Col md="12" lg="4"></Col>
      </Row>
    </Container>
  );
};

OverviewComponent.propTypes = {
};

export default OverviewComponent;
