import { css } from '@emotion/core';
export const sidebarStyles = css`
  position: fixed;
  max-height: 100vh;
  background: #0e65ad;
  /* Old browsers */
  background: -moz-linear-gradient(top, #263263 0%, #273d72 38%, #0e65ad 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #263263 0%,
    #273d72 38%,
    #0e65ad 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #263263 0%, #273d72 38%, #0e65ad 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#263263', endColorstr='#0e65ad', GradientType=0);
  /* IE6-9 */

  min-height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 2;
  margin-left: -15rem;

  .collapseButton {
    cursor: pointer;
  }

  .list-group {
    position: relative;
    top: -20px;
    width: 15rem;
  }

  .list-bottom {
    position: fixed;
    bottom: 0px;
    width: 15rem;
    button {
      text-align: left !important;
    }
  }

  .nav-link {
    color: white !important;
    padding: 10px 20px;
    &.active {
      background-color: white !important;
      color: #0e65ad !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  &.reduced {
    text-align: center;
    .sidebar-heading {
      padding: 5px 0px;
    }

    .sidebar-heading {
      margin-left: 8px;
      min-height: 88px;
    }

    .nav-link.active {
      background-color: transparent !important;
      color: white !important;
    }

    .navbar-nav, .list-group, .list-bottom {
      width: 65px;
    }

    .nav-link .nav-text {
      display: none;
    }

    .nav-link svg {
      margin-right: 0px !important;
      margin-left: 8px !important;
    }

    .list-bottom {
      button {
        text-align: center !important;
      }
    }
  }
  @media (min-width: 768px) {
    & {
      margin-left: 0;
    }
  }

  @media (max-height: 500px) {
    height: 700px;
    scroll-behavior: smooth;
    overflow: scroll;

    .list-bottom {
      position: static;
    }
  }
`;
