import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { formattedDate } from '../../common/dataTypes/dates';
import Table from '../../common/ui/Table';
import Link from '../../common/ui/Link';

const InventoryTableComponent = (props) => {
  const { collection, loadProductsService } = props;

  const displayProducts = (products) => {
    if (products) {
      return products.map((product) => (
        <tr key={product.id}>
          <td>{product.seller_sku_code}</td>
          <td><Link to={`/products/${product.product_id}`}>{product.fluetis_sku_code}</Link></td>
          <td>{product.name}</td>
          <td>{formattedDate(product.expiry_date)}</td>
          <td>{product.full_stock}</td>
          <td>{product.quantity}</td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan="5">No products</td>
      </tr>
    );
  };

  return (
    <>
      <Row>
        <Col style={{ padding: '0px' }}>
          <Table>
            <thead>
              <tr>
                <th>Seller SKU Code</th>
                <th>Fluetis SKU Code</th>
                <th>Name</th>
                <th>Expiry Date</th>
                <th>Full Stock</th>
                <th>Quantity available</th>
              </tr>
            </thead>
            <tbody>{displayProducts(collection)}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

InventoryTableComponent.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InventoryTableComponent;
