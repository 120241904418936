import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

/**
 * Field to choose valid unit of measure
 * @param name Field name
 * @param className Field className attribute
 * @param noCol
 * @returns {JSX.Element}
 * @constructor
 */

 const MeasureOptions = [
  { value: 'ml', label: 'ML' },
  { value: 'g', label: 'G' },
  { value: 'pcs', label: 'PCS' }
];

const UnitOfMeasureSelectorInputComponent = ({
  className,
  onChangeHandler,
  style,
  value
}) => {
  return (
    <Select
      className={`${className}`}
      options={MeasureOptions}
      onChange={onChangeHandler}
      value={MeasureOptions.find(mo => mo.value === value)}
    />
  );
};

UnitOfMeasureSelectorInputComponent.propTypes = {
  onChangeHandler: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  noCol: PropTypes.number,
};

export default UnitOfMeasureSelectorInputComponent;
