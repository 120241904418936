import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Table from '../../common/ui/Table';
import Button from '../../common/ui/Button';
import { formattedBoolean } from '../../common/dataTypes/boolean';

const MyContactsTable = (props) => {
  const { collection, showEditContactModal, deleteContact } = props;
  return (
    <Table style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Position</th>
          <th>Name</th>
          <th>Email</th>
          <th>Contact Number</th>
          <th>Access to Fluetis</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {collection.map((contact) => {
          return (
            <tr key={contact.id}>
              <td>{contact.position}</td>
              <td>{contact.first_name}</td>
              <td>{contact.email}</td>
              <td>{contact.phone_number}</td>
              <td>{formattedBoolean(contact.allow_login)}</td>
              <td>
                <Button
                  variant="link"
                  style={{ marginRight: '5px', padding: '9px 12px' }}
                  onClick={() => {
                    /* istanbul ignore next */
                    showEditContactModal(contact.id);
                  }}
                >
                  <FontAwesomeIcon className="editAction" icon={faPencilAlt} />
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    /* istanbul ignore next */
                    deleteContact(contact.id);
                  }}
                >
                  <FontAwesomeIcon className="deleteAction" icon={faTrashAlt} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default MyContactsTable;
