import React from 'react';
import Select from 'react-select';

const MySelect = ({
  name,
  multiple,
  options,
  value,
  onChange,
  className,
  id,
  defaultValue,
  disabled
}) => {
  /* istanbul ignore next */
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <Select
      id={id}
      name={name}
      options={options}
      isMulti={multiple}
      defaultValue={defaultValue}
      onChange={handleChange}
      value={value}
      className={multiple ? `basic-multi-select ${className}` : className}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};

export default MySelect;
