import React, { useState } from 'react';
import { Formik, Field, ErrorMessage, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import ShipmentsService from '../../../services/data/shipments_service';
import { createNotification } from '../../common/notifications';
import Modal from '../../common/ui/Modal';
import Button from '../../common/ui/Button';

const schema = Yup.object().shape({
  pallets: Yup.array().of(
    Yup.object().shape({
      height: Yup.number().test(
        'is-empty',
        'value empty',
        (value, context) => typeof value === 'number'
      ).min(1, 'must be greater than 0'),
      width: Yup.number().test(
        'is-empty',
        'value empty',
        (value, context) => typeof value === 'number'
      ).min(1, 'must be greater than 0'),
      length: Yup.number().test(
        'is-empty',
        'value empty',
        (value, context) => typeof value === 'number'
      ).min(1, 'must be greater than 0'),
    })
  ),
});

const PalletizingSizesFormComponent = (props) => {
  const LIMIT = 4;
  const [loading, setLoading] = useState(false);
  const [palletNumber, setPalletNumber] = useState(1);

  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    setLoading(true);
    let pallets = [];
    values.pallets.map((pallet) => {
      pallets.push({
        height: pallet.height === '' ? 0 : pallet.height,
        width: pallet.width === '' ? 0 : pallet.width,
        length: pallet.length === '' ? 0 : pallet.length,
      });
      return null;
    });
    ShipmentsService.setShipmentToFinalized(props.palletizingOrderId, pallets)
      .then((response) => {
        props.reloadComponent();
        setLoading(false);
        props.setShowPalletizingModal(false);
      })
      .catch((error) => {
        setLoading(false);
        createNotification('error', 'Error sending pallets');
        console.log(error);
      });
  };

  /* istanbul ignore next */
  const onCancelPallet = () => {
    props.setShowPalletizingModal();
  };

  return (
    <Formik
      initialValues={{
        pallets: [
          {
            height: 1800,
            width: 80,
            length: 120,
          },
        ],
      }}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Modal
            onHide={onCancelPallet}
            onCancel={onCancelPallet}
            title="Pallet Sizes"
            show={props.showPalletizingModal}
            loading={loading}
          >
            <FieldArray name="pallets">
              {(arrayHelpers) => {
                return (
                  <Container fluid id="pallets-form">
                    <Row>
                      <Button
                        variant="primary"
                        onClick={() => {
                          /* istanbul ignore next */
                          if (palletNumber < LIMIT) {
                            arrayHelpers.push({
                              height: 1800,
                              width: 80,
                              length: 120,
                            });
                            setPalletNumber(palletNumber + 1);
                          }
                        }}
                        disabled={palletNumber === LIMIT}
                      >
                        Add new Pallet
                      </Button>
                    </Row>

                    {values.pallets.map((pallet, index) => {
                      return (
                        <Row key={index}>
                          <Col md="2" style={{ textAlign: 'center' }}>
                            <label></label>
                            <h5>{`Pallet ${index + 1}`}</h5>
                          </Col>
                          <Col md="3">
                            <label>{'Height'}</label>
                            <Field
                              name={`pallets[${index}][height]`}
                              type="number"
                              className="form-control"
                              value={pallet.height}
                              onChange={(event) => {
                                /* istanbul ignore next */
                                let value = event.target.value;
                                /* istanbul ignore next */
                                setFieldValue(
                                  `pallets[${index}][height]`,
                                  value
                                );
                              }}
                            />
                            <ErrorMessage
                              component="small"
                              name={`pallets[${index}][height]`}
                              style={{ color: 'red' }}
                            />
                          </Col>
                          <Col md="3">
                            <label>{'Width'}</label>
                            <Field
                              name={`pallets[${index}][width]`}
                              type="number"
                              className="form-control"
                              value={pallet.width}
                              onChange={(event) => {
                                /* istanbul ignore next */
                                let value = event.target.value;
                                /* istanbul ignore next */
                                setFieldValue(
                                  `pallets[${index}][width]`,
                                  value
                                );
                              }}
                            />
                            <ErrorMessage
                              component="small"
                              name={`pallets[${index}][width]`}
                              style={{ color: 'red' }}
                            />
                          </Col>
                          <Col md="3">
                            <label>{'Length'}</label>
                            <Field
                              name={`pallets[${index}][length]`}
                              type="number"
                              className="form-control"
                              value={pallet.length}
                              onChange={(event) => {
                                /* istanbul ignore next */
                                let value = event.target.value;
                                /* istanbul ignore next */
                                setFieldValue(
                                  `pallets[${index}][length]`,
                                  value
                                );
                              }}
                            />
                            <ErrorMessage
                              component="small"
                              name={`pallets[${index}][length]`}
                              style={{ color: 'red' }}
                            />
                          </Col>
                          <Col md="1">
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ marginTop: '42px', color: 'red' }}
                              onClick={() => {
                                arrayHelpers.remove();
                              }}
                            ></FontAwesomeIcon>
                          </Col>
                        </Row>
                      );
                    })}
                  </Container>
                );
              }}
            </FieldArray>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default PalletizingSizesFormComponent;
