import axios from '../axios';

class PurchaseOrderLineItemsService {

  static getPurchaseOrdersItemsCSV() {
    return axios.request({
      url: '/purchase_order_line_items.csv',
      method: 'GET',
      responseType: 'blob',
    });
  }

  /**
   * Send CSV file to backend for orders bulk creation.
   * @param file Object from input type file
   */
  static loadItemsFromCSV(file) {
    let formData = new FormData();
    formData.append('purchase_order_line_items[purchase_order_line_items_file]', file);
    return axios.post('purchase_order_line_items', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default PurchaseOrderLineItemsService;
