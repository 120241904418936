import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect, useLocation } from 'react-router-dom';
import Can from '../roles/Can';
import FileDownload from '../common/dataTypes/fileDownload';
import ProductService from '../../services/data/products_service';
import PriceListsService from '../../services/data/price_list_service';
import InnerLayout from '../layout/InnerLayout';
import ProductSearch from '../common/ui/Search';
import Button from '../common/ui/Button';
import Link from '../common/ui/Link';
import ProductsTable from './ProductsTable';
import Spinner from '../common/ui/Spinner';
import PaginateComponent from '../common/ui/PaginateComponent';
import AdjustPaginateButtons from '../common/ui/AdjustPaginateButtons';
import GenericModal from '../common/ui/GenericModal';
import { createNotification } from '../common/notifications';
import BulkUpload from '../common/ui/BulkUpload';

const ProductsComponent = ({
  products,
  loadProducts,
  user,
  location,
  company,
  logOut,
}) => {
  const defaultModalState = {
    show: false,
    bodyMessage: '',
    buttons: [
      {
        id: 'accept',
        show: true,
        variant: 'primary',
        text: 'Accept',
      },
      {
        id: 'cancel',
        show: true,
        variant: 'secondary',
        text: 'Cancel',
      },
    ],
  };
  const stateLocation = useLocation();
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [localProducts, setLocalProducts] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [priceLists, setPriceLists] = useState([])
  const [modal, setModal] = useState(defaultModalState);
  const [bulkUploadBegin, setBulkUploadBegin] = useState("");
  const [bulkUploadSummary, setBulkUploadSummary] = useState("");
  const [bulkUploadErrors, setBulkUploadErrors] = useState([]);
  const [bulkUploadEnd, setBulkUploadEnd] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  /* istanbul ignore next */
  const setStateProducts = (newProducts) => {
    setLocalProducts(newProducts);
  };

  /**
   * Reset all modal settings. Usually called on modal hide.
   */
  /* istanbul ignore next */
  const resetModal = () => {
    setModal(defaultModalState);
  };

  /* istanbul ignore next */
  const setSearchField = (term) => {
    setSearchTerm(term);
  }

  /**
   * Load all products from backend
   */
  const loadLocalProducts = useCallback(() => {
    ProductService.loadProducts()
      .then((response) => {
        loadProducts(response.data.products, false);
        setLoadingProducts(false);
        setLocalProducts(response.data.products);
      })
      .catch((error) => {
        console.warn(error);
      });
  }, [loadProducts]);

  const loadLocalPriceLists = useCallback(() => {
    PriceListsService.loadPriceLists().then(response => {
      setPriceLists(response.data.price_lists.filter(pl => pl.code && pl.code.includes("SUPPLIERCOST")).filter(pl => pl.currency_iso === 'EUR' || pl.currency_iso === 'SGD' || pl.currency_iso === 'USD' || pl.currency_iso === 'GBP' || pl.currency_iso === 'INR'));
    }).catch((error) => {
      console.warn(error);
    });
  }, [])

  /* istanbul ignore next */
  const exportHandlerToXLSX = (template = false) => {
    createNotification('success', 'Your products are being exported to XLSX file. The file will be downloaded in a while');
    ProductService.getProductsXLSX(template).then((response) => {
      const download = new FileDownload('products.xlsx', response.data);
      download.downloadFile();
    });
  };

  /* istanbul ignore next */
  const bulkUploadHandler = (values, actions) => {
    createNotification('warning', 'Uploading your file');
    setBulkUploadBegin("Uploading file, this might take a while...");
    return ProductService.createBulkProduct(values.document)
      .then((response) => {
        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            createNotification('error', error);
          });
          setBulkUploadEnd("Your file could not be processed");
        } else {
          setBulkUploadSummary(response.data.length + " products were created/updated")
          setBulkUploadEnd("Your file has been processed correctly");
          createNotification('success', 'Your file has been processed');
        }
      })
      .catch((error) => {
        setBulkUploadErrors(error.response.data.errors);
        setBulkUploadEnd("Your file could not be processed");
        error.response.data.errors.forEach((error) => {
          createNotification('error', error);
        });
      });
  };

  /**
   * Delete a product
   * @param product Product model
   * @returns {Promise<AxiosResponse<any>>}
   */
  /* istanbul ignore next */
  const deleteProduct = (product) => {
    
    if (window.confirm('Do you really want to delete it')){
      // Send request to backend
      return ProductService.deleteProduct(product.id)
        .then((response) => {
          if(response.data && response.data.error){
            createNotification(
              'error',
              response.data.error
            );
            return null;
          }
          createNotification(
            'success',
            `${product.name} has been deleted product`
          );
          setLoadingProducts(true);
          ProductService.loadProducts()
            .then((response) => {
              loadProducts(response.data.products, false);
              setLoadingProducts(false);
              setLocalProducts(response.data.products);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.warn(error);
          setLoadingProducts(false);
        });
    };
  };

  const resetBulkUploadInfo = () => {
    setBulkUploadBegin("");
    setBulkUploadSummary("");
    setBulkUploadErrors([]);
    setBulkUploadEnd("");
    return true;
  };

  const bulkUploadInfo = () => {
    let errors = "";
    bulkUploadErrors.forEach((error) => {
      errors = errors + error + "\n";
    });
    return(
      <div>
        <p>{bulkUploadBegin}</p>
        <p>{bulkUploadSummary}</p>
        <p>{errors}</p>
        <p>{bulkUploadEnd}</p>
      </div>
    );
  }

  const showSpinner = () => {
    if (loadingProducts) {
      return (
        <Container id="spinnerWrapper">
          <Row style={{ textAlign: 'center', marginTop: '20px' }}>
            <Col>
              <Spinner loading={loadingProducts} css={{ display: 'inline' }} />
            </Col>
          </Row>
        </Container>
      );
    }
    return renderContent();
  };

  const renderContent = () => {
    return (
      <>
        <Container id="products">
          <Row>
            <Col>
              <ProductSearch
                collection={products}
                setCollection={setStateProducts}
                searchableFields={[
                  { id: 'seller_sku_code', type: 'text' },
                  { id: 'fluetis_sku_code', type: 'text' },
                  { id: 'name', type: 'text' },
                  { id: 'brand', type: 'text' },
                  { id: 'upc', type: 'text' },
                ]}
                allCollection={products}
                setSearchField={setSearchField}
                searchField={searchTerm}
              />
            </Col>
          </Row>
        </Container>
        <Container style={{ marginBottom: '10px', padding: '0px' }}>
          <Row>
            <Col>
              <Link
                variant="button"
                className="btn btn-primary"
                to="/products/new"
              >
                Create New product
              </Link>
            </Col>
            <Col>
              <Button
                variant="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  exportHandlerToXLSX();
                }}
              >
                Export All
              </Button>
              <BulkUpload
                fileTypes=".csv, .xlsx"
                format={
                  <div>
                    <p>
                      Click <a rel="noopener noreferrer" href="#" onClick={() => exportHandlerToXLSX(true)}>here</a> to download a new blank template.
                    </p>
                    <p>
                      Click <a rel="noopener noreferrer" href="#" onClick={() => exportHandlerToXLSX()}>here</a> to download a template with your current data, edit and re-upload them.
                    </p>
                  </div>
                }
                messages={bulkUploadInfo()}
                messagesReset={resetBulkUploadInfo}
                handleSubmit={(values, actions) => {
                  return bulkUploadHandler(values, actions);
                }}
                title="New products"
                onClose={loadLocalProducts}
              />
            </Col>
          </Row>
        </Container>

        <PaginateComponent
          componentList={
            <ProductsTable
              deleteProduct={deleteProduct}
              company={company}
              priceLists={priceLists}
              searchTerm={searchTerm}
            />}

          list={localProducts}
          containerClassName="pagination-reduced"
          perPage={perPage}
        />
        <AdjustPaginateButtons
          list={localProducts}
          perPageList={[25, 50, 100]}
          setPerPage={setPerPage}
        />

        <GenericModal
          show={modal.show}
          loading={modal.loading || false}
          onHide={modal.onHide || resetModal}
          buttons={modal.buttons}
          body={modal.bodyMessage}
        />
      </>
    );
  };

  useEffect(() => {
    if (stateLocation.state && stateLocation.state.searchTerm){
      setSearchTerm(stateLocation.state.searchTerm);
      setLoadingProducts(false);
    }

    else{
      loadLocalProducts();
    }

    loadLocalPriceLists();
    // eslint-disable-next-line
  }, [loadLocalProducts]);

  if (user) {
    return (
      <Can
        role={user.type.toLowerCase()}
        perform="Products:list"
        yes={() => (
          <InnerLayout
            logOut={logOut}
            user={user}
            location={location}
            company={company}
          >
            {showSpinner()}
          </InnerLayout>
        )}
        no={() => <Redirect to="/no_permissions" />}
      />
    );
  }
  return null;
};

ProductsComponent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadProducts: PropTypes.func.isRequired,
};

export default ProductsComponent;
