import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

const BadgeComponent = (props) => {
  const { content } = props;
  let variantType = '';
  switch (content) {
  case 'In Progress':
    variantType = 'primary';
    break;
  case 'active':
  case 'paid':
  case 'Completed':
  case 'Return':
    variantType = 'success';
    break;
  case 'disabled':
  case 'unpaid':
  case 'Failed':
    variantType = 'danger';
    break;
  case 'fulfilled':
  case 'P.Completed':
    variantType = 'warning';
    break;
  case 'finalized':
  case 'On Hold':
    variantType = 'secondary';
    break;
  case 'Assigned':
    variantType = 'light';
    break;
  case 'Info Received':
    variantType = 'dark'
    break;
  case 'Unassigned':
  default:
    variantType = 'info';
    break;
  }
  return (
    <Badge pill variant={variantType} style={{fontSize: '14px'}}>
      {content}
    </Badge>
  );
};

BadgeComponent.propTypes = {
  content: PropTypes.string.isRequired,
};

export default BadgeComponent;
