import React from 'react';
import { Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import EditableInput from '../../../common/ui/EditableInput';
import Field from '../../../common/ui/Field';
import DimensionsValues from './DimensionsValues';

const DimensionsFormComponent = ({ values, setFieldValue, handleSubmit, readonly }) => {
  return (
    <Form id="dimsensionsForm">
      <p style={{marginLeft: '15px'}}>Dimensions are in centimeters, weight in grams.</p>
      <Row>
        <Col>
          <DimensionsValues
            title={'Single'}
            type={'ium'}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            readonly={readonly}
            />
        </Col>
      </Row>
      <Row>
        <Col>
          <DimensionsValues
            title={'Pack'}
            type={'pack'}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            readonly={readonly}
            />
        </Col>
      </Row>
      <Row>
        <Col>
          <DimensionsValues
            title={'Carton'}
            type={'pium'}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            readonly={readonly}
          />
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name="carton_quantity"
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
                noCol
              />
            }
            field="carton_quantity"
            label={'Units per Carton'}
            text={values.carton_quantity}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name="pium_per_layer"
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
                noCol
              />
            }
            field="pium_per_layer"
            label={'Cartons per Layer'}
            text={values.pium_per_layer}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name="layer_per_pallet"
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
                noCol
              />
            }
            field="layer_per_pallet"
            label={'Layers per Pallet'}
            text={values.layer_per_pallet}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <div className="form-group">
            <label>Units per Pallet</label>
            <span style={{ marginLeft: '50px' }}>
              {values.carton_quantity *
                values.pium_per_layer *
                values.layer_per_pallet}
            </span>
          </div>
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name="pallet_volume"
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
                noCol
              />
            }
            field="pallet_volume"
            label={'Volume (CBM)'}
            text={values.pallet_volume}
            setFieldValue={setFieldValue}
            disabled={true}
            handleSubmit={handleSubmit}
          />
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name="pallet_weight"
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
                noCol
              />
            }
            field="pallet_weight"
            label={'Weight'}
            text={values.pallet_weight}
            setFieldValue={setFieldValue}
            disabled={true}
            handleSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DimensionsFormComponent;
