import React, { useEffect, useState } from 'react';
import { host } from '../../../services/axios';
import CompanyAgreementsService from '../../../services/data/company_agreements_service';
import ButtonComponent from '../../common/ui/Button';
import AgreementNewComponent from './agreements/AgreementNew';
import AgreementsTable from './agreements/AgreementsTable';
import Label from '../../common/ui/Label';

const MyAgreement = ({ company }) => {
  const [agreements, setAgreements] = useState([]);
  const [showCreateAgreement, setShowCreateAgreement] = useState(false);

  const closeCreateAgreementModal = () => {
    setShowCreateAgreement(false);
  };

  const openCreateAgreementModal = () => {
    setShowCreateAgreement(true);
  };

  const loadAgreements = () => {
    CompanyAgreementsService.loadCompanyAgreements().then((response) => {
      setAgreements(response.data.company_agreements);
    });
  };

  useEffect(() => {
    loadAgreements();
  }, []);

  const displayLink = () => {
    return company?.agreement.agreement_url ===
      '/images/original/missing.png' ? (
      ''
    ) : (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={host + company?.agreement.agreement_url}
      >
        {company?.agreement.agreement_file_name}
      </a>
    );
  };

  return (
    <>
      <Label labelKey="profile.agreements"></Label>
      <p>1. Read the current terms and condition: {displayLink()}</p>
      <p>
         2. Upload the terms of condition that you have read and signed here:{' '}
        <ButtonComponent variant="primary" onClick={() => openCreateAgreementModal()}>
          {'Upload'}
        </ButtonComponent>
        <AgreementNewComponent showCreateAgreement={showCreateAgreement} closeCreateAgreementModal={closeCreateAgreementModal} loadAgreements={loadAgreements}/>
      </p>
      <AgreementsTable agreements={agreements} />
    </>
  );
};

export default MyAgreement;
