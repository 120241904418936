import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../common/ui/Modal';
import Form from './AgreementNewForm';
import { createNotification } from '../../../common/notifications';
import CompanyAgreementsService from '../../../../services/data/company_agreements_service';

const AgreementNewSchema = Yup.object().shape({
  document: Yup.string(),
});

const AgreementNewComponent = ({
  closeCreateAgreementModal,
  showCreateAgreement,
  loadAgreements,
}) => {
  /* istanbul ignore next */
  const handleCreateSubmit = (values, actions) => {
    CompanyAgreementsService.createCompanyAgreement(values)
      .then((response) => {
        actions.setSubmitting(false);
        createNotification('success', 'has been created', 'The agreement');
        loadAgreements();
        closeCreateAgreementModal();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        createNotification(
          'error',
          'was not possible to create',
          'The agreement'
        );
        closeCreateAgreementModal();
      });
  };

  return (
    <Formik
      initialValues={{
        document: '',
      }}
      validationSchema={AgreementNewSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleCreateSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeCreateAgreementModal}
          onCancel={closeCreateAgreementModal}
          title="New agreement"
          show={showCreateAgreement}
        >
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default AgreementNewComponent;
