import React from 'react';
import Spinner from 'react-spinners/FadeLoader';

const SpinnerComponent = ({ loading, marginTop }) => {
  return (
    <div style={{textAlign: 'center', marginTop: marginTop ?? '15%'}}>
      <Spinner loading={loading} css={{ display: 'inline-block' }} />
      <div>This may take a few seconds. Thank you for your patience</div>
    </div>
  );
};

export default SpinnerComponent;
