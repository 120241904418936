import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ProductsService from '../../services/data/products_service';
import InnerLayout from '../layout/InnerLayout';
import ExtraInformation from './ProductExtraInformation';
import Form from './ProductNewForm';
import { createNotification } from '../common/notifications';

const ProductNewSchema = Yup.object().shape({
  name_creation: Yup.string().required().min(5),
  sellerSkuCode: Yup.string().required(),
  brand: Yup.string().required(),
  productType: Yup.string(),
  hsCode: Yup.string().required().min(6),
  country: Yup.string().required(),
  upc: Yup.string().min(6).max(14)
    .required(),
  units_of_measure: Yup.string(),
  measure_value: Yup.string(),
  singleQuantity: Yup.number(),
  temperatureOfStorage: Yup.string().nullable(),
  manufactureShelfLife: Yup.number().required(),
});

const ProductNewComponent = ({ user, location, company, logOut }) => {
  const [product, setProduct] = useState();
  const [loadingProduct] = useState(false);
  const [enabledExtraInformation, setEnabledExtraInformation] = useState(false);
  /* istanbul ignore next */
  const handleCreateSubmit = (values, actions) => {
    actions.setSubmitting(true);
    ProductsService.createProduct(values)
      .then((response) => {
        actions.setSubmitting(false);
        setProduct(response.data);
        setEnabledExtraInformation(true);
        createNotification('success', 'The product has been created');
        window.location.href = `/products/${response.data.id}`;
      })
      .catch((error) => {
        actions.setSubmitting(false);
        createNotification(
          'error',
          'was not possible to create',
          'The product'
        );
      
      });
      
  };

  return (
    <InnerLayout
      logOut={logOut}
      user={user}
      location={location}
      company={company}
    >
      <Formik
        initialValues={{
          name: '',
          image: '',
          brand: '',
          productType: '',
          hsCode: '',
          country: '',
          sellerSkuCode: '',
          upc: '',
          units_of_measure: '',
          measure_value: '',
          singleQuantity: 0,
          temperatureOfStorage: '',
          manufactureShelfLife: '',
        }}
        validationSchema={ProductNewSchema}
        onSubmit={(values, actions) => {
          handleCreateSubmit(values, actions);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            enabled={enabledExtraInformation}
            values={values}
          />
        )}
      </Formik>
      <hr></hr>
      <ExtraInformation
        product={product}
        loadingProduct={loadingProduct}
        enabled={enabledExtraInformation}
      />
    </InnerLayout>
  );
};

export default ProductNewComponent;
