import axios from '../axios';

class AddressesService {
	static loadAddresses = () => axios.get('/addresses');
	static loadAddress = (id) => axios.get(`/addresses/${id}`);
	static createAddress = (data) => axios.post('/addresses', {
		address: {
			address1: data.address1,
			address2: data.address2,
			zip_code: data.postal_code,
			address_type: data.type,
			label: data.label,
			country_code: data.country_code,
			city: data.city,
			state: data.state
		}
	});
	static updateAddress = (id, data) => axios.patch(`/addresses/${id}`, {
		address: {
			address1: data.address1,
			address2: data.address2,
			zip_code: data.postal_code,
			address_type: data.type,
			label: data.label,
			country_code: data.country_code,
			city: data.city,
			state: data.state
		}
	});
	static deleteAddress = (id) => axios.delete(`/addresses/${id}`);
}

export default AddressesService;
