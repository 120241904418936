import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../common/ui/Field';

const ContactForm = (props) => {
  const { values, isCurrentUser } = props;
  return (
    <Container>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="position"
              type="text"
              label="Position"
              placeholder="Optional"
              labelStyle={{marginTop: '8px', width: '100px'}}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="name"
              type="text"
              label="Name"
              placeholder="Optional"
              labelStyle={{marginTop: '8px', width: '100px'}}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="email"
              type="email"
              label="Email"
              placeholder="Mandatory"
              labelStyle={{marginTop: '8px', width: '100px'}}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="phone_number"
              type="text"
              label="Contact Number"
              placeholder="Mandatory"
              labelStyle={{marginTop: '8px', width: '100px'}}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="allowLogin"
              type="checkbox"
              label="Access to Fluetis"
              style={{marginTop: '12px'}}
              labelStyle={{marginTop: '8px', width: '100px'}}
              checked={values.allowLogin}
              disabled={isCurrentUser}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
