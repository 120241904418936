import React from 'react';
import InnerLayout from '../layout/InnerLayout';

const UserValidationComponent = ({ logOut, user, location, company }) => {
  return (
    <InnerLayout
      logOut={logOut}
      user={user}
      location={location}
      company={company}
    >
      <h1>Users#validation</h1>
      <form
        action="/users/validation"
        method="post"
        style={{ padding: '10px' }}
        id="userValidation"
      >
        <label for="user_company_name">Company Name</label>
        <br />
        <input type="text" name="user[company_name]" />
        <br />
        <label for="user_company_url">Company url</label>
        <br />
        <input type="text" name="user[company_url]" />
        <br />
        <label for="user_contact_name">Contact name</label>
        <br />
        <input type="text" name="user[contact_name]" />
        <br />
        <label for="user_contact_title">Contact title</label>
        <br />
        <input type="text" name="user[contact_title]" />
        <br />
        <label for="user_contact_email">Contact email</label>
        <br />
        <input type="text" name="user[contact_email]" />
        <br />
        <label for="user_contact_phone">Contact phone</label>
        <br />
        <input type="text" name="user[contact_phone]" />
        <div>
          <h4>Contact</h4>
          <label for="contact_contact_name">Contact name</label>
          <br />
          <input type="text" name="contact[contact_name]" />
          <br />
          <label for="contact_contact_title">Contact title</label>
          <br />
          <input type="text" name="contact[contact_title]" />
          <br />
          <label for="contact_contact_email">Contact email</label>
          <br />
          <input type="text" name="contact[contact_email]" />
          <br />
          <label for="contact_contact_phone">Contact phone</label>
          <br />
          <input type="text" name="contact[contact_phone]" />
        </div>
        <div>
          <h4>Companies</h4>
          <label for="company_company_name">Company name</label>
          <br />
          <input type="text" name="company[company_name]" />
          <br />
          <label for="company_company_url">Company url</label>
          <br />
          <input type="text" name="company[company_url]" />
        </div>
        <input
          type="submit"
          value="Update User"
          data-disable-with="Update User"
        />
      </form>
    </InnerLayout>
  );
};

export default UserValidationComponent;
