import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './ImageDropzone.css';

const ImageDropzoneComponent = (props) => {
  /* istanbul ignore next */
  const onDrop = useCallback(
    (acceptedFiles) => {
      let aux = props.images ? [...props.images] : [];
      const images = acceptedFiles.map((image) => {
        return { image: image, url_image_local: URL.createObjectURL(image) };
      });
      aux.push(...images);
      props.setImages(aux);
    },
    [props]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="dragImage" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag’n drop pictures here. Re-order as you will.</p>
      )}
    </div>
  );
};

export default ImageDropzoneComponent;
