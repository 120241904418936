import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CompaniesService from '../../../services/data/companies_service';
import Form from './MyCompanyForm';
import { createNotification } from '../../common/notifications';

const CompanySchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
  url: Yup.string().matches(
    /^((https?):\/\/)?(www.)?[a-z0-9\\-]+(\.[a-z\\-]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!'),
  registrationNumber: Yup.string().required(),
  taxNumber: Yup.string().required(),
  incorporationDate: Yup.date().required(),
  keyBusinessActivity: Yup.string().required(),
});

const MyCompanyComponent = (props) => {
  const { company } = props;

  /* istanbul ignore next */
  const handleCompanySubmit = (values, actions) => {
    CompaniesService.updateCompany(company.id, values)
      .then(() => {
        createNotification('success', 'You have updated the company details');
        actions.setSubmitting(false);
      })
      .catch(() => {
        createNotification('error', 'Error updating company details');
        actions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        name: (company && company.name) || '',
        description: (company && company.description) || '',
        url: (company && company.website) || '',
        registrationNumber: (company && company.registration_number) || '',
        taxNumber: (company && company.tax_number) || '',
        incorporationDate: (company && company.incorporation_date) || '',
        keyBusinessActivity: (company && company.key_business_activity) || '',
      }}
      enableReinitialize
      validationSchema={CompanySchema}
      onSubmit={(values, actions) => handleCompanySubmit(values, actions)}
    >
      {({ isSubmitting, values, setFieldValue, submitForm }) => (
        <Form
          isSubmitting={isSubmitting}
          values={values}
          setFieldValue={setFieldValue}
          handleSubmit={submitForm}
          enableReinitialize={true}
        />
      )}
    </Formik>
  );
};

export default MyCompanyComponent;
