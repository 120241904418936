import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from '../../services/axios';
import SharedLinks from './SharedLinks';
import { createNotification, delayedRedirect } from '../common/notifications';
import OuterLayout from '../layout/OuterLayout';
import Form from './BecomeASellerForm';
import { dig } from '../common/dataTypes/array';

//const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const BecomeASellerSchema = Yup.object().shape({
  company_name: Yup.string().required('Company Name is required'),
  company_url: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9\\-]+(\.[a-z\\-]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .required('Company Url is required'),
  contact_email: Yup.string().email().required('Contact Email is required'),
  contact_name: Yup.string().required('First name cant be blank'),
  contact_lastname: Yup.string(),
  password: Yup.string().min(6).required('Password is required'),
  password_confirmation: Yup.string()
    .min(6)
    .required('Password Confirmation is required')
    .test('email-match', 'Password must match', function (value) {
      /* istanbul ignore next */
      return this.parent.password === value;
    }),
});

const BecomeASellerComponent = (props) => {
  /* istanbul ignore next */
  const handleSubmit = (action, values) => {
    axios
      .post('/sellers', {
        seller: {
          company_name: values.company_name,
          company_url: values.company_url,
          contact_email: values.contact_email,
          password: values.password,
          contact_name: `${values.contact_name} ${values.contact_lastname}`,
        },
      })
      .then((response) => {
        createNotification('success', 'You have created your account. You will receive an email with instructions');
        delayedRedirect('/users/sign_in');
      })
      .catch((error) => {
        action.setSubmitting(false);
        let errors = dig(error.response.data, "errors");
        Object.keys(errors).forEach((key)=>{
          createNotification('error', key + " " + errors[key][0]);
        });
      });
  };

  return (
    <OuterLayout externalContentWrapperStyles={{ marginTop: '190px' }}>
      <Formik
        initialValues={{
          company_name: '',
          company_url: '',
          contact_email: '',
          contact_name: '',
          contact_lastname: '',
          password: '',
          password_confirmation: '',
        }}
        enableReinitialize={true}
        validationSchema={BecomeASellerSchema}
        onSubmit={(values, actions) => handleSubmit(actions, values)}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form isSubmitting={isSubmitting} setFieldValue={setFieldValue} />
        )}
      </Formik>
      <SharedLinks
        login={false}
        signUp={false}
        forgotPassword={false}
        confirmation={false}
      />
    </OuterLayout>
  );
};

BecomeASellerComponent.propTypes = {
  currentUser: PropTypes.object,
};

export default BecomeASellerComponent;
