import React from 'react';
import { Table } from 'react-bootstrap';
import { getCurrency } from '../../components/common/dataTypes/prices';

const InventoryTable = ({ top10ProductInventory, company, currency }) => {
  const displayInventory = () => {
    if (top10ProductInventory && top10ProductInventory.length > 0) {
      return top10ProductInventory.map((product) => {
        return (
          <tr>
            <td>{product.sku}</td>
            <td>{product.name}</td>
            <td>{product.quantity}</td>
            <td>{product.price}</td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td colSpan={4}>No products yet</td>
      </tr>
    );
  };
  return (
    <Table
      striped
      bordered
      hover
      style={{ marginBottom: 0, textAlign: 'center' }}
    >
      <thead>
        <tr>
          <th colSpan="4" style={{ color: '#1B5DA0' }}>
            Top 10
          </th>
        </tr>
        <tr className="text-muted">
          <th style={{ width: '28%' }}>SKU</th>
          <th style={{ width: '56%' }}>Product Name</th>
          <th style={{ width: '8%' }}>Quantity</th>
          <th style={{ width: '8%' }}>{`Value (${`${getCurrency(currency)}`})`}</th>
        </tr>
      </thead>
      <tbody>{displayInventory()}</tbody>
    </Table>
  );
};

export default InventoryTable;
