import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'formik';
import FlatButton from '../common/ui/FlatButton';
import Spinner from 'react-spinners/FadeLoader';
import Field from '../common/ui/OuterField';
import ErrorMessage from '../common/ui/ErrorMessage';

const BecomeASellerForm = ({ isSubmitting, setFieldValue }) => {
  return (
    <Container fluid>
      <Form id="becomeASellerForm">
        <Row>
          <Col>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                name="company_name"
                placeholder="Company Name"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="company_name" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                name="company_url"
                placeholder="Company Website"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="company_url" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="">
              <Field
                className="form-control"
                type="email"
                name="contact_email"
                placeholder="Email"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="contact_email" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="">
              <Field
                className="form-control"
                type="text"
                name="contact_name"
                placeholder="First Name"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="contact_name" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="">
              <Field
                className="form-control"
                type="text"
                name="contact_lastname"
                placeholder="Last Name"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="contact_lastname" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <Field
                className="form-control"
                type="password"
                name="password"
                placeholder="Password"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="password" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <Field
                className="form-control"
                type="password"
                name="password_confirmation"
                placeholder="Confirm password"
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="small" name="password_confirmation" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="" style={{textAlign: 'center'}}>
            {isSubmitting ? (
                  <Spinner
                    loading={isSubmitting}
                    css={{ display: 'inline-block' }}
                  />
                ) : (
              <FlatButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ float: 'right' }}
              >
                Create your account
              </FlatButton>
                )}
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BecomeASellerForm;
