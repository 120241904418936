import axios from '../axios';

/**
 * Service to manage categories in backend.
 */
class CategoriesService {
  /**
   * Gets all categories
   * @returns {Promise<AxiosResponse<any>>}
   */
  static list() {
    return axios.get('/categories');
  }

  /**
   * Gets a category by its id.
   * @param id Category ID
   * @returns {Promise<AxiosResponse<any>>}
   */
  static load(id) {
    return axios.get(`/categories/${id}`);
  }

  /**
   * Creates a category
   * @param category category object
   * @returns {Promise<AxiosResponse<any>>}
   */
  static create(category) {
    return axios.post(
      '/categories',
      this._formDataFromCategoryObject(category),
        {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  /**
   * Updates a category
   * @param id Category ID
   * @param category Category object
   * @returns {Promise<AxiosResponse<any>>}
   */
  static update(id, category) {
    return axios.patch(
      `/categories/${id}`,
      this._formDataFromCategoryObject(category),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  /**
   * Request to backend for deleting a category
   * @param id Category ID
   * @returns {Promise<AxiosResponse<any>>}
   */
  static delete(id) {
    return axios.delete(`/categories/${id}`);
  }

  /**
   * @private
   * Get a FormData object from a category to be sent to backed.
   * @param category Object with category information
   * @returns {FormData}
   */
  static _formDataFromCategoryObject(category) {
    const formData = new FormData();
    formData.append(`category[name]`, category.name);
    return formData;
  }
}

export default CategoriesService;
