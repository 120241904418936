import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Col, Row, Alert } from 'react-bootstrap';
import InnerLayout from '../layout/InnerLayout';
import Can from '../roles/Can';
import DashboardService from '../../services/data/dashboard_service';
import {
  getCurrency,
  formattedPriceWithoutCurrency,
} from '../../components/common/dataTypes/prices';
import DashboardCard from './DashboardCard';
import InventoryTable from './InventoryTable';
import SalesTable from './SalesTable';
import Actions from './Actions';
import DateSelector from '../common/ui/DateSelector';

const DashboardComponent = ({ logOut, user, company, location }) => {
  const [loadingDashboardData, setLoadingDashboardData] = useState(true);
  const [displayWelcomeMessage, setDisplayWelcomeMessage] = useState(true);
  const [dateScope, setDateScope] = useState("week");
  const [preferredCurrency, setPreferredCurrency] = useState('sgd')

  const [productsData, setProductsData] = useState([
    { name: 'Products', value: 0 },
    { name: 'With stock', value: 0 },
    { name: 'Sold', value: 0 },
  ]);

  const [shipmentsData, setShipmentsData] = useState([
    { name: 'Finalized', value: 0 },
    { name: 'Payment', value: 0 },
    { name: 'In transit', value: 0 },
    { name: 'Received', value: 0 },
  ]);

  const [inventoryData, setInventoryData] = useState([
    { name: 'Quantity', value: 0 },
    { name: 'Value', value: 0 },
  ]);

  const [salesData, setSalesData] = useState([
    { name: 'Quantity', value: 0 },
    { name: 'Value', value: 0 },
  ]);

  const [top10Sales, setTop10Sales] = useState();
  const [top10ProductInventory, setTop10ProductInventory] = useState();

  const setDashboardData = (data) => {
    setProductsData([
      { name: 'Products', value: data.products.unique },
      { name: 'With stock', value: data.products.with_stock },
      { name: 'Sold', value: data.products.sold },
    ]);

    setSalesData([
      { name: 'Orders', value: data.orders.quantity },
      {
        name: 'Value',
        value:
          `${getCurrency(data.orders.currency)} ` +
          `${formattedPriceWithoutCurrency(data.orders.value)}`,
      },
    ]);

    setInventoryData([
      { name: 'Quantity', value: data.inventory.units },
      {
        name: 'Value',
        value:
          `${getCurrency(data.inventory.currency)} ` +
          `${formattedPriceWithoutCurrency(data.inventory.value)}`,
      },
    ]);

    setPreferredCurrency(data.inventory.currency);

    const top10Sales = data.orders.top_10_sales.map((sale) => {
      return {
        name: sale.name,
        sales: formattedPriceWithoutCurrency(sale.sales),
        quantity: sale.quantity,
        sku: sale.sku,
      };
    });

    setTop10Sales(top10Sales);

    const top10Products = data.inventory.top_10_products_inventory.map((product) => {
      return {
        name: product.name,
        price: formattedPriceWithoutCurrency(product.price),
        quantity: product.quantity,
        sku: product.sku,
      };
    });
    
    setTop10ProductInventory(top10Products);
  };

  const loadDashboardData = (startDate, endDate, scope) => {
    setLoadingDashboardData(true);
    DashboardService.getData(startDate, endDate, scope)
      .then((response) => {
        setDashboardData(response.data);
        setLoadingDashboardData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingDashboardData(false);
      });
  };

  useEffect(() => {
    loadDashboardData("", "", dateScope);

    if (user.type.toLowerCase() === 'seller') {
      //Revert this commit when dashboard works as expected
      //loadDashboardData();
      setLoadingDashboardData(false);
    } else {
      setLoadingDashboardData(false);
    }

    setTimeout(() => {
      setDisplayWelcomeMessage(false);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  if (user) {
    return (
      <>
        <Can
          role={user.type.toLowerCase()}
          perform="Dashboard:list"
          yes={() => (
            <InnerLayout
              logOut={logOut}
              user={user}
              location={location}
              company={company}
            >
              {displayWelcomeMessage ? (
                <Alert
                  variant={'success'}
                  dismissible
                  onClose={() => {
                    setDisplayWelcomeMessage(false);
                  }}
                >
                  Welcome to Fluetis
                </Alert>
              ) : null}
              {user.activation_messages && user.activation_messages.length > 0 ? (
                <Actions messages={user.activation_messages} />) : null}
              <div id="dashboard">
                <Row>
                  <Col sm="2">
                    <DateSelector
                      dateScope={dateScope}
                      setDateScope={setDateScope}
                      loadData={loadDashboardData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DashboardCard
                      title="Products"
                      values={productsData}
                      loadingDashboardData={loadingDashboardData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DashboardCard
                      title="Sales"
                      values={salesData}
                      loadingDashboardData={loadingDashboardData}
                    >
                      <SalesTable top10Sales={top10Sales} company={company} />
                    </DashboardCard>
                  </Col>
                  <Col>
                    <DashboardCard
                      title="Inventory"
                      values={inventoryData}
                      loadingDashboardData={loadingDashboardData}
                    >
                      <InventoryTable
                        top10ProductInventory={top10ProductInventory}
                        company={company}
                        currency={preferredCurrency}
                      />
                    </DashboardCard>
                  </Col>
                </Row>
              </div>
            </InnerLayout>
          )}
          no={() => <Redirect to="/no_permissions" />}
        />
      </>
    );
  }
  /* istanbul ignore next */
  return null;
};

DashboardComponent.propTypes = {
  logOut: PropTypes.func.isRequired,
};

export default DashboardComponent;
