import { LOAD_COMPANY } from '../actions/actions';

const invoicesAndPaymentsReducers = (state, action) => {
  switch (action.type) {
  case LOAD_COMPANY:
    const newState = { ...state };
    newState.company = action.company;
    return newState;
  default:
    return state;
  }
};

export default invoicesAndPaymentsReducers;
