import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import Modal from './Modal';

const BulkUploadSchema = Yup.object().shape({
  document: Yup.string().required(),
});

const BulkUploadForm = (props) => {
  const {
    show,
    setShow,
    closeShow,
    handleSubmit,
    title,
    autoClose,
    format,
    messages,
    messagesReset,
    fileTypes,
  } = props;

  /**
   * Funtionality to execute everytime Formik submission ends.
   */
  const endSubmission = (values, actions) => {
    actions.setSubmitting(false);
    if (autoClose) {
      closeShow();
    }
  };

  useEffect(() => {
    if (!show && title == "New products"){
      messagesReset();
    }
  }, [show]);

  return (
    <Formik
      initialValues={{
        document: '',
      }}
      validationSchema={BulkUploadSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleSubmit(values, actions);
        endSubmission(values, actions);
      }}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Modal
          onHide={setShow}
          onCancel={closeShow}
          title={title}
          show={show}
          loading={isSubmitting}
        >
          <div className="form-group">
            <p>Please add a file in UTF-8 format. Not UTF-8-BOM, or other subsets of UTF-8.</p>
            {format}
            {messages}
            <input
              name="document"
              type="file"
              accept={fileTypes}
              onChange={(event) => {
                setFieldValue('document', event.currentTarget.files[0]);
              }}
            />
            <ErrorMessage component="small" name="document" />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default BulkUploadForm;
