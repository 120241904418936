export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_SHIPMENTS = 'LOAD_SHIPMENTS';
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const LOAD_INVOICES_AND_PAYMENTS = 'LOAD_INVOICES_AND_PAYMENTS';
export const SET_USER = 'SET_USER';

export const loadProducts = (products) => (dispatch) =>
  dispatch({
    type: LOAD_PRODUCTS,
    products,
  });

export const loadShipments = (shipments) => (dispatch) =>
  dispatch({
    type: LOAD_SHIPMENTS,
    shipments,
  });

export const loadCompany = (company) => (dispatch) =>
  dispatch({
    type: LOAD_COMPANY,
    company,
  });

export const logIn = () => (dispatch) => dispatch({ type: LOG_IN });

export const loadInvoicesAndPayments = (invoices, payments, refunds) => (
  dispatch
) =>
  dispatch({
    type: LOAD_INVOICES_AND_PAYMENTS,
    invoices,
    payments,
    refunds,
  });

export const logOut = () => (dispatch) => dispatch({ type: LOG_OUT });

export const setUser = (user) => (dispatch) =>
  dispatch({
    type: SET_USER,
    user,
  });
