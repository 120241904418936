import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, ErrorMessage } from 'formik';
import Field from '../common/ui/Field';
import Button from '../common/ui/Button';

const UserForm = (props) => {
  return (
    <Form id="usersForm">
      <Modal.Body>
        <div className="form-group">
          <label>
            <h5>{'Email'}</h5>
          </label>
          <Field
            name="email"
            type="email"
            className="form-control"
            placeholder="email"
          />
          <ErrorMessage component="small" name="email" />
        </div>
        <div className="form-group">
          <label>
            <h5>{'Password'}</h5>
          </label>
          <Field
            name="password"
            type="password"
            className="form-control"
            placeholder=""
          />
          <ErrorMessage component="small" name="password" />
        </div>
        <div className="form-group">
          <label>
            <h5>{'Password Confirmation'}</h5>
          </label>
          <Field
            name="passwordConfirmation"
            type="password"
            className="form-control"
            placeholder=""
          />
          <ErrorMessage component="small" name="passwordConfirmation" />
        </div>
        <div className="form-group">
          <label>
            <h5>{'Type'}</h5>
          </label>
          <Field
            name="type"
            type="text"
            as="select"
            className="form-control"
            placeholder=""
          >
            <option value="Seller">Seller</option>
            <option value="Admin">Admin</option>
          </Field>
          <ErrorMessage component="small" name="type" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default UserForm;
