import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Field from '../common/ui/Field';
import Button from '../common/ui/Button';

const SearchSchema = Yup.object().shape({
  search: Yup.string().optional(),
});

const OrdersSearch = ({
  collection,
  allCollection,
  setCollection,
  companies,
  loadInitialOrders,
  user,
  setSearchField,
  searchField,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const includedInCollection = (word, collection) => {
    let result = false;
    if (collection) {
      // eslint-disable-next-line
      collection.map((element) => {
        if (element.toLowerCase().includes(word)) {
          result = true;
        }
      });
    }

    return result;
  };

  const searchFilter = (search) => {
    let aux = [...allCollection];
    if (aux.length === 0) {
      aux = allCollection;
    }
    const words = search.toLowerCase().split(' ');

    // eslint-disable-next-line
    words.map((word) => {
      if (word === '') {
        aux = allCollection;
        return null;
      }
      aux = aux
        .filter((col) => {
          return (
            col.order_number.toString().toLowerCase().includes(word.toString()) ||
            col.company_name.toString().toLowerCase().includes(word.toString())
          );
        })
    });

    return aux;
  };

  const handleSubmit = (values) => {
    setSearchTerm(values.search);
    const orders = searchFilter(values.search);
    setSearchField(values.search);
    setCollection(orders);
  };

  useEffect(() => {
    if (searchField){
      const values={search: searchField};
      handleSubmit(values);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{
        search: searchTerm || searchField || '',
      }}
      enableReinitialize
      validationSchema={SearchSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {() => (
        <Form id="search">
          <Container fluid style={{ margin: '0px', padding: '0px 15px' }}>
            <Row style={{ padding: '0px' }}>
              <Col style={{ padding: '0px' }}>
                <div className="input-group">
                  <Field
                    name="search"
                    type="text"
                    noCol
                    placeholder="Type any customer name, customer type or order number to filter the list"
                    style={{ borderRadius: '5px' }}
                  />
                  <span className="input-group-addon">
                    <Button
                      variant="primary"
                      type="submit"
                      className="float-right"
                      style={{ height: '38px', marginLeft: '5px' }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default OrdersSearch;
