import React, { useState } from 'react';
import { formattedDate } from '../common/dataTypes/dates';
import { formattedPrice } from '../common/dataTypes/prices';
import OrdersOfflineRowDetail from './OrdersOfflineRowDetail';
import Badge from '../common/ui/Badge';

const OrdersOfflineTableRow = ({
  order,
  orderLineItems,
  products,
  companies,
  user,
  getCount,
  getAmount,
}) => {
  const [visibleDetail, setVisibleDetail] = useState(false);

  const getCompanyById = (platformCompanyId, companies) => {
    return companies.find((c) => c.id === platformCompanyId);
  };

  const company = getCompanyById(order.platform_company_id, companies);

  const displayRow = () => {
    return (
      <>
        <tr
          className="orderRow"
          key={order.id}
          onClick={() => setVisibleDetail(!visibleDetail)}
        >
          <td>{formattedDate(order.issued_at)}</td>
          <td>{order.company_name}</td>
          <td>{order.order_number}</td>
          <td>
            {
              order.fulfillment_status == "shipped" ? "fulfilled" : order.fulfillment_status == "unshipped" ? "active" : "No Status"
            }
          </td>
          <td>{count}</td>
          <td>
            {
              order.tracking_status ? <Badge content={order.tracking_status} /> : "None"
            }
          </td>
          <td>
            {
              order.tracking_link ? <a href={order.tracking_link} target="_blank" rel="noopener noreferrer">Tracking</a>
                                    : "No Tracking"
            }
          </td>
        </tr>
        <OrdersOfflineRowDetail
          visible={visibleDetail}
          orderLineItems={orderLineItems}
          products={products}
          count={getCount(orderLineItems)}
        />
      </>
    );
  };

  let count = getCount(orderLineItems);

  if (count > 0) {
    return displayRow();
  }
  return null;
};

export default OrdersOfflineTableRow;
