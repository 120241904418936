import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from './OverviewForm';
import ProductService from '../../../services/data/products_service';
import CategoriesService from '../../../services/data/categories_service';
import { createNotification } from '../../common/notifications';

const OverviewSchema = Yup.object().shape({
  categories: Yup.string().nullable(),
  description: Yup.string().nullable(),
  ingredients: Yup.string().nullable(),
  allergensWarning: Yup.string().nullable(),
  aboutTheBrand: Yup.string().nullable(),
  highlight1: Yup.string().nullable(),
  highlight2: Yup.string().nullable(),
  highlight3: Yup.string().nullable(),
  howToUse: Yup.string().nullable(),
  reloadTags: Yup.boolean(),
});

const Overview = ({ disabled, product, readonly }) => {
  const [tags, setTags] = useState(['']);
  const [allTags, setAllTags] = useState([]);
  const [reloadTags, setReloadTags] = useState(false);
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [allergensWarning, setAllergensWarning] = useState('');
  const [aboutTheBrand, setAboutTheBrand] = useState('');
  const [highlight1, setHighlight1] = useState('');
  const [highlight2, setHighlight2] = useState('');
  const [highlight3, setHighlight3] = useState('');
  const [howToUse, setHowToUse] = useState('');


  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    const productData = {
      category_ids: values.categories,
      description: values.description,
      ingredients: values.ingredients,
      allergens_warning: values.allergensWarning,
      about_the_brand: values.aboutTheBrand,
      highlight1: values.highlight1,
      highlight2: values.highlight2,
      highlight3: values.highlight3,
      how_to_use: values.howToUse
    };
    ProductService.updateProduct(product.id, productData)
      .then((response) => {
        createNotification('success', 'have been updated', 'Descriptions');
        _setDataFromRequest(response.data);
      })
      .catch((error) => console.log(error.body));
  };

   /**
   * Set state values from a product which come from a ProductService requiers
   * @param requestData
   * @private
   * @see ProductService
   */
  /* istanbul ignore next */
  const _setDataFromRequest = (requestData) => {
    if (requestData) {
      setTags(
        allTags
          .filter((tag) => {
            return requestData.category_ids.includes(tag.value);
          })
          .map((tag2) => tag2.value)
      );
      setReloadTags(!reloadTags);

      setDescription(requestData.platform_description);
      setIngredients(requestData.ingredients);
      setAllergensWarning(requestData.allergens_warning);
      setAboutTheBrand(requestData.about_the_brand);
      setHighlight1(requestData.highlight1);
      setHighlight2(requestData.highlight2);
      setHighlight3(requestData.highlight3);
      setHowToUse(requestData.how_to_use);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    CategoriesService.list()
      .then((response) => {
        setAllTags(
          response.data.categories.map((category) => {
            return { label: category.name, value: category.id };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (allTags.length > 0 && product) {
      const selectedTags = [...allTags].filter((tag) => {
        return product.category_ids.includes(tag.value);
      });
      setTags(selectedTags.map((tag) => tag.value));
    }
    if (product){
      _setDataFromRequest(product);
    }
  }, [allTags, product]);

  if (product) {
    return (
      <Formik
        initialValues={{
          categories: tags,
          description: description || '',
          ingredients: ingredients || '',
          allergensWarning: allergensWarning || '',
          aboutTheBrand: aboutTheBrand || '',
          highlight1: highlight1 || '',
          highlight2: highlight2 || '',
          highlight3: highlight3 || '',
          howToUse: howToUse || '',
          reloadTags: reloadTags,
        }}
        enableReinitialize
        validationSchema={OverviewSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ values, submitForm, setFieldValue }) => (
          <Form
            setFieldValue={setFieldValue}
            categories={allTags}
            disabled={disabled}
            readonly = {readonly}
            values={values}
            handleSubmit={submitForm}
          />
        )}
      </Formik>
    );
  }
  return <span></span>;
};

export default Overview;
