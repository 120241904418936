import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AddressesService from '../../../services/data/address_service';
import MyAddressTable from './MyAddressTable';
import Button from '../../common/ui/Button';
import PaginateComponent from '../../common/ui/PaginateComponent';
import AddressNew from './AddressesNew';
import AddressEdit from './AddressEdit';

const MyAddressesComponent = (props) => {
  const [addresses, setAddresses] = useState([]);
  const [loadingAddresses, setLoadingAddresses] = useState(true);
  const [showCreateLocation, setShowCreateLocation] = useState(false);
  const [showEditLocation, setShowEditLocation] = useState(false);
  const [addressId, setAddressId] = useState();

  /* istanbul ignore next */
  const loadAddresses = () => {
    AddressesService.loadAddresses()
      .then((response) => {
        setAddresses(response.data.addresses);
        setLoadingAddresses(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingAddresses(false);
      });
  };

  /* istanbul ignore next */
  const showCreateLocationModal = () => {
    setShowCreateLocation(true);
  };

  /* istanbul ignore next */
  const closeCreateLocationModal = () => {
    setShowCreateLocation(false);
  };

  /* istanbul ignore next */
  const closeEditLocationModal = () => {
    setShowEditLocation(false);
  };

  /* istanbul ignore next */
  const showEditLocationModal = (id) => {
    setShowEditLocation(true);
    setAddressId(id);
  };

  const displayAddressesWarning = () => {
    const hasOffice = addresses.some(
      (address) => address.address_type === 'office'
    );
    const hasWarehouse = addresses.some(
      (address) => address.address_type === 'warehouse'
    );

    if (!hasOffice || !hasWarehouse) {
      return (
        <span style={{ color: 'red', marginLeft: '20px' }}>
          Please create at least 2 addresses: one for your office, one for your
          warehouse. Select the "Type" value accordingly.{' '}
        </span>
      );
    }
    return null;
  };

  /* istanbul ignore next */
  const handleDelete = (id) => {
    if (window.confirm('Do you really want to delete it')) {
      AddressesService.deleteAddress(id)
        .then((response) => {
          loadAddresses();
        })
        .catch((error) => console.log(error));
    }
  };

  /* istanbul ignore next */
  const displayLocations = () => {
    if (loadingAddresses) {
      return <div>Loading Addresses</div>;
    } else {
      if (addresses && addresses.length > 0) {
        return (
          <PaginateComponent
            componentList={
              <MyAddressTable
                showEditLocationModal={showEditLocationModal}
                handleDelete={handleDelete}
              />
            }
            list={addresses}
            perPage={10}
          />
        );
      } else {
        return <div>No Addresses</div>;
      }
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  return (
    <Container fluid style={{ marginTop: '15px' }}>
      <Row>
        <Col>
          <Button
            variant="primary"
            style={{ marginBottom: '10px' }}
            onClick={() => showCreateLocationModal()}
          >
            Create Address
          </Button>
          {displayAddressesWarning()}
          <AddressNew
            showCreateLocation={showCreateLocation}
            closeCreateLocationModal={closeCreateLocationModal}
            loadAddresses={loadAddresses}
          />
          <AddressEdit
            showEditLocation={showEditLocation}
            closeEditLocationModal={closeEditLocationModal}
            addressId={addressId}
            loadAddresses={loadAddresses}
          />
          {displayLocations()}
        </Col>
      </Row>
    </Container>
  );
};

export default MyAddressesComponent;
