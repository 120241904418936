import React, { useState, useEffect } from 'react';
import Button from '../../common/ui/Button';
import BulkUploadForm from './BulkUploadForm';

const BulkUpload = (props) => {
  const { handleSubmit, title, autoClose, format, messages, messagesReset, fileTypes, show, onClose } = props;
  const [showModal, setShowModal] = useState(show);

  /* istanbul ignore next */
  const closeModal = () => {
    setShowModal(false);
    messagesReset();
    onClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show])

  return (
    <>
      <Button
        variant="primary"
        style={{ float: 'right', marginRight: '5px' }}
        onClick={() => setShowModal(true)}
      >
        Bulk Upload
      </Button>
      <BulkUploadForm
        show={showModal}
        setShow={setShowModal}
        closeShow={closeModal}
        handleSubmit={handleSubmit}
        title={title}
        format={format}
        messages={messages}
        messagesReset={messagesReset}
        fileTypes={fileTypes}
        autoClose={autoClose}
      />
    </>
  );
};

export default BulkUpload;
