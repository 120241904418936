import axios from '../axios';

class ActivationMessagesService {

  static getMessagesXLSX(template = false) {
    return axios.request({
      url: `/activation_messages.xlsx?template=${template}`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  /**
   * Send XLSX file to backend for orders bulk creation.
   * @param file Object from input type file
   */
  static bulkCreation(file) {
    let formData = new FormData();
    if (file){
      formData.append('activation_message[activation_messages_file]', file);
    }
    return axios.post('activation_messages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default ActivationMessagesService;
