import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from './CertificationNewForm';
import CertificationService from '../../../../services/data/certifications_service';
import { createNotification } from '../../../common/notifications';
import Modal from '../../../common/ui/Modal';

const CertificationNewSchema = Yup.object().shape({
  name: Yup.string(),
  start_date: Yup.date(),
  end_date: Yup.date(),
  document: Yup.string(),
});

const CertificationNewComponent = ({
  productId,
  closeAddCertificationModal,
  loadCertifications,
  showCreateCertification,
}) => {
  /* istanbul ignore next */
  const handleCreateSubmit = (values, actions) => {
    if (productId === undefined) {
      createNotification(
        'error',
        'was not possible to be created without a product',
        'The certification'
      );
      return;
    }
    CertificationService.createCertification(productId, values)
      .then((response) => {
        actions.setSubmitting(false);
        createNotification('success', 'has been created', 'The certificate');
        closeAddCertificationModal();
        loadCertifications();
      })
      .catch((error) => {
        createNotification(
          'error',
          'was not possible to create',
          'The certification'
        );
      });
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: '',
        start_date: '',
        end_date: '',
        document: '',
      }}
      validationSchema={CertificationNewSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleCreateSubmit(values, actions);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Modal
          onHide={closeAddCertificationModal}
          onCancel={closeAddCertificationModal}
          title="New Certification"
          show={showCreateCertification}
        >
          <Form isSubmitting={isSubmitting} setFieldValue={setFieldValue} />
        </Modal>
      )}
    </Formik>
  );
};

CertificationNewComponent.propTypes = {
  showCreateCertification: PropTypes.bool,
  closeAddCertificationModal: PropTypes.func,
  productId: PropTypes.number,
};

export default CertificationNewComponent;
