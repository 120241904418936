import React, { useState } from 'react';

const AdjustPaginateButtons = ({ perPageList, setPerPage, list }) => {
  const [selected, setSelected] = useState(0);

  const handleClick = (index, perPage) => {
    setSelected(index);
    setPerPage(perPage);
  };

  if (list?.length > 0) {
    return (
      <div
        className={`pagination-adjustments ${
          list?.length <= perPageList[selected]
            ? 'pagination-adjustments-without-pagination'
            : ''
        }`}
      >
        {perPageList.map((perPage, index) => {
          return (
            <span className="page-item">
              <button
                type="button"
                className="page-link"
                style={{ display: 'inline', border: 'none' }}
                onClick={() => handleClick(index, perPage)}
              >
                {perPage}
              </button>
            </span>
          );
        })}
      </div>
    );
  }
  return null;
};

export default AdjustPaginateButtons;
