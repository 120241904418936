import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from './LoginForm';
import LoginService from '../../services/login';
import StorageService from '../../services/storage_service';
import { createNotification } from '../common/notifications';
import OuterLayout from '../layout/OuterLayout';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const LoginComponent = (props) => {
  const [isLogin, setIsLogin] = useState(false);
  
  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    setIsLogin(true);
    actions.setSubmitting(true);
    LoginService.login(values)
      .then((response) => {
        actions.setSubmitting(false);
        if (response.status === 200) {
          const bearerToken = response.headers.authorization.split(' ')[1];
          StorageService.storeBearer(bearerToken);
          StorageService.storeUserData(response.data);
          props.logIn();
          setIsLogin(false);
          props.setUser(response.data);
          window.location.href = '/';
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        setIsLogin(false);        
        
        if(error.response.status === 403 || error.response.status === 401){
          createNotification('error', 'Invalid email or password');
        }
      });
  };

  return (
    <OuterLayout>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        enableReinitialize={true}
        validationSchema={LoginSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => (
          <Form
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={submitForm}
            isLogin={isLogin}
          />
        )}
      </Formik>
    </OuterLayout>
  );
};

export default LoginComponent;
