import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ContactsService from '../../../services/data/contacts_service';
import { createNotification } from '../../common/notifications';
import Modal from '../../common/ui/Modal';
import Form from './ContactForm';

const ContactNewSchema = Yup.object().shape({
  companyId: Yup.number(),
  name: Yup.string().required('Name is required'),
  position: Yup.string(),
  email: Yup.string().email().required('Contact Email is required'),
  phone_number: Yup.string(),
  allowLogin: Yup.boolean(),
});

const ContactNewComponent = (props) => {
  const {
    company,
    loadContacts,
    closeCreateContactModal,
    showCreateContact,
  } = props;

  /* istanbul ignore next */
  const handleCreateSubmit = (values, actions) => {
    ContactsService.createContact(values)
      .then((response) => {
        actions.setSubmitting(true);
        createNotification('success', 'You have created a new contact');
        loadContacts();
        closeCreateContactModal();
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((error) => {
        createNotification('error', error.response.data);
        actions.setSubmitting(false);
        actions.resetForm();
      });
  };

  return (
    <Formik
      initialValues={{
        companyId: company && company.id,
        name: '',
        position: '',
        email: '',
        allowLogin: false,
        phone_number: '',
      }}
      validationSchema={ContactNewSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleCreateSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeCreateContactModal}
          onCancel={closeCreateContactModal}
          title="New contact"
          show={showCreateContact}
        >
          <Form id="contactNew" company={company} values={values} />
        </Modal>
      )}
    </Formik>
  );
};

export default ContactNewComponent;
