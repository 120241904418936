import { SET_USER } from '../actions/actions';

const usersReducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            let newState = {...state};
            newState.user = action.user;
            return newState;
        default:
            return state;
    }
}

export default usersReducer;
