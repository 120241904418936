import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from './authentication/Login';
import SignUp from './authentication/SignUp';
import ForgotPassword from './authentication/ForgotPassword';
import BecomeASeller from './authentication/BecomeASeller';
import EditUser from './authentication/EditUser';
import Confirmation from './authentication/Confirmation';
import Dashboard from './dashboard/Dashboard';
import UserValidation from './validation/UserValidation';
import ProductNew from './products/ProductNew';
import Products from './products/Products';
import ProductEdit from './products/ProductEdit';
import InventoryAndShipments from './inventory_and_shipments/InventoryAndShipment';
import AccountingAndPayment from './accounting_and_payment/AccountingAndPayment';
import Delivery from './delivery/Delivery';
import Marketing from './marketing/Marketing';
import OrdersAndSales from './orders_and_sales/OrdersAndSales';
import ShipmentsNew from './inventory_and_shipments/shipments/ShipmentsNew';
import ShipmentsEdit from './inventory_and_shipments/shipments/ShipmentsEdit';
import PurchaseOrdersNew from './inventory_and_shipments/purchase_orders/PurchaseOrdersNew';
import PurchaseOrdersEdit from './inventory_and_shipments/purchase_orders/PurchaseOrdersEdit';
import UserConfirmationSent from './authentication/UserConfirmationSent';
import UserForgotPasswordLink from './authentication/UserForgotPasswordLink';
import ContactAndFaq from './contact_and_faq/ContactAndFaq';
import NoPermissions from './NoPermissions';
import AdminTools from './admin_tools/AdminTools';
import NotFoundComponent from './NotFound';

const DefinedRoutes = (properties) => (
  <Switch>
    <PrivateRoute
      exact
      path="/"
      component={Dashboard}
      properties={properties}
    />
    <PrivateRoute
      exact
      path="/dashboard/index"
      component={Dashboard}
      properties={properties}
    />

    <Route
      exact
      path="/users/sign_in"
      render={(props) => <Login {...props} {...properties} />}
    />

    <Route
      exact
      path="/users/sign_up"
      render={(props) => <SignUp {...props} {...properties} />}
    />

    <Route
      exact
      path="/users/password/new"
      render={(props) => <ForgotPassword {...props} {...properties} />}
    />

    <Route
      exact
      path="/users/confirmation/new"
      render={(props) => <Confirmation {...props} {...properties} />}
    />

    <Route
      exact
      path="/join_as_a_seller"
      render={(props) => <BecomeASeller {...props} {...properties} />}
    />

    <Route
      exact
      path="/confirmation"
      render={(props) => <UserConfirmationSent {...props} {...properties} />}
    />

    <Route
      exact
      path="/password/edit"
      render={(props) => <UserForgotPasswordLink {...props} {...properties} />}
    />

    <Route
      exact
      path="/not_found"
      component={NotFoundComponent}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/users/validation_form"
      component={UserValidation}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/users/edit"
      component={EditUser}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/products/new"
      component={ProductNew}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/products"
      component={Products}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/products/:id"
      component={ProductEdit}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/products/:id#show"
      component={ProductEdit}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/inventory_and_shipments"
      component={InventoryAndShipments}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/purchase_orders/new"
      component={PurchaseOrdersNew}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/purchase_orders/:id"
      component={PurchaseOrdersEdit}
      properties={properties}
    />  

    <PrivateRoute
      exact
      path="/shipments/new"
      component={ShipmentsNew}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/shipments/:id"
      component={ShipmentsEdit}
      properties={properties}
    />
    <PrivateRoute
      exact
      path="/shipments/:id#show"
      component={ShipmentsEdit}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/contact_and_faq"
      component={ContactAndFaq}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/no_permissions"
      component={NoPermissions}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/accounting_and_payment"
      component={AccountingAndPayment}
      properties={properties}
    />
    <PrivateRoute
      exact
      path="/delivery"
      component={Delivery}
      properties={properties}
    />
    <PrivateRoute
      exact
      path="/sales_overview"
      component={OrdersAndSales}
      properties={properties}
    />

    <PrivateRoute
      exact
      path="/admin_tools"
      component={AdminTools}
      properties={properties}
    />
    <Route component={NotFoundComponent} />
  </Switch>
);

export default DefinedRoutes;
