import { LOAD_SHIPMENTS } from '../actions/actions';

const shipmentsReducers = (state, action) => {
  switch (action.type) {
    case LOAD_SHIPMENTS:
      let newState = { ...state };
      newState.shipments = action.shipments;
      return newState;
    default:
      return state;
  }
};

export default shipmentsReducers;
