/** @jsx jsx */
/** @jsxFrag React.Fragment */
// eslint-disable-next-line
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Image from './Image';
import { colImage } from './ListImagesStyles';

const ListImagesComponent = ({ images, setImages, productId }) => {
  const [pos, setPos] = useState(-1);

  /* istanbul ignore next */
  const deleteImage = (index) => {
    let aux = [...images];
    aux.splice(index, 1);
    setImages(aux);
  };

  /* istanbul ignore next */
  const onDragEnd = (result) => {
    const { destination, source, reason } = result;

    // Not a thing to do...
    if (!destination || reason === 'CANCEL') {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const imagesL = [...images];
    const image = imagesL[source.index];
    imagesL.splice(source.index, 1);
    imagesL.splice(destination.index, 0, image);
    setImages(imagesL);
  };

  /* istanbul ignore next */
  const changeToPreviousImage = (position) => {
    setPos(((position - 1) + images.length) % images.length);
  };

  /* istanbul ignore next */
  const changeToNextImage = (position) => {
    setPos((position + 1) % images.length);
  };

  const resetPosition = () => {
    setPos(-1);
  }


  if (images && images.length > 0) {
    return (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Container fluid id="listImages">
            <Droppable direction="horizontal" droppableId={'imagesDropable'}>
              {(provided, snapshot) => (
                <Row ref={provided.innerRef} {...provided.droppableProps}>
                  {images.map((image, position) => (
                    <Draggable
                      draggableId={
                        (image.id && image.id.toString()) || position.toString()
                      }
                      index={position}
                      key={image.id}
                    >
                      {(provided, snapshot) => (
                        <Col
                          md={1}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          css={colImage}
                        >
                          <Image
                            key={image['id']}
                            image={image}
                            productId={productId}
                            position={position}
                            images={images}
                            deleteImage={deleteImage}
                            previous={changeToPreviousImage}
                            next={changeToNextImage}
                            onlyModal={pos == position}
                            resetPosition={resetPosition}
                          />
                        </Col>
                      )}
                    </Draggable>
                  ))}
                </Row>
              )}
            </Droppable>
          </Container>
        </DragDropContext>
      </>
    );
  }
  return null;
};

ListImagesComponent.propTypes = {
  productId: PropTypes.string,
};

export default ListImagesComponent;
