import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AddressesService from '../../../services/data/address_service';
import { createNotification } from '../../common/notifications';
import Modal from '../../common/ui/Modal';
import Form from './AddressForm';

const AddressNewSchema = Yup.object().shape({
  address1: Yup.string().required(),
  address2: Yup.string(),
  postal_code: Yup.string(),
  type: Yup.mixed().oneOf(['office', 'warehouse', 'port_of_landing']).required(),
  country_code: Yup.string(),
  label: Yup.string().required(),
  name: Yup.string(),
  state: Yup.string(),
});

const AddressNewComponent = (props) => {
  const { closeCreateLocationModal, loadAddresses, showCreateLocation } = props;

  /* istanbul ignore next */
  const handleCreateSubmit = (values, actions) => {
      /* istanbul ignore next */
    AddressesService.createAddress(values)
      .then((response) => {
        createNotification('success', 'You have created a new address');
        closeCreateLocationModal();
        loadAddresses();
      })
      .catch((error) => {
        console.log(error);
        createNotification('danger', 'Error creating a new address');
      });
  };

  return (
    <Formik
      initialValues={{
        address1: '',
        address2: '',
        postal_code: '',
        type: 'office',
        city: '',
        country_code: '',
        label: '',
        state: ''
      }}
      validationSchema={AddressNewSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleCreateSubmit(values, actions);
      }}
    >
      {({ setFieldValue, values }) => (
        <Modal
          onHide={closeCreateLocationModal}
          onCancel={closeCreateLocationModal}
          title="New Address"
          show={showCreateLocation}
        >
          <Form
            id="addressesNew"
            setFieldValue={setFieldValue}
            address={values}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default AddressNewComponent;
