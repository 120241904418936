import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Accordion, Card } from 'react-bootstrap';
import AddProductCategory from './AddProductCategory';
import Button from '../../common/ui/Button';
import EditableTable from '../../common/ui/EditableTable/EditableTable';
import CategoriesService from '../../../services/data/categories_service';
import { createNotification } from '../../common/notifications';
import GenericModal from '../../common/ui/GenericModal';
import PaginateComponent from '../../common/ui/PaginateComponent';
import Search from '../../common/ui/Search';
import Spinner from '../../common/ui/Spinner';

/**
 * Product categories manager
 * @returns {JSX.Element}
 * @constructor
 */
const ProductCategoryManagement = () => {
  const defaultModalState = {
    show: false,
    bodyMessage: '',
    buttons: [
      {
        id: 'accept',
        show: true,
        variant: 'primary',
        text: 'Accept',
      },
      {
        id: 'cancel',
        show: true,
        variant: 'secondary',
        text: 'Cancel',
      },
    ],
  };

  const [loadingCategories, setLoadingCategories] = useState(true);
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(defaultModalState);

  useEffect(() => {
    loadCategories();
  }, []);

  /**
   * Load all categories from backend.
   */
  /* istanbul ignore next */
  const loadCategories = () => {
    setLoadingCategories(true);
    CategoriesService.list().then((response) => {
      if (response.status === 200) {
        setAllCategories(response.data.categories);
        setCategories(response.data.categories);
      }
      setLoadingCategories(false);
    });
  };

  /**
   * Reset all modal settings. Usually called on modal hide.
   */
  /* istanbul ignore next */
  const resetModal = () => {
    setModal(defaultModalState);
  };

  /**
   * Adds a category.
   * @param name Category name
   * @returns {Promise<*>}
   */
  /* istanbul ignore next */
  const addCategory = (name) => {
    return CategoriesService.create({ name })
      .then((response) => {
        if (response.data) {
          const newCats = [...categories];
          newCats.push(response.data);
          setCategories(newCats);
          const newAllCats = [...allCategories];
          newAllCats.push(response.data);
          setAllCategories(newAllCats);
          createNotification('success', 'has been added', 'The category');
        }
        return response.data;
      })
      .catch((e) => {
        let message = e.response.data.error ?? e.message;
        createNotification('error', message);
        return false;
      });
  };

  /**
   * Edit a category
   * @param category Category object
   * @returns {Promise<*>}
   */
  /* istanbul ignore next */
  const editCategory = (category) => {
    return CategoriesService.update(category.id, category)
      .then((response) => {
        if (response.data) {
          const newCats = [...categories];
          const catIndex = newCats.findIndex(
            (cat) => cat.id === parseInt(category.id)
          );
          newCats[catIndex].name = category.name;
          setCategories(newCats);

          const newAllCats = [...allCategories];
          const allCatIndex = newAllCats.findIndex(
            (cat) => cat.id === parseInt(category.id)
          );
          newAllCats[allCatIndex].name = category.name;
          setAllCategories(newAllCats);
          createNotification('success', 'has been updated', 'The category');
        }
        return response.data;
      })
      .catch((e) => {
        let message = e.response.data.error ?? e.message;
        createNotification('error', message);
        return false;
      });
  };

  /**
   * Deletes a category
   * @param category Category object
   * @returns {Promise<*>}
   */
  /* istanbul ignore next */
  const deleteCategory = (category) => {
    let modalData = { ...defaultModalState };
    modalData.show = true;
    modalData.bodyMessage = `Are you sure you want to delete the category "${category.name}"? This action can not be undone`;
    modalData.buttons[0].onClick = () => {
      resetModal();

      // Send request to backend
      return CategoriesService.delete(category.id).then((response) => {
        if (response.data) {
          const newCats = categories.filter(
            (cat) => cat.id !== parseInt(category.id)
          );
          setCategories(newCats);
          const newAllCats = allCategories.filter(
            (cat) => cat.id !== parseInt(category.id)
          );
          setAllCategories(newAllCats);
          createNotification('success', 'has been deleted', 'The category');
        }
        return response.data;
      });
    };
    modalData.buttons[1].onClick = () => {
      resetModal();
    };
    setModal(modalData);
  };

  /**
   * Return spinner or content depeding on loading state.
   * @returns {JSX.Element|*}
   */
  const showSpinner = () => {
    if (loadingCategories) {
      return (
        <Container>
          <Row style={{ textAlign: 'center', marginTop: '20px' }}>
            <Col>
              <Spinner
                loading={loadingCategories}
                css={{ display: 'inline' }}
              />
            </Col>
          </Row>
        </Container>
      );
    }
    /* istanbul ignore next */
    return renderContent();
  };

  /**
   * Return categories manager.
   * @returns {JSX.Element}
   */
  const renderContent = () => (
    <Container>
      <Row>
        <Col>
          <h4>Product Category Management</h4>

          <Container>
            <Row>
              <Col>
                <Search
                  collection={allCategories}
                  setCollection={setCategories}
                  searchableFields={[{ id: 'name', type: 'text' }]}
                />
              </Col>
            </Row>
          </Container>

          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Add new category
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <AddProductCategory handleCreate={addCategory} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>

      <PaginateComponent
        componentList={
          <EditableTable
            handleEdit={editCategory}
            handleDelete={deleteCategory}
            columns={[{ id: 'name', name: 'Category', type: 'text' }]}
          />
        }
        list={categories}
        perPage={10}
      />

      <GenericModal
        show={modal.show}
        loading={modal.loading || false}
        onHide={modal.onHide || resetModal}
        buttons={modal.buttons}
        body={modal.bodyMessage}
      />
    </Container>
  );

  return showSpinner();
};

export default ProductCategoryManagement;
