export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
   * Given a units this method returns it in the proper format
   * @param units_of_measure String
   * @returns {string}
   */
export const formatUnitsOfMeasure = (units_of_measure) => {
  switch (units_of_measure) {
    case 'g':
      return 'G';
    case 'ml':
      return 'ML';
    case 'pcs':
      return 'PCS';
    default:
      return '';
  }
};
