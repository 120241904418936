import React from 'react';
import UserList from './UserList';

const ManageUsersComponent = (props) => {
  const { user} = props;

  if (user) {
    return <UserList />;
  }
  return null;
};

export default ManageUsersComponent;
