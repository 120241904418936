import React from 'react';
import { Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../common/ui/Button';
import Field from '../common/ui/Field';

const EditUserFormComponent = (props) => {
  return (
    <Container
      fluid
      style={{ marginTop: '15px', position: 'relative', height: '80vh' }}
    >
      <Form>
        <Row>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                className="form-control"
                type="text"
                name="user[name]"
                label="Name"
                placeholder=""
                disabled
              />
            </div>
          </Col>
          <Col md="12" lg="6">
            <div className="form-group row">
            <Field
                className="form-control"
                type="text"
                name="user[title]"
                label="Title"
                placeholder=""
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                className="form-control"
                type="email"
                name="user[email]"
                label="Email"
                placeholder=""
              />
            </div>
          </Col>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                type="tel"
                name="user[phone]"
                disabled
                label="Phone"
                placeholder=""
                labelStyle={{ marginTop: '8px' }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                className=""
                type="file"
                name="user[passport]"
                label="Passport"
                placeholder="Passport"
                style={{ marginLeft: '-12px', border: 'none' }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                className="form-control"
                type="password"
                name="user[current_password]"
                label="Current Password"
                placeholder=""
              />
            </div>
          </Col>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                className="form-control"
                type="password"
                name="user[password]"
                label="Password"
                placeholder="(leave blank if you don't want to change it)"
              />
            </div>
          </Col>
          <Col md="12" lg="6">
            <div className="form-group row">
              <Field
                className="form-control"
                type="password"
                name="user[password_confirmation]"
                label="Password Confirmation"
                placeholder=""
              />
            </div>
          </Col>
        </Row>
        <div className="actions row">
          <Button variant="primary" type="submit" disabled={props.isSubmitting}>
            Update
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditUserFormComponent;
