import React from 'react';
import { Table } from 'react-bootstrap';
import './Table.css';

const TableComponent = (props) => {
  const { children } = props;
  return (
    <Table
      responsive
      hover
      style={{ textAlign: 'center', ...props.style }}
    >
      {children}
    </Table>
  );
};

export default TableComponent;
