import React, { useState, useEffect } from 'react';
import Button from '../../common/ui/Button';
import PaginateComponent from '../../common/ui/PaginateComponent';
import MyContactsTable from './MyContactsTable';
import ContactNew from './ContactNew';
import ContactEdit from './ContactEdit';
import ContactsService from '../../../services/data/contacts_service';

const MyContactsComponent = (props) => {
  const [contacts, setContacts] = useState([]);
  const [, setLoadingContacts] = useState(false);
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);
  const [contactId, setContactId] = useState();
  const { company } = props;

  /* istanbul ignore next */
  const closeCreateContactModal = () => {
    setShowCreateContact(false);
  };

  /* istanbul ignore next */
  const showCreateContactModal = () => {
    setShowCreateContact(true);
  };

  /* istanbul ignore next */
  const closeEditContactModal = () => {
    setShowEditContact(false);
  };

  /* istanbul ignore next */
  const showEditContactModal = (id) => {
    setShowEditContact(true);
    setContactId(id);
  };

  /* istanbul ignore next */
  const loadContacts = () => {
    ContactsService.loadContacts()
      .then((response) => {
        setLoadingContacts(false);
        setContacts(response.data.contacts.sort((a,b) => {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        }));
      })
      .catch((error) => {
        setLoadingContacts(false);
      });
  };

  /* istanbul ignore next */
  const deleteContact = (id) => {
    if (window.confirm('Do you really want to delete it')){
      setLoadingContacts(true);
    ContactsService.deleteContact(id)
      .then((response) => {
        loadContacts();
      })
      .catch((error) => {
        setLoadingContacts(false);
      });
    }
  };

  useEffect(() => {
    loadContacts();
  }, []);

  return (
    <>
      <Button
        variant="primary"
        style={{ marginBottom: '10px' }}
        onClick={(_) => showCreateContactModal()}
      >
        Create Contact
      </Button>
      <ContactNew
        company={company}
        showCreateContact={showCreateContact}
        closeCreateContactModal={closeCreateContactModal}
        loadContacts={loadContacts}
      />

      <ContactEdit
        company={company}
        showEditContact={showEditContact}
        closeEditContactModal={closeEditContactModal}
        loadContacts={loadContacts}
        contactId={contactId}
      />
      <PaginateComponent
        componentList={
          <MyContactsTable
            showEditContactModal={showEditContactModal}
            deleteContact={deleteContact}
          />
        }
        list={contacts}
        perPage={10}
      />
    </>
  );
};

export default MyContactsComponent;
