import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropType from 'prop-types';
import LabelsService from '../../../services/data/labels_service';
import { createNotification } from '../../common/notifications';
import Button from '../../common/ui/Button';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const LabelEditComponent = ({ label }) => {

    const LabelSchema = Yup.object().shape({
        key: Yup.string(),
        body: Yup.string(),
      });

    const handleUpdateSubmit = (values, actions) => {
        LabelsService.updateLabel(values).then((response) => {
            actions.setSubmitting(false);
            createNotification('success', 'has been updated', 'The label');
            }).catch((error) => {
            actions.setSubmitting(false);
            createNotification(
                'error',
                'was not possible to update',
                'The label'
            );
            });
    };

    return (
        <Formik
            initialValues={{
                key: label.key,
                body: label.body,
            }}
            validationSchema={LabelSchema}
            onSubmit={(values, actions) => {
                /* istanbul ignore next */
                handleUpdateSubmit(values, actions);
            }}
        >
        {({ isSubmitting, setFieldValue, values }) => (
            <>
                <Form>
                    <Container fluid>
                        <h5>Label: {values.key}</h5>
                        <Row>
                        <Col lg="8">
                            <Field name="body">
                                {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                            </Field>
                        </Col>
                        </Row>
                        <Button style={{marginTop: '15px', marginLeft: '15px'}} variant="primary" type="submit" className="float-right" disabled={isSubmitting}> Submit</Button>
                    </Container>
                </Form>
            </>
        )}
        </Formik>
    );
};

LabelEditComponent.propTypes = {
    isSubmitting: PropType.bool,
    values: PropType.object,
    setFieldValue: PropType.func,
    handleSubmit: PropType.func,
  };

  
export default LabelEditComponent;