import axios from '../axios';

class InvoicesService {
  static loadInvoices = () => {
    return axios.get('/invoices');
  }

  static loadInvoice = (invoiceId) => {
    return axios.get(`/invoices/${invoiceId}`);
  }

  static getInvoiceFromOrder(orderId, invoices) {
    const filteredInvoices = invoices.filter((invoice) => invoice.order_id === orderId);
    return filteredInvoices[filteredInvoices.length - 1];
  }

  static createInvoice = (data) => axios.post('/invoices', {
    invoice: {
      order_number: data.order_number,
      platform_company_id: data.company.value,
      invoiced_at: data.invoiced_at,
      due_at: data.due_at,
      total: data.total,
      document_url: data.document_url
    }
  });

  static updateInvoice = (id, data) => axios.patch(`/invoices/${id}`, {
    invoice: {
      invoiced_at: data.invoiced_at,
      due_at: data.due_at,
      payment_status: data.payment_status.value,
      total: data.total,
      document_url: data.document_url
    }
  });

  static deleteInvoice = (id) => axios.delete(`/invoices/${id}`)
}

export default InvoicesService;
