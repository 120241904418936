import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Label from '../../common/ui/Label';

const MyAccountActivationComponent = () => {
  /* istanbul ignore next */
  return (
    <Container fluid style={{ marginTop: '15px' }}>
      <Row>
        <Col>
          <Label labelKey="profile.user_guide"></Label>
        </Col>
      </Row>
    </Container>
  );
};

export default MyAccountActivationComponent;
