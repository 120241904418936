import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompaniesService from '../../../services/data/companies_service';
import Search from '../../common/ui/Search';
import CompanyAgreementTable from './CompanyAgreementTable';
import CompanyAgreementsService from '../../../services/data/company_agreements_service';
import { createNotification } from '../../common/notifications';
import PaginateComponent from '../../common/ui/PaginateComponent';
import AdjustPaginateButtons from '../../common/ui/AdjustPaginateButtons';

const CompanyAgreements = () => {
    const [agreements, setAgreements] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [perPage, setPerPage] = useState(20);

    const loadCompanyAgreements = () => {
        CompaniesService.loadCompanies().then((response) => {
            let total_agreements = [];
            
            response.data.companies.forEach((company) => {
                if (company.company_agreements.length > 0){
                    company.company_agreements.forEach((agreement) => {
                        total_agreements.push(agreement);
                    });
                };
            });
            
            setAgreements(total_agreements);
            setAllCompanies(total_agreements);
        });

    };

    const deleteCompanyAgreement = (company_agreement) => {
        if (window.confirm('Do you really want to delete it')){
          // Send request to backend
          return CompanyAgreementsService.deleteCompanyAgreement(company_agreement.id)
            .then((response) => {
              if(response.data && response.data.error){
                createNotification(
                  'error',
                  response.data.error
                );
                return null;
              }
              createNotification(
                'success',
                `Company Agreement has been deleted`
              );
              loadCompanyAgreements();
            })
            .catch((error) => {
              console.warn(error);
              createNotification(
                'error',
                'was not possible to destroy',
                'The company agreement'
              );
            });
        };
    };

    useEffect(() => {
        loadCompanyAgreements();
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Search
                            collection={agreements}
                            setCollection={setAgreements}
                            searchableFields={[{ id: 'name', type: 'text' }]}
                            allCollection={allCompanies}
                        />
                    </Col>
                </Row>
            </Container>

            <PaginateComponent
                componentList={<CompanyAgreementTable collection={agreements} loadCompanyAgreements={loadCompanyAgreements} deleteCompanyAgreement={deleteCompanyAgreement} />}
                list={agreements}
                cointainerClassName="pagination-reduced"
                perPage={perPage}
            />

            <AdjustPaginateButtons
                list={agreements}
                perPageList={[25, 50, 100]}
                setPerPage={setPerPage}
            />
        </>
    );
};

export default CompanyAgreements;