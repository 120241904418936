import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from './CertificationEditForm';
import CertificationsService from '../../../../services/data/certifications_service';
import {
  createNotification,
  delayedRedirect,
} from '../../../common/notifications';
import Modal from '../../../common/ui/Modal';

const CertificationEditSchema = Yup.object().shape({
  name: Yup.string(),
  start_date: Yup.date(),
  end_date: Yup.date(),
  document: Yup.string(),
});

const CertificationEditComponent = ({
  certification,
  productId,
  loadCertifications,
  closeEditCertificationModal,
  showEditCertification,
}) => {
  /* istanbul ignore next */
  const handleUpdateSubmit = (values, actions) => {
    CertificationsService.updateCertification(certification.id, values)
      .then((response) => {
        createNotification('success', 'has been updated', 'The certificate');
        loadCertifications();
      })
      .catch((error) => {
        delayedRedirect(`/products/${productId}`);
        createNotification(
          'error',
          'was not possible to update',
          'The certification'
        );
      });
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: certification.name,
        start_date: certification.start_date,
        end_date: certification.end_date,
        document: '',
      }}
      validationSchema={CertificationEditSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleUpdateSubmit(values, actions);
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeEditCertificationModal}
          onCancel={closeEditCertificationModal}
          title="Edit Certification"
          show={showEditCertification}
        >
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            values={values}
            certification={certification}
          />
        </Modal>
      )}
    </Formik>
  );
};

CertificationEditComponent.propTypes = {
  showEditCertification: PropTypes.bool,
  closeEditCertificationModal: PropTypes.func,
  productId: PropTypes.number,
  certification: PropTypes.object,
};

export default CertificationEditComponent;
