import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '../../common/ui/Button';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import Spinner from '../../common/ui/Spinner';
import OrdersService from '../../../services/data/orders_service';
import OrdersOffline from '../../orders_and_sales/OrdersOffline';
import ProductsService from '../../../services/data/products_service';
import CompaniesService from '../../../services/data/companies_service';

const BulkUploadSchema = Yup.object().shape({
  document: Yup.string().required(),
});

/**
 * Form for creating orders described in a CSV file.
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const BulkOrdersUpload = (user, company) => {
  const [loading, setLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const [submitErrors, setSubmitErrors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);

  /* istanbul ignore next */
  const bulkUploadManager = (values, actions) => {
    setLoading(true);
    setSubmitResponse(null);
    setSubmitErrors([]);
    OrdersService.bulkCreation(values.document)
      .then((response) => {
        if (response.data.errors) {
          setSubmitErrors(response.data.errors);
        } else {
          setSubmitResponse(response.data);
        }
        setLoading(false);
        setOrdersLoading(true);
      })
      .catch((reason) => {
        setSubmitErrors(['There was an unexpected error.']);
        setLoading(false);
        setOrdersLoading(true);
      });
  };

  useEffect(() => {
    OrdersService.loadOrders("week",true)
      .then((response) => {
        setOrders(response.data.orders);
        setOrdersLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    CompaniesService.loadCompanies()
      .then((response) => {
        setCompanies(response.data.companies);
      })
      .catch((error) => {
        console.log(error);
      });
      // eslint-disable-next-line
  },[submitResponse]);

  return (
    <Container>
      <Row>
        <Col>
          <h4>Bulk Orders</h4>
          {submitResponse && (
            <Alert variant={'success'}>
              Orders created/updated:
              <ul>
                {submitResponse.map((order, i) => (
                  <li key={'successOrder' + i}>{order.order_number}</li>
                ))}
              </ul>
            </Alert>
          )}
          {submitErrors &&
            submitErrors.map((error, i) => {
              return (
                <Alert variant={'danger'} key={'errorMessage' + i}>
                  {error}
                </Alert>
              );
            })}
          {!loading ? (
            <Formik
              initialValues={{
                document: '',
              }}
              validationSchema={BulkUploadSchema}
              onSubmit={(values, actions) => {
                /* istanbul ignore next */
                bulkUploadManager(values, actions);
              }}
            >
              {({ isSubmitting, setFieldValue, errors }) => (
                <Form>
                  <div className="form-group">
                    <label>{'Document'}</label>
                    <input
                      className={
                        'form-control' +
                        (errors.document ? ' needs-validation' : '')
                      }
                      name="document"
                      type="file"
                      accept=".xlsx, .csv"
                      onChange={(event) => {
                        /* istanbul ignore next */
                        setFieldValue('document', event.currentTarget.files[0]);
                      }}
                      required
                    />
                    {errors.document && (
                      <ErrorMessage
                        component="small"
                        name="document"
                        className={'text-danger'}
                      />
                    )}
                  </div>
                  <Button
                    id="submit"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting && !errors}
                    style={{ marginTop: '20px'}}
                  >
                    Create orders
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner
              loading={loading}
              marginTop={'20px'}
              css={{ display: 'inline' }}
            />
          )}
        </Col>
      </Row>

      <Row style={{ textAlign: 'center' }}>
        <Col></Col>
      </Row>
      <Row>
        <Col lg="12" style={{ marginTop: '20px'}}>
          <OrdersOffline
            orders={orders}
            ordersLoading={ordersLoading}
            setOrdersLoading={setOrdersLoading}
            adminView={true}
            company={company}
            companies={companies}
            user={user}
            template={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BulkOrdersUpload;
