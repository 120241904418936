import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Spinner from 'react-spinners/BeatLoader';

const styles = {
  lightBorder: '1px solid rgba(0,0,0,.125)',
};

function DashboardCard({ title, values, children, loadingDashboardData, dataFullColumn }) {
  const displayValues = () => {
    if (loadingDashboardData) {
      return (
        <>
          <Row>
            <Col
              style={{ textAlign: 'center', marginTop: '10px', minHeight: '25px' }}
              span={values.length}
            >
              <Spinner
                loading={loadingDashboardData}
                css={{ display: 'inline', position: 'relative', top: '5px' }}
              />
            </Col>
          </Row>
        </>
      );
    }
    if (values) {
      return (
        <>
          <Row className="text-center" style={{ margin: 0 }}>
            {values.map((value, index) => (
              <Col
                key={index}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: '.75rem',
                  paddingBottom: children ? '.75rem' : 0,
                }}
              >
                {/* //Revert this commit when dashboard works as expected
                {value.value} */}
                {value.value}
              </Col>
            ))}
          </Row>
          {children}
        </>
      );
    }
  };
  return (
    <Card
      style={{
        marginBottom: '1.25rem',
        borderWidth: 2,
        borderTop: '5px solid #1B5DA0',
      }}
    >
      <Card.Body style={{ padding: 0 }}>
        <Card.Title
          className="text-center"
          style={{
            paddingTop: 15,
            paddingBottom: '.75rem',
            marginBottom: 0,
            borderBottom: styles.lightBorder,
          }}
        >
          {title}
        </Card.Title>
        <div
          className="card-text"
          style={{
            paddingBottom: children ? 0 : '.75rem',
            paddingTop: '.75rem',
            marginBottom: 0,
          }}
        >
          <Row
            className="text-uppercase text-muted text-center"
            style={{ margin: 0, borderBottom: styles.lightBorder }}
          >
            {values.map((value, index) => (
              <Col
                key={index}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: '.75rem',
                }}
              >
                {value.name}
              </Col>
            ))}
          </Row>
          {displayValues()}
        </div>
      </Card.Body>
    </Card>
  );
}

export default DashboardCard;
