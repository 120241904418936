
import axios from '../axios';

class ContactMessagesService {
  static createContactMessage = (data) => axios.post('/contact_messages', {
      contact_message: {
          subject: data.subject,
          body: data.body
      }
  });
 
}

export default ContactMessagesService;
