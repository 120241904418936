import React from 'react';
import { Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Select from '../../common/ui/Select';
import Field from '../../common/ui/Field';
import EditableInput from '../../common/ui/EditableInput';

const OverviewForm = ({
  values,
  setFieldValue,
  handleSubmit,
  readonly,
  categories,
  disabled,
}) => (
  <Container fluid>
    <Form id="overviewForm">
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="description"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'description'}
            label={'Description'}
            direction="block"
            text={values.description}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="ingredients"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'ingredients'}
            label={'Ingredients'}
            direction="block"
            text={values.ingredients}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="allergensWarning"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'allergensWarning'}
            label={'Allergens warning'}
            direction="block"
            text={values.allergensWarning}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="aboutTheBrand"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'aboutTheBrand'}
            label={'About the brand'}
            direction="block"
            text={values.aboutTheBrand}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="8">
          <EditableInput
            inputComponent={
              <Select
                name="categories"
                options={categories}
                value={
                  values.categories !== null
                    ? categories.filter((cat) =>
                        values.categories.includes(cat.value)
                      )
                    : null
                }
                onChange={(value) => {
                  /* istanbul ignore next */
                  if (value !== null) {
                    setFieldValue(
                      'categories',
                      value.map((val) => val.value)
                    );
                  } else {
                    setFieldValue('categories', null);
                  }
                }}
                multiple
                className=""
              />
            }
            field={'categories'}
            direction="block"
            placeholder="Categories"
            label="Categories"
            labelContainerClassName={'edit-field-label-container-textarea'}
            text={
              /* istanbul ignore next */
              values.categories !== null
                ? categories
                    .filter((cat) => values.categories.includes(cat.value))
                    .map((category) => category.label)
                : ''
            }
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            disabled={readonly}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="highlight1"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'highlight1'}
            label={'Product Highlight 1'}
            direction="block"
            text={values.highlight1}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="highlight2"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'highlight2'}
            label={'Product Highlight 2'}
            direction="block"
            text={values.highlight2}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="highlight3"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'highlight3'}
            label={'Product Highlight 3'}
            direction="block"
            text={values.highlight3}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <EditableInput
            inputComponent={
              <Field
                name="howToUse"
                type="text"
                as="textarea"
                className={'textareaReduced'}
                placeholder=""
                noCol={true}
                labelStyle={{ width: '130px', flexGrow: '0.2' }}
                label=""
              />
            }
            field={'howToUse'}
            label={'How To Use'}
            direction="block"
            text={values.howToUse}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
            labelContainerClassName={'edit-field-label-container-textarea'}
          />
        </Col>
      </Row>
    </Form>
  </Container>
);

export default OverviewForm;
