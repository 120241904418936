import React from 'react';
import Badge from '../../common/ui/Badge';
import { formattedPrice } from '../../common/dataTypes/prices';
import { formattedDate } from '../../common/dataTypes/dates';
import Button from '../../common/ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

const InvoiceRowComponent = (props) => {
    const { invoice, showEditInvoiceModal, handleDelete} = props;

    return(
        <tr key={invoice.id}>
            <td>{invoice.order_number}</td>
            <td>{invoice.reference}</td>
            <td>{invoice.company_name ? invoice.company_name : ""}</td>
            <td>{formattedDate(invoice.invoiced_at)}</td>
            <td>{formattedDate(invoice.due_at)}</td>
            <td>
                {
                    invoice.payment_status ? <Badge content={invoice.payment_status} /> : "None"
                }
            </td>
            <td>{formattedPrice(invoice.total, 'sgd')}</td>
            <td><a href={invoice.document_url} target="_blank" rel="noopener noreferrer">Document</a></td>
            <td>
                <Button
                    variant="link"
                    onClick={() => {
                        /* istanbul ignore next */
                        showEditInvoiceModal(invoice.id);
                    }}
                >
                    <FontAwesomeIcon
                        className="editAction"
                        icon={faPencilAlt}
                    />
                </Button>
                <Button
                    variant="link"
                    onClick={() => {
                        /* istanbul ignore next */
                        handleDelete(invoice.id);
                    }}
                >
                    <FontAwesomeIcon
                        className="deleteAction"
                        icon={faTrashAlt}
                    />
                </Button>
            </td>
        </tr>
    );
};

export default InvoiceRowComponent;