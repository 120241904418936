import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from '../common/ui/Button';

const popover = (
  <Popover id="popover-basic" style={{ padding: '10px' }}>
    <Popover.Content style={{ padding: '0px' }}>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Link to="/users/password/new">Forgot your password?</Link>
            <br />
          </Col>
          <Col lg="12">
            <Link to="/users/confirmation/new">
              {'Resend confirmation instructions?'}{' '}
            </Link>
            <br />
          </Col>
        </Row>
      </Container>
    </Popover.Content>
  </Popover>
);

const AnyProblemHint = () => {
  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Button
        variant="link"
        style={{
          color: '#007bff',
          textDecoration: 'none',
          backgroundColor: 'transparent',
          float: 'right',
        }}
        id={"anyProbButton"}
      >
        Need Help?
      </Button>
    </OverlayTrigger>
  );
};

export default AnyProblemHint;
