/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { useState } from 'react';
import { jsx } from '@emotion/core';
import { Transition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import logoReduced from '../../assets/images/logo-reduced.png';
import logo from '../../assets/images/logo-white-version.svg';
import BottomLinks from './BottomLinks';
import UpLinks from './UpLinks';
import { sidebarStyles } from './SidebarStyles.js';

const SidebarComponent = (props) => {
  const { reduced, setReduced, user, company, location, handleLogout } = props;
  const [mouseHover, setMouseHover] = useState(false);

  const duration = 400;

  const defaultStyle = {
    transition: `width  ${duration}ms ease-in-out `,
  };
  const transitionStyles = {
    exited: { width: `${reduced ? '80px' : '240px'}` },
  };

  return (
    <Transition>
      {(state) => (
        <div
          className={`bg-light d-md-block ${reduced ? 'reduced' : ''}`}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          id="sidebar-wrapper"
          css={sidebarStyles}
        >
          <div style={{ position: 'relative' }}>
            <div
              style={{ height: '100vh', width: '80%', position: 'absolute' }}
              onMouseEnter={() => {
                /* istanbul ignore next */
                if (reduced) {
                  setMouseHover(true);
                  setReduced(false);
                }
              }}
              onMouseLeave={() => (mouseHover ? setReduced(true) : '')}
            >
              <div className="sidebar-heading">
                <Link to="/">
                  <img
                    alt="logo"
                    height={60}
                    src={reduced ? logoReduced : logo}
                  ></img>
                </Link>
              </div>

              <div
                className="list-group list-group-flush"
                style={{ marginTop: '15px' }}
              >
                <UpLinks user={user} location={location} />
              </div>
              <div className="list-bottom">
                <BottomLinks
                  user={user}
                  location={location}
                  company={company}
                  handleLogout={handleLogout}
                  reduced={reduced}
                />
              </div>
            </div>
          </div>
          {reduced ? (
            <FontAwesomeIcon
              icon={faAngleRight}
              className={'collapseButton'}
              size="lg"
              style={{
                float: 'right',
                color: '#283A6D',
                background: 'white',
                position: 'fixed',
                bottom: '132px',
                left: '70px',
                borderRadius: '30px',
                width: '21.33px',
                border: '1px solid #E6E6E6',
              }}
              onClick={() => {
                /* istanbul ignore next */
                setReduced(!reduced);
                /* istanbul ignore next */
                setMouseHover(false);
                /* istanbul ignore next */
                localStorage.setItem('reducedSidebar', !reduced);
              }}
            >
              Toggle
            </FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              icon={faAngleLeft}
              className={'collapseButton'}
              size="lg"
              style={{
                float: 'right',
                color: '#283A6D',
                background: 'white',
                position: 'fixed',
                bottom: '132px',
                left: '230px',
                borderRadius: '30px',
                width: '21.33px',
                border: '1px solid #E6E6E6',
                paddingRight: '3px',
              }}
              onClick={() => {
                /* istanbul ignore next */
                setReduced(!reduced);
                /* istanbul ignore next */
                localStorage.setItem('reducedSidebar', !reduced);
              }}
            >
              Toggle
            </FontAwesomeIcon>
          )}
        </div>
      )}
    </Transition>
  );
};

export default SidebarComponent;
