import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Tab } from 'react-bootstrap';
import Can from '../roles/Can';
import InnerLayout from '../layout/InnerLayout';
import Tabs from '../common/ui/Tabs';
import Inventory from './inventory/Inventory';
import Shipments from './shipments/Shipments';
import PurchaseOrders from './purchase_orders/PurchaseOrders';

const InventoryAndShipmentComponent = (props) => {
  const {
    orders,
    invoices,
    products,
    loadProducts,
    logOut,
    user,
    location,
    company,
  } = props;
  const defaultTab = location.hash  ? location.hash.substring(1) : 'inventory';

  if (user) {
    return (
      <Can
        role={user.type.toLowerCase()}
        perform="InventoryAndShipments:list"
        yes={() => (
          <InnerLayout
            logOut={logOut}
            user={user}
            location={location}
            company={company}
          >
            <Tabs
              defaultActiveKey={defaultTab}
              id="tabs_inventory_and_shipments"
            >
              <Tab eventKey="inventory" title="Inventory">
                <Inventory products={products} loadProducts={loadProducts} company={company} />
              </Tab>
              <Tab eventKey="purchase_orders" title="Purchase Orders">
                <PurchaseOrders user={user} localProducts={products} adminView={false} company={company} />
              </Tab>
            </Tabs>
          </InnerLayout>
        )}
        no={() => <Redirect to="/no_permissions" />}
      />
    );
  }
  return null;
};

InventoryAndShipmentComponent.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadProducts: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

export default InventoryAndShipmentComponent;
