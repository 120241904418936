import React from 'react';
import Table from '../../common/ui/Table';
import Agreement from './Agreement';

const AgreementTable = ({ collection, loadAgreements }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{width: '400px'}}>Company</th>
            <th>Document</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {collection.map((company) => (
            <Agreement company={company} loadAgreements={loadAgreements} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AgreementTable;