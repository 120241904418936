import axios from '../axios';

class LicencesService {
  static loadLicences = (companyId) =>
    axios.get('/licences', { params: { company_id: companyId } });

  static updateLicence = (licenceId, licence) => {
    let formData = new FormData();
    formData.append('licence[name]', licence.name);
    formData.append('licence[licence_number]', licence.licence_number);
    formData.append('licence[start_date]', licence.start_date);
    formData.append('licence[end_date]', licence.end_date);
    formData.append('licence[document]', licence.document);
    return axios.patch(`/licences/${licenceId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static destroyLicence = (licenceId) => {
    return axios.delete(`/licences/${licenceId}`);
  };
}

export default LicencesService;
