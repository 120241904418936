import React, { useState } from 'react';
import Button from '../../common/ui/Button';
import AgreementEdit from './AgreementEdit';
import { host } from '../../../services/axios';

const Agreement = ({ company, loadAgreements }) => {
  const [showEditAgreement, setShowEditAgreement] = useState(false);

  /* istanbul ignore next */
  const showEditModal = () => {
    setShowEditAgreement(true);
  };

  /* istanbul ignore next */
  const hideEditModal = () => {
    setShowEditAgreement(false);
  };
  return (
    <tr>
      <td>{company.name}</td>
      <td>
        {company.agreement.agreement_url === '/images/original/missing.png' ? (
          ''
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={host + company.agreement.agreement_url}
          >
            {company.agreement.agreement_file_name}
          </a>
        )}
      </td>
      <td>
        <Button variant="primary" onClick={(_) => showEditModal()}>
          Edit
        </Button>
      </td>
      <AgreementEdit
        showEditAgreement={showEditAgreement}
        company={company}
        closeEditAgreementModal={hideEditModal}
        loadAgreements={loadAgreements}
      />
    </tr>
  );
};

export default Agreement;