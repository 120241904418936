import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import DefinedRoutes from './DefinedRoutes';
import LoginService from '../services/login';
import CompaniesService from '../services/data/companies_service';
import StorageService from '../services/storage_service';
import {
  loadProducts,
  loadShipments,
  loadInvoicesAndPayments,
  logIn,
  logOut,
  setUser,
  loadCompany,
} from '../actions/actions';

const mapStateToProps = (state) => {
  const {
    products,
    invoices,
    payments,
    refunds,
    loggedIn,
    user,
    shipments,
    company,
  } = state;
  return {
    products,
    invoices,
    payments,
    refunds,
    loggedIn,
    user,
    shipments,
    company,
  };
};

const AppContainerComponent = (props) => {
  const { setUser, company, loadCompany, logOut } = props;
  const logOutUser = useCallback(() => {
    StorageService.clearBearer();
    StorageService.clearUserData();
    logOut();
    window.location.href = '/users/sign_in';
  }, [logOut]);

  useEffect(() => {
    LoginService.getUser()
      .then((response) => {
        setUser({
          id: response.data.id,
          type: response.data.type,
          validated: response.data.validated,
          company: response.data.company_id,
          activation_messages: response.data.activation_messages,
        });

        if (!company) {
          CompaniesService.loadCompany(response.data.platform_company_id)
            .then((response) => {
              loadCompany(response.data.company);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        if (
          typeof(error.response) === 'object' &&
          error.response.data.error === 'Signature has expired'
        ) {
          logOutUser();
        }
      });

    const user = StorageService.getUserData();
    setUser(user);
  }, [logOutUser, setUser, loadCompany, company]);

  return (
    <Router>
      <DefinedRoutes {...props} />
    </Router>
  );
};

AppContainerComponent.defaultProps = {
  invoices: [],
  orders: [],
};

AppContainerComponent.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  invoices: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  loadInvoicesAndPayments: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

const MainContainer = connect(mapStateToProps, {
  loadProducts,
  loadInvoicesAndPayments,
  logIn,
  logOut,
  setUser,
  loadShipments,
  loadCompany,
})(AppContainerComponent);

export default MainContainer;
