import axios from '../axios';

class OfflineInventoryService {

  static getInventory(){
    return axios.get('/offline_inventories');
  }

  static getInventoryXLSX(template = 'false') {
    return axios.request({
      url: `/offline_inventories.xlsx?template=` + template,
      method: 'GET',
      responseType: 'blob',
    });
  }

  /**
   * Send CSV file to backend for orders bulk creation.
   * @param file Object from input type file
   */
  static bulkCreation(file) {
    let formData = new FormData();
    formData.append('inventory[inventory_file]', file);
    return axios.post('offline_inventories', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default OfflineInventoryService;
