import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InvoicesService from '../../../services/data/invoices_service';
import { createNotification } from '../../common/notifications';
import { formattedPrice } from '../../common/dataTypes/prices';
import { formattedDate } from '../../common/dataTypes/dates';
import Modal from '../../common/ui/Modal';
import Form from './InvoiceForm';

const InvoicesNewSchema = Yup.object().shape({
    order_number: Yup.string().required(),
    company: Yup.object().required(),
    invoiced_at: Yup.date().required(),
    due_at: Yup.date().required(),
    total: Yup.number().required(),
    document_url: Yup.string().required(),
});

const InvoicesNewComponent = (props) => {
    const { showCreateInvoice, closeCreateInvoiceModal, loadInvoices, companies } = props;
    
    /* istanbul ignore next */
    const handleCreateSubmit = (values, actions) => {
        InvoicesService.createInvoice(values)
            .then((response) => {
                createNotification('success', 'You have created a new invoice');
                closeCreateInvoiceModal();
                loadInvoices();
            })
            .catch((error) => {
                console.log(error);
                createNotification('danger', 'Error creating a new invoice');
            });
    };

    return (
        <Formik
            initialValues={{
                order_number: '',
                company: companies && companies[0] && {
                    value: companies[0].id,
                    label: companies[0].name,
                },
                invoiced_at: '',
                due_at: '',
                total: 0,
                document_url: '',
            }}
            validationSchema={InvoicesNewSchema}
            onSubmit={(values, actions) => {
                /* istanbul ignore next */
                handleCreateSubmit(values, actions);
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal
                    onHide={closeCreateInvoiceModal}
                    onCancel={closeCreateInvoiceModal}
                    title="New Invoice"
                    show={showCreateInvoice}
                >
                    <Form
                        id="invoicesNew"
                        setFieldValue={setFieldValue}
                        invoice={values}
                        companies={companies}
                        edit={false}
                    />
                </Modal>
            )}
        </Formik>
    );
};

export default InvoicesNewComponent;