import React from 'react';
import Table from '../../common/ui/Table';
import { formattedPrice } from '../../common/dataTypes/prices';

const InventoryTabContentComponent = (props) => {
  const { productsInStock, productsNoStock, inventoryValue } = props;
  return (
    <Table>
      <thead>
        <tr>
          <th>Products in stock</th>
          <th>Products with no Stock</th>
          <th>Inventory value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{productsInStock}</td>
          <td>{productsNoStock}</td>
          <td>{formattedPrice(inventoryValue, props.company?.preferred_currency)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default InventoryTabContentComponent;
