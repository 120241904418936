import { css } from '@emotion/core';

export const externalWrapper = css`
  background: #263263; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #263263 0%,
    #273d72 50%,
    #0e65ad 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #263263 0%,
    #273d72 50%,
    #0e65ad 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #263263 0%,
    #273d72 50%,
    #0e65ad 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#263263', endColorstr='#0e65ad',GradientType=1 ); /* IE6-9 */

  min-height: 100vh;
  height: 100%;
  text-align: center;
`;

export const externalContentWrapper = css`
  margin: 0 auto;
  margin-top: 50%;
  padding: 20px;
  background: white;
  width: fit-content;
  max-width: 500px;
  min-width: 450px;
  border-radius: 10px;
  text-align: left;
  padding-top: 35px;
`;
