import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Field from '../common/ui/OuterField';
import ErrorMessage from '../common/ui/ErrorMessage';
import NormalField from '../common/ui/Field';
import FlatButton from '../common/ui/FlatButton';
import LoginService from '../../services/login';
import OuterLayout from '../layout/OuterLayout';
import { createNotification, delayedRedirect } from '../common/notifications';

const UserForgotPasswordSchema = Yup.object().shape({
  reset_password_token: Yup.string(),
  password: Yup.string().required('Password required'),
  password_confirmation: Yup.string()
    .required('Password Confirmation is required')
    .test('email-match', 'Password must match', function (value) {
      /* istanbul ignore next */
      return this.parent.password === value;
    }),
});

const UserForgotPasswordLinkComponent = (props) => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  /* istanbul ignore next */
  const handleSubmit = (action, values) => {
    action.setSubmitting(true);
    LoginService.passwordReset(values)
      .then((response) => {
        action.setSubmitting(false);
        setPasswordChanged(true);
        createNotification('success', 'Your password has been changed');
        delayedRedirect('/');
      })
      .catch((error) => {
        action.setSubmitting(false);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key) => {
            return createNotification(
              'error',
              key + ' ' + error.response.data.errors[key]
            );
          });
        } else {
          createNotification('error', 'Error requesting new password');
        }
      });
  };

  if (!passwordChanged) {
    return (
      <OuterLayout>
        <Formik
          initialValues={{
            reset_password_token: props.location.search.split('=')[1],
            password: '',
            password_confirmation: '',
          }}
          enableReinitialize={true}
          validationSchema={UserForgotPasswordSchema}
          onSubmit={(values, actions) => handleSubmit(actions, values)}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Container fluid>
              <Form id="forgotPasswordLinkForm">
                <div className="">
                  <NormalField type="hidden" name="reset_password_token" />
                </div>
                <Row>
                  <Col>
                    <div className="form-group">
                      <Field
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="New Password"
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage component="small" name="password" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <Field
                        className="form-control"
                        type="password"
                        name="password_confirmation"
                        placeholder="New Password Confirmation"
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage
                        component="small"
                        name="password_confirmation"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="">
                      <FlatButton
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        style={{ float: 'right' }}
                      >
                        Change your Password
                      </FlatButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Container>
          )}
        </Formik>
      </OuterLayout>
    );
  } else {
    /* istanbul ignore next */
    return (
      <OuterLayout externalContentWrapperStyles={{ padding: '20px' }}>
        <Container fluid>
          <Row>
            <Col>Your password has been changed</Col>
          </Row>
          <Row>
            <Col>
              <Link to="/">Login</Link>
            </Col>
          </Row>
        </Container>
      </OuterLayout>
    );
  }
};

export default UserForgotPasswordLinkComponent;
