import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencilAlt,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Row element in a EditableTable.
 * @see EditableTableComponent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EditableTableRowComponent = (props) => {
  const { rowObject, columns, handleEdit, handleDelete } = props;
  const [editMode, setEditMode] = useState(false);
  const [editableObject, setEditableObject] = useState(rowObject);

  /**
   * Gets column content
   * @param column column description
   * @returns {JSX.Element|*}
   */
  /* istanbul ignore next */
  const columnTagContent = (column) => {
    if (editMode) {
      if (column.type === 'text') {
        return (
          <input
            type="text"
            value={editableObject[column.id]}
            name={column.id}
            onChange={(event) => {
              const newObject = { ...editableObject };
              newObject[event.target.name] = event.target.value;
              setEditableObject(newObject);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                editRowObject();
              }
              // escape key maps to keycode `27`
              else if (e.keyCode === 27) {
                setEditMode(false);
              }
            }}
          />
        );
      } else {
        return <>TBD</>;
      }
    } else {
      return editableObject[column.id];
    }
  };

  /**
   * Get action column content
   */
  /* istanbul ignore next */
  const getActionColumnContent = () => {
    if (editMode) {
      return (
        <>
          <Button
            variant="icon"
            onClick={editRowObject}
            style={{ marginRight: '5px', padding: '9px 12px' }}
          >
            <FontAwesomeIcon className="acceptChangesAction" icon={faCheck} />
          </Button>
          <Button variant="icon" onClick={() => setEditMode(false)}>
            <FontAwesomeIcon className="cancelChangesAction" icon={faTimes} />
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            variant="link"
            onClick={() => setEditMode(true)}
            style={{ marginRight: '5px', padding: '9px 12px' }}
          >
            <FontAwesomeIcon className="editAction" icon={faPencilAlt} />
          </Button>
          <Button variant="link" onClick={deleteRowObject}>
            <FontAwesomeIcon className="deleteAction" icon={faTrashAlt} />
          </Button>
        </>
      );
    }
  };

  /**
   * Handle edition call for a row.
   */
  /* istanbul ignore next */
  const editRowObject = () => {
    handleEdit(editableObject).then((response) => {
      if (response) {
        setEditMode(false);
      }
    });
  };

  /**
   * Handle delete call for a row.
   */
  /* istanbul ignore next */
  const deleteRowObject = () => {
    handleDelete(editableObject);
  };

  return (
    <tr>
      {columns.map((column) => (
        <td key={column.id}>{columnTagContent(column)}</td>
      ))}
      {/* Actions */}
      <td>{getActionColumnContent()}</td>
    </tr>
  );
};

EditableTableRowComponent.propTypes = {
  rowObject: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default EditableTableRowComponent;
