import { LOG_IN, LOG_OUT } from '../actions/actions';

const loginReducers = (state, action) => {
  const newState = { ...state };
  const newState2 = { ...state };
  switch (action.type) {
  case LOG_IN:
    newState.loggedIn = true;
    return newState;
  case LOG_OUT:
    newState2.loggedIn = false;
    return newState2;
  default:
    return state;
  }
};

export default loginReducers;
