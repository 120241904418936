import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '../../common/ui/Button';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import { createNotification } from '../../common/notifications';
import FileDownload from '../../common/dataTypes/fileDownload';
import Spinner from '../../common/ui/Spinner';
import ActivationMessagesService from '../../../services/data/activation_messages_service';

const ActivationMessageSchema = Yup.object().shape({
  document: Yup.string().required(),
});

/**
 * Form for creating orders described in a XLSX file.
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const ActivationMessage = (user, company) => {
  const [loading, setLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const [submitErrors, setSubmitErrors] = useState([]);

  /* istanbul ignore next */
  const bulkUploadManager = (values, actions) => {
    setLoading(true);
    setSubmitResponse(null);
    setSubmitErrors([]);
    ActivationMessagesService.bulkCreation(values.document)
    .then((response) => {
        if (response.data.errors) {
          setSubmitErrors(response.data.errors);
        } else {
          setSubmitResponse(response.data);
        }
        setLoading(false);
      })
      .catch((reason) => {
        setLoading(false);
        reason.response.data.errors.forEach((error) => {
            createNotification('error', error);
            setSubmitErrors(['There was an unexpected error: ' + error]);
        });
      });
  };

  const exportAllHandler = (template = false) => {
    createNotification('success', 'Your messages are being exported. The file will be downloaded in a while');
    ActivationMessagesService.getMessagesXLSX(template).then((response) => {
      const download = new FileDownload('messages.xlsx', response.data);
      download.downloadFile();
    });
  };


  return (
    <Container>
      <Row>
        <Col>
          <h4>Activation Messages</h4>
          {submitResponse && (
            <Alert variant={'success'}> Messages created successfully </Alert>
          )}
          {submitErrors &&
            submitErrors.map((error, i) => {
              return (
                <Alert variant={'danger'} key={'errorMessage' + i}>
                  {error}
                </Alert>
              );
            })}
          {!loading ? (
            <Formik
              initialValues={{
                document: '',
              }}
              validationSchema={ActivationMessageSchema}
              onSubmit={(values, actions) => {
                /* istanbul ignore next */
                bulkUploadManager(values, actions);
              }}
            >
              {({ isSubmitting, setFieldValue, errors }) => (
                <Form>
                    <div className="form-group">
                        <label>{'Document'}</label>
                        <input
                        className={
                            'form-control' +
                            (errors.document ? ' needs-validation' : '')
                        }
                        name="document"
                        type="file"
                        accept=".xlsx, .csv"
                        onChange={(event) => {
                            /* istanbul ignore next */
                            setFieldValue('document', event.currentTarget.files[0]);
                        }}
                        required
                        />
                        {errors.document && (
                        <ErrorMessage
                            component="small"
                            name="document"
                            className={'text-danger'}
                        />
                        )}
                    </div>
                    <Button id="submit" variant="primary" type="submit" disabled={isSubmitting && !errors} style={{ marginTop: '20px'}}>
                        Upload Messages
                    </Button>
                    <Button variant="primary" style={{ marginTop: '20px', marginLeft: '20px' }}onClick={() => {exportAllHandler();}}> Export All</Button>
                    <Button variant="primary" style={{ marginTop: '20px', marginLeft: '20px' }}onClick={() => {exportAllHandler(true);}}> Download Blank Template</Button>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner
              loading={loading}
              marginTop={'20px'}
              css={{ display: 'inline' }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ActivationMessage;
