import React, { useState } from 'react';
import { host } from '../../../services/axios';
import Button from '../../common/ui/Button';
import CompanyAgreementEdit from './CompanyAgreementEdit';

const CompanyAgreement = ({ company_agreement, loadCompanyAgreements, deleteCompanyAgreement }) => {
    const [showEditCompanyAgreement, setShowEditCompanyAgreement] = useState(false);

    /* istanbul ignore next */
    const showEditModal = () => {
        setShowEditCompanyAgreement(true);
    };

    /* istanbul ignore next */
    const hideEditModal = () => {
        setShowEditCompanyAgreement(false);
    };

    if (company_agreement){
        return (
            <tr>
                <td>{company_agreement.name}</td>
                <td>
                    {company_agreement.document_url === '/images/original/missing.png' ? (
                        ''
                    ) : (
                      <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={host + company_agreement.document_url}
                      >
                          {company_agreement.document_file_name}
                      </a>
                    )}
                </td>
                <td>
                    <Button style={{"margin-right": "20px"}} variant="primary" onClick={(_) => showEditModal()}>
                        Edit
                    </Button>

                    <CompanyAgreementEdit
                        showEditCompanyAgreement={showEditCompanyAgreement}
                        company_agreement={company_agreement}
                        closeEditCompanyAgreementModal={hideEditModal}
                        loadCompanyAgreements={loadCompanyAgreements}
                    />

                    <Button variant="danger" onClick={(_) => deleteCompanyAgreement(company_agreement)}>
                        Delete
                    </Button>
                </td>
            </tr>
        );
    }
    
};

export default CompanyAgreement;