import React from 'react';
import { Table } from 'react-bootstrap';

const SalesTable = ({ top10Sales, company }) => {
  const displaySales = () => {
    if (top10Sales && top10Sales.length > 0) {
      return top10Sales.map((sale) => {
        return (
          <tr>
            <td>{sale.sku}</td>
            <td>{sale.name}</td>
            <td>{sale.quantity}</td>
            <td>{sale.sales}</td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td colSpan={4}>No products yet</td>
      </tr>
    );
  };

  return (
    <Table
      striped
      bordered
      hover
      style={{ marginBottom: 0, textAlign: 'center' }}
    >
      <thead>
        <tr>
          <th colSpan="4" style={{ color: '#1B5DA0' }}>
            Top 10
          </th>
        </tr>
        <tr className="text-muted">
          <th style={{ width: '28%' }}>SKU</th>
          <th style={{ width: '56%' }}>Product Name</th>
          <th style={{ width: '8%' }}>Quantity</th>
          <th style={{ width: '8%' }}>{`Sales (SGD)`}</th>
        </tr>
      </thead>
      <tbody>{displaySales()}</tbody>
    </Table>
  );
};

export default SalesTable;
