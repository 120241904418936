import axios from '../axios';

class UsersService {
  static loadUsers = () => axios.get('/users');

  static createUser = (values) =>
    axios.post('/users', {
      user: {
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        validated: false,
        type: values.type,
      },
    });

  /**
   * Update user in backend using fields in values
   * @param id User ID
   * @param values Values to get updated
   * @returns {*|Observable<AjaxResponse>|Promise<AxiosResponse<any>>}
   */
  static updateUser = (id, values) =>
    axios.patch(`/users/${id}`, {
      user: values,
    });

  static updateUserValidated = (id, value) =>
    this.updateUser(id, { validated: value });
  /**
   * Toogle deny status of a user
   * @param user (object) User instance. It must contains at least id and denied_at.
   */
  static toggleDeny = (user) =>
    this.updateUser(user.id, {
      denied_at: !user.denied_at ? new Date().toISOString() : null,
    });

  static deleteUser = (id) => axios.delete(`/users/${id}`);
}

export default UsersService;
