import React from 'react';
import Table from '../common/ui/Table';
import Link from '../common/ui/Link';

const OrdersOfflineRowDetail = ({
  visible,
  orderLineItems,
  count,
}) => {
  if (visible && count > 0) {
    return (
      <tr>
        <td colSpan="7" className="top-align">
          <Table>
            <thead>
              <tr>
                <td>Fluetis SKU</td>
                <td>Brand</td>
                <td>Name</td>
                <td>Quantity</td>
                <td>Unit Price</td>
              </tr>
            </thead>
            <tbody>
              {orderLineItems.map((orderLineItem) => {
                if (orderLineItem.product_sku){
                  return (
                    <tr key={orderLineItem.id}>
                      <td>
                        <Link to={`/products/${orderLineItem.platform_product_id}`}>{orderLineItem.product_sku}</Link>
                      </td>
                      <td>{orderLineItem.product_brand}</td>
                      <td>{orderLineItem.product_name}</td>
                      <td>
                        {orderLineItem.quantity
                          ? parseInt(orderLineItem.quantity)
                          : 0}
                      </td>
                      <td>{orderLineItem.price}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
        </td>
      </tr>
    );
  }
  if (visible && count === 0) {
    return (
      <tr>
        <td colSpan="6">No items</td>
      </tr>
    );
  }
  return null;
};

export default OrdersOfflineRowDetail;
