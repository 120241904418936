export default class FileDownload {
  constructor(fileName, data) {
    this.fileName = fileName;
    this.data = data;
  }

  downloadFile() {
    const downloadUrl = window.URL.createObjectURL(new Blob([this.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', this.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadFileFromURL(url) {
    fetch(url, 
      { 
        method: 'GET',
        cache: 'default'
      })
      .then(response => response.blob())
      .then(imageBlob => {
        var binaryData = [];
        binaryData.push(imageBlob);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = this.fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => alert(error));
  }
}
