import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../../common/ui/Field';
import EditableInput from '../../../common/ui/EditableInput';

const DimensionsValuesComponent = ({
  title,
  type,
  values,
  setFieldValue,
  readonly,
  handleSubmit,
}) => {
  return (
    <Container fluid>
      <h5>{title}</h5>
      <Row>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name={type + '_length'}
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
              />
            }
            field={type + '_length'}
            label={'Length'}
            text={values[type + '_length']}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name={type + '_width'}
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
              />
            }
            field={type + '_width'}
            label={'Width'}
            text={values[type + '_width']}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name={type + '_height'}
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
              />
            }
            field={type + '_height'}
            label={'Height'}
            text={values[type + '_height']}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
        <Col lg={3}>
          <EditableInput
            inputComponent={
              <Field
                name={type + '_weight'}
                type="text"
                style={{
                  width: '50px',
                  display: 'inline-block',
                }}
              />
            }
            field={type + '_weight'}
            label={'Weight'}
            text={values[type + '_weight']}
            setFieldValue={setFieldValue}
            disabled={readonly}
            handleSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </Container>
  );
};

DimensionsValuesComponent.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default DimensionsValuesComponent;
