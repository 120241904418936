import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AnyProblemHint from './AnyProblemHint';

const SharedLinksComponent = (props) => {
  const displaySignUp = () => {
    if (props.signUp) {
      let styles = {};
      if (props.login) {
        styles = { textAlign: 'right' };
      }
      return (
        <Col lg="6" style={styles}>
          <Link to="/join_as_a_seller">Sign up</Link>
          <br />
        </Col>
      );
    }
  };

  const displayLogin = () => {
    if (props.login) {
      return (
        <Col lg="6">
          <Link to="/users/sign_in">Login</Link>
          <br />
        </Col>
      );
    }
  };

  const displayForgotPassword = () => {
    if (props.forgotPassword && !props.anyProblem) {
      return (
        <Col lg="6">
          <Link to="/users/password/new">Forgot your password?</Link>
          <br />
        </Col>
      );
    }
  };

  const displayConfirmation = () => {
    if (props.confirmation && !props.anyProblem) {
      return (
        <Col lg="6">
          <Link to="/users/confirmation/new">
            {'Resend confirmation instructions?'}{' '}
          </Link>
          <br />
        </Col>
      );
    }
  };

  const displayAnyProblem = () => {
    if (props.anyProblem) {
      return (
        <Col lg="6">
          <AnyProblemHint />
        </Col>
      );
    }
  };

  return (
    <Container style={{ marginTop: '15px' }}>
      <Row>
        {displayLogin()}
        {displaySignUp()}
        {displayForgotPassword()}
        {displayConfirmation()}
        {displayAnyProblem()}
      </Row>
    </Container>
  );
};

export default SharedLinksComponent;

SharedLinksComponent.propTypes = {
  signUp: PropTypes.any,
  login: PropTypes.any,
  forgotPassword: PropTypes.any,
  confirmation: PropTypes.any,
};
