import React from 'react';
import Tabs from '../common/ui/Tabs';
import { Tab } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Can from '../roles/Can';
import InnerLayout from '../layout/InnerLayout';
import BulkOrdersUpload from './bulk_orders_upload/BulkOrdersUpload';
import PurchaseOrders from '../inventory_and_shipments/purchase_orders/PurchaseOrders';
import ActivationMessage from './activation_messages/ActivationMessage';
import Inventory from './inventory/Inventory';
import ProductCategoryManagement from './product_category_management/ProductCategoryManagement';
import SalesOrderManagement from './sales_order_management/SalesOrderManagement';
import ManageUsersComponent from '../manage_users/ManageUsers';
import Agreements from './agreement/Agreements';
import Labels from './label/Labels';
import CompanyAgreements from './company_agreement/CompanyAgreements';
import Invoices from './invoices/Invoices';
import CustomerCode from './customer_codes/CustomerCode';

/**
 * Component for tools only for admin users
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const AdminTools = (props) => {
  const { user, company, logOut, location } = props;

  if (user) {
    return (
      <Can
        role={user.type.toLowerCase()}
        perform="AdminTools"
        yes={() => (
          <InnerLayout
            logOut={logOut}
            user={user}
            location={location}
            company={company}
          >
            <Tabs
              defaultTab="bulk_orders"
              id="tabs_admin_tools"
              location={location}
            >
              <Tab eventKey="bulk_orders" title="Bulk Orders">
                <BulkOrdersUpload
                  user={user}
                  company={company}
                />
              </Tab>
              <Tab eventKey="inventory" title="Inventory">
                <Inventory user={user} company={company} />
              </Tab>
              <Tab eventKey="purchase_orders" title="Purchase Orders">
                <PurchaseOrders user={user} localProducts={[]} adminView={true} company={company} />
              </Tab>
              <Tab
                eventKey="product_category_management"
                title="Product category management"
              >
                <ProductCategoryManagement />
              </Tab>
              <Tab eventKey="sales_order" title="Sales Order management">
                <SalesOrderManagement />
              </Tab>
              <Tab eventKey="manage_users" title="Manage Users">
                <ManageUsersComponent
                  user={user}
                  logOut={logOut}
                  location={location}
                  company={company}
                />
              </Tab>
              <Tab eventKey="agreement" title="Agreement">
                <Agreements company={company} />
              </Tab>
              <Tab eventKey="company_agreements" title="Company Agreements">
                <CompanyAgreements/>
              </Tab>
              <Tab eventKey="label" title="Labels">
                <Labels/>
              </Tab>
              <Tab eventKey="activation_message" title="Messages">
                <ActivationMessage/>
              </Tab>
              <Tab eventKey="invoice" title="Invoices">
                <Invoices/>
              </Tab>
              <Tab eventKey="customer_code" title="Customer Codes">
                <CustomerCode />
              </Tab>
            </Tabs>
          </InnerLayout>
        )}
        no={() => <Redirect to="/no_permissions" />}
      />
    );
  }
  return null;
};

export default AdminTools;
