import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import PaginateComponent from '../common/ui/PaginateComponent';
import DateSelector from '../common/ui/DateSelector';
import { sliceCollectionInChunks } from '../common/dataTypes/array';
import { convertToInputFormat } from '../common/dataTypes/dates';
import OrdersOfflineTable from './OrdersOfflineTable';
import OrdersSearch from './OrdersSearch';
import AdjustPaginateButtons from '../common/ui/AdjustPaginateButtons';
import Spinner from '../common/ui/Spinner';
import Button from '../common/ui/Button';
import OrdersService from '../../services/data/orders_service';
import FileDownload from '../common/dataTypes/fileDownload';
import { createNotification } from '../common/notifications';

const OrdersOfflineComponent = ({
  orders,
  ordersLoading,
  setOrdersLoading,
  adminView,
  company,
  companies,
  user,
  template,
}) => {
  const [localOrders, setLocalOrders] = useState([]);
  const [allLocalOrders, setAllLocalOrders] = useState([]);
  const [reloadCleanData, ] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  let today = new Date();
  let aux = new Date();
  today.setDate(aux.getDate() + 1);
  aux.setDate(aux.getDate() - 7);
  const [selectedDate, setSelectedDate] = useState({
    startDate: convertToInputFormat(aux),
    endDate: convertToInputFormat(today),
  });

  const [dateScope, setDateScope] = useState("week");

  /* istanbul ignore next */
  const setSearchField = (term) => {
    setSearchTerm(term);
  }

  const calculateTotals = (selectedLineItems) => {
    setTotalQuantity(getCount(selectedLineItems));
    setTotalSales(getAmount(selectedLineItems));
  };

  const loadInitialOrders = () => {
    let value;
    let today = new Date();
    let aux = new Date();
    today.setDate(today.getDate() + 1);
    aux.setDate(aux.getDate() - 7);
    value = {
      startDate: convertToInputFormat(aux),
      endDate: convertToInputFormat(today),
    };
    const filteredOrders = orders.filter(
        (lo) => lo.issued_at <= value.endDate && lo.issued_at >= value.startDate
      );
    return filteredOrders;
  };

  const loadAllOrders = () => {
    const filteredOrders = orders;
    return filteredOrders;
  };

  const loadOrdersData = (startDate, endDate) => {
    const ordersFiltered = loadAllOrders().filter(
      (lo) => lo.issued_at <= endDate && lo.issued_at >= startDate
    );

    const ordersLoad = [];
    // eslint-disable-next-line
    ordersFiltered.map((order) => {
      let items = [];
      items.push(...order.order_line_items);
      if (getCount(items) > 0) {
        ordersLoad.push(order);
      }
    });
    return ordersLoad;
  };

  const setOrdersDataByDate = (startDate, endDate) => {
    const ordersLoad = loadOrdersData(startDate, endDate);
    setLocalOrders(ordersLoad);
  };

  const getCount = (orderLineItems) => {
    let count = 0;
    if (orderLineItems) {
      orderLineItems.map((orderLineItem) => {
        if (orderLineItem.quantity) {
          count += parseInt(orderLineItem.quantity);
        }
        return null;
      });
    }

    return count;
  };

  const getAmount = (orderLineItems) => {
    let amount = 0;
    if (orderLineItems) {
      orderLineItems.map((orderLineItem) => {
        if (orderLineItem.product_name) {
          amount +=
            parseInt(orderLineItem.quantity) * parseFloat(orderLineItem.price);
        }
        return null;
      });
    }

    return amount;
  };

  const exportAllHandler = (dateScope, adminView, template = false) => {
    createNotification('success', 'Your orders are being exported. The file will be downloaded in a while');
    OrdersService.getOrdersXLSX(dateScope, adminView, template).then((response) => {
      const download = new FileDownload('orders.xlsx', response.data);
      download.downloadFile();
    })
  };

  useEffect(() => {
    if (orders?.length > 0) {
      setOrdersLoading(true);
      setLocalOrders(loadInitialOrders());
      setOrdersLoading(false);
    }
  }, [orders, reloadCleanData]);

  useEffect(() => {
    let selectedLi = [];
    localOrders.map((lo) => selectedLi.push(...lo.order_line_items));
    calculateTotals(selectedLi);
    // eslint-disable-next-line
  }, [localOrders]);

  useEffect(() => {
    if(dateScope != ""){
      setOrdersLoading(true);
      OrdersService.loadOrders(dateScope, adminView)
      .then((response) => {
        setLocalOrders(response.data.orders);
        setAllLocalOrders(response.data.orders);
        setOrdersLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [dateScope]);

  if (ordersLoading) {
    return (
      <Row>
        <Col>
          <Spinner loading={loading} />
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row>
        <Col lg="2">
          <DateSelector
            loadData={setOrdersDataByDate}
            setSelectedDate={setSelectedDate}
            setDateScope={setDateScope}
            dateScope={dateScope}
          />
        </Col>
        <Col>
          <OrdersSearch
            collection={localOrders}
            allCollection={allLocalOrders}
            loadInitialOrders={loadOrdersData(
              selectedDate.startDate,
              selectedDate.endDate
            )}
            setCollection={setLocalOrders}
            companies={companies}
            setSearchField={setSearchField}
            searchField={searchTerm}
            user={user}
          />
        </Col>
      </Row>
      <Row style={{ margin: '10px 20px', padding: '0px' }}>
        <Col></Col>
        <Col>
          {
            template &&
            (
              <Button variant="primary" style={{ marginLeft: '35%' }}
                  onClick={() => {
                    exportAllHandler(dateScope, adminView, true);
                  }}
              >
                Download Blank Template
              </Button>
            )
          }
          <Button variant="primary" style={{ float: 'right' }}
              onClick={() => {
                exportAllHandler(dateScope, adminView);
              }}
          >
            Export All
          </Button>
        </Col>
      </Row>
      <PaginateComponent
        componentList={
          <OrdersOfflineTable
            collection={localOrders}
            companies={companies}
            user={user}
            getCount={getCount}
            getAmount={getAmount}
            totalQuantity={totalQuantity}
            totalSales={totalSales}
          />
        }
        list={localOrders}
        perPage={perPage}
        containerClassName="pagination-reduced"
      />
      <AdjustPaginateButtons
        list={localOrders}
        perPageList={[10, 50]}
        setPerPage={setPerPage}
      />
    </>
  );
};

OrdersOfflineComponent.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OrdersOfflineComponent;
