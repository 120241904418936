import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LabelsService from '../../../services/data/labels_service';
import LabelEdit from './LabelEdit';

const Labels = () => {
  const [labels, setLabels] = useState([]);

  const loadLabels = () => {
    LabelsService.list().then((response) => {
      const labels = response.data.labels;
      setLabels(labels);
    });
  };

  useEffect(() => {
    loadLabels();
  }, []);

  return (
    <>
      <Container fluid>
        <h4>Labels</h4>
        <Row>
          {labels.map((label) => (
          <Col lg="12" style={{ marginTop: '40px' }}>
            <LabelEdit label={label}></LabelEdit>
          </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Labels;
