import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import Form from './UserForm';

const UserNewSchema = Yup.object().shape({
  email: Yup.string().email().required('Contact Email is required'),
  password: Yup.string().min(6).required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(6)
    .required('Password Confirmation is required')
    .test('email-match', 'Password must match', function (value) {
      /* istanbul ignore next */
      return this.parent.password === value;
    }),
  type: Yup.mixed().oneOf(['Seller', 'Admin']),
});

const UserNewComponent = (props) => {
  const { showUserNew, closeShowUserNew, handleSubmit } = props;

  /* istanbul ignore next */
  const handleCreate = (values, actions) => {
    handleSubmit(values);
    closeShowUserNew();
  };

  return (
    <Modal show={showUserNew} onHide={(_) => closeShowUserNew()}>
      <Modal.Header closeButton>New User</Modal.Header>
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordConfirmation: '',
          type: 'Seller',
        }}
        validationSchema={UserNewSchema}
        onSubmit={(values, actions) => {
          /* istanbul ignore next */
          handleCreate(values, actions);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form id="userNew" onClose={() => closeShowUserNew()} />
        )}
      </Formik>
    </Modal>
  );
};

export default UserNewComponent;
