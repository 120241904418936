import axios from './axios';

class LoginService {
  static login(data) {
    axios.defaults.headers.Authorization = '';
    return axios.post('/login', {
      user: {
        email: data.email,
        password: data.password
      },
    });
  }

  static getUser() {
    return axios.get('/current_users');
  }

  static signup(data) {
    axios.defaults.headers.Authorization = '';
    return axios.post('/signup', {
      user: {
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
      },
    });
  }

  static forgotPassword(data) {
    axios.defaults.headers.Authorization = '';
    return axios.post('/password', {
      user: {
        email: data.email,
      },
    });
  }

  static emailConfirmation(data) {
    axios.defaults.headers.Authorization = '';
    return axios.post('/confirmation', {
      user: {
        email: data.email,
      },
    });
  }

  static changePassword(data) {
    return axios.put(`/current_users`, {
      current_user: {
        email: data.user.email,
        password: data.user.password,
        password_confirmation: data.user.password_confirmation,
        current_password: data.user.current_password,
      },
    });
  }

  static confirmUser(url) {
    return axios.get(url);
  }

  static passwordReset(data) {
    return axios.put('/password', {
      user: {
        reset_password_token: data.reset_password_token,
        password: data.password,
        password_confirmation: data.password_confirmation
      }
    });
  }
}

export default LoginService;
