import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Select from './Select';
import { convertToInputFormat } from '../dataTypes/dates';

const DateSelctionRangeSchema = Yup.object().shape({
  range: Yup.string(),
});

const DateSelector = ({ loadData, dateScope, setDateScope }) => {
  const selectDataByChoice = (choice) => {
    let value;
    const today = new Date();
    let aux = new Date();
    switch (choice) {
      case 'lastWeek':
        setDateScope("week");
        aux.setDate(aux.getDate() - 7);
        value = {
          startDate: convertToInputFormat(aux),
          endDate: convertToInputFormat(today),
          scope: "week",
        };
        break;
      case 'lastYear':
        setDateScope("year");
        aux.setDate(aux.getDate() - 365);
        value = {
          startDate: convertToInputFormat(aux),
          endDate: convertToInputFormat(today),
          scope: "year",
        };
        break;
      case 'lastMonth':
        setDateScope("month");
        aux.setDate(aux.getDate() - 30);
        value = {
          startDate: convertToInputFormat(aux),
          endDate: convertToInputFormat(today),
          scope: "month",
        };
        break;
      default:
        value = { startDate: null, endDate: null, scope: "week", };
        break;
    }
    return value;
  };

  const valueFromScope = (scope) => {
    let value;
    switch (scope) {
      case 'week':
        value = {value: "lastWeek", label: 'Last 7 days'};
        break;
      case 'year':
        value = {value: "lastYear", label: 'Last 365 days'};
        break;
      case 'month':
        value = {value: "lastMonth", label: 'Last 30 days'};
        break;
      default:
        value = {value: "lastWeek", label: 'Last 7 days'};
        break;
    }
    return value;
  };

  return (
    <Formik
      initialValues={{
        range: '',
      }}
      validationSchema={DateSelctionRangeSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        const dates = selectDataByChoice(values.range.value);
        loadData(dates.startDate, dates.endDate, dates.scope);
      }}
    >
      {({ isSubmitting, setFieldValue, values, submitForm }) => {
        return (
          <Form>
            <Container fluid>
              <Row>
                <Col>
                  <div className="form-group row">
                    <Select
                      name="range"
                      options={[
                        { value: 'lastWeek', label: 'Last 7 days' },
                        { value: 'lastMonth', label: 'Last 30 days' },
                        { value: 'lastYear', label: 'Last 365 days' },
                      ]}
                      defaultValue={{ value: 'lastWeek', label: 'Last 7 days' }}
                      value={ valueFromScope(dateScope) }
                      className="col-sm-12 no-padding"
                      onChange={(value) => {
                        setFieldValue('range', value);
                        submitForm();
                      }}
                      id="filterByDateDashboard"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DateSelector;
