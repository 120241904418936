import React, { useEffect, useState } from 'react';
import LabelsService from '../../../services/data/labels_service';
import ReactHtmlParser from 'react-html-parser'; 

const LabelComponent = (props) => {
    const { labelKey } = props
    const [content, setContent] = useState("");

    const loadLabel = () => {
        LabelsService.getLabel(labelKey).then((response) => {
            const label = response.data.label;
            let aux = label ? label.body : ""; 
            setContent(aux);
        });
    };

    useEffect(() => {
        loadLabel();
    }, []);


    return (<>{ ReactHtmlParser (content) }</>);
};

export default LabelComponent;
