import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CertificationsService from '../../../services/data/certifications_service';
import { host } from '../../../services/axios';
import CertificationEdit from './certifications/CertificationEdit';
import { createNotification } from '../../common/notifications';
import { formattedDate } from '../../common/dataTypes/dates';
import Button from '../../common/ui/Button';

const CertificationComponent = ({ certification, loadCertifications, product }) => {
  const [showEditCertification, setShowEditCertification] = useState(false);

  /* istanbul ignore next */
  const showEditModal = () => {
    setShowEditCertification(true);
  };

  /* istanbul ignore next */
  const hideEditModal = () => {
    setShowEditCertification(false);
  };

  /* istanbul ignore next */
  const handleDelete = () => {
    CertificationsService.deteleCertification(certification.id)
      .then((response) => {
        createNotification('success', 'has been removed', 'The certificate');
        loadCertifications();
      })
      .catch((error) => {
        createNotification(
          'error',
          'was not possible to be deleted',
          'The certification'
        );
      });
  };

  return (
    <>
      <tr>
        <td>{certification.name}</td>
        <td>{formattedDate(certification.start_date)}</td>
        <td>{formattedDate(certification.end_date)}</td>
        <td>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={host + certification.document_url}
          >
            {certification.document_file_name}
          </a>
        </td>
        <td>
          <Button variant="primary" onClick={(_) => showEditModal()}>
            Edit
          </Button>
          <Button variant="danger" onClick={(_) => handleDelete()} style={{marginLeft: '5px'}}>
            Delete
          </Button>
        </td>
      </tr>
      <CertificationEdit
        showEditCertification={showEditCertification}
        certification={certification}
        closeEditCertificationModal={hideEditModal}
        productId={product.id}
        loadCertifications={loadCertifications}
      />
    </>
  );
};

CertificationComponent.propTypes = {
  certification: PropTypes.object,
  product: PropTypes.object,
};

export default CertificationComponent;
