import React from 'react';
import Table from '../../common/ui/Table';
import CompanyAgreement from './CompanyAgreement';

const CompanyAgreementTable = ({ collection, loadCompanyAgreements, deleteCompanyAgreement }) => {
    
    const displayAgreement = () => {
        if (collection){
            return(collection.map((company_agreement) => (
                <CompanyAgreement company_agreement={company_agreement} loadCompanyAgreements={loadCompanyAgreements} deleteCompanyAgreement={deleteCompanyAgreement} />
              )))
        };
    };

    return(
        <>
            <Table>
                <thead>
                    <tr>
                        <th style={{width: '400px'}}>Company</th>
                        <th>Document</th>
                        <th colSpan={2}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {displayAgreement()}
                </tbody>
            </Table>
        </>
    );
};

export default CompanyAgreementTable;