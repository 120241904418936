import React from 'react';
import Table from '../../common/ui/Table';
import Invoice from './InvoiceRow.jsx'

const InvoicesTable = (props) => {
    const { collection, showEditInvoiceModal, handleDelete } = props;

    return (
        <Table id="invoiceTable" style={{ textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>Order Number</th>
                    <th style={{ width: '10%' }}>Reference</th>
                    <th>Company Name</th>
                    <th>Invoiced at</th>
                    <th>Due at</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Invoice</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {collection && collection.map((invoice) => (
                    <Invoice invoice={invoice} showEditInvoiceModal={showEditInvoiceModal} handleDelete={handleDelete} />
                ))}
            </tbody>
        </Table>
    )


};

export default InvoicesTable;