import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createNotification } from '../../common/notifications';
import DimensionService from '../../../services/data/dimensions_service';
import Form from './dimensions/DimensionsForm';

const DimensionSchema = Yup.object().shape({
  ium_length: Yup.number(),
  ium_width: Yup.number(),
  ium_height: Yup.number(),
  ium_weight: Yup.number(),
  pium_length: Yup.number(),
  pium_width: Yup.number(),
  pium_height: Yup.number(),
  pium_weight: Yup.number(),
  ium_per_pium: Yup.number(),
  pium_per_layer: Yup.number(),
  layer_per_pallet: Yup.number(),
  pallet_vollume: Yup.number(),
  pallet_weight: Yup.number(),
  pack_length: Yup.number(),
  pack_width: Yup.number(),
  pack_height: Yup.number(),
});

const DimensionsComponent = ({ productId, dimension, readonly }) => {
  const [ium_length, set_ium_length] = useState(0);
  const [ium_width, set_ium_width] = useState(0);
  const [ium_height, set_ium_height] = useState(0);
  const [ium_weight, set_ium_weight] = useState(0);
  const [pium_length, set_pium_length] = useState(0);
  const [pium_width, set_pium_width] = useState(0);
  const [pium_height, set_pium_height] = useState(0);
  const [pium_weight, set_pium_weight] = useState(0);
  const [ium_per_pium, set_ium_per_pium] = useState(0);
  const [pium_per_layer, set_pium_per_layer] = useState(0);
  const [layer_per_pallet, set_layer_per_pallet] = useState(0);
  const [pallet_volume, set_pallet_volume] = useState(0);
  const [pallet_weight, set_pallet_weight] = useState(0);
  const [pack_length, set_pack_length] = useState(0);
  const [pack_width, set_pack_width] = useState(0);
  const [pack_height, set_pack_height] = useState(0);
  const [pack_weight, set_pack_weight] = useState(0);
  const [carton_quantity, set_carton_quantity] = useState(0);

  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    if (productId === undefined) {
      createNotification(
        'error',
        'was not possible to be created or updated without a product',
        'Dimensions'
      );
      return;
    }
    DimensionService.createDimension(values, productId)
      .then((response) => {
        if (response.status === 200) {
          set_ium_length(response.data.ium_length);
          set_ium_width(response.data.ium_width);
          set_ium_height(response.data.ium_height);
          set_ium_weight(response.data.ium_weight);
          set_pium_length(response.data.pium_length);
          set_pium_width(response.data.pium_width);
          set_pium_height(response.data.pium_height);
          set_pium_weight(response.data.pium_weight);
          set_ium_per_pium(response.data.ium_per_pium);
          set_pium_per_layer(response.data.pium_per_layer);
          set_layer_per_pallet(response.data.layer_per_pallet);
          set_pallet_volume(response.data.pallet_volume);
          set_pallet_weight(response.data.pallet_weight);
          set_pack_length(response.data.pack_length);
          set_pack_width(response.data.pack_width);
          set_pack_height(response.data.pack_height);
          set_pack_weight(response.data.pack_weight);
          set_carton_quantity(response.data.carton_quantity);
          createNotification('success', 'has been updated', 'Dimensions');
          actions.setSubmitting(false);
        }
      })
      .catch((error) => {
        createNotification('error', 'was not possible to update', 'Dimensions');
        console.log(error);
      });
  };

  useEffect(() => {
    if (dimension) {
      set_ium_length(dimension.ium_length ? dimension.ium_length : 0);
      set_ium_width(dimension.ium_width ? dimension.ium_width : 0);
      set_ium_height(dimension.ium_height ? dimension.ium_height : 0);
      set_ium_weight(dimension.ium_weight ? dimension.ium_weight : 0);
      set_pium_length(dimension.pium_length ? dimension.pium_length : 0);
      set_pium_width(dimension.pium_width ? dimension.pium_width : 0);
      set_pium_height(dimension.pium_height ? dimension.pium_height : 0);
      set_pium_weight(dimension.pium_weight ? dimension.pium_weight : 0);
      set_ium_per_pium(dimension.ium_per_pium ? dimension.ium_per_pium : 0);
      set_pium_per_layer(dimension.pium_per_layer ? dimension.pium_per_layer : 0);
      set_layer_per_pallet(dimension.layer_per_pallet ? dimension.layer_per_pallet : 0);
      set_pallet_volume(dimension.pallet_volume ? dimension.pallet_volume : 0);
      set_pallet_weight(dimension.pallet_weight ? dimension.pallet_weight : 0);
      set_pack_length(dimension.pack_length ? dimension.pack_length : 0);
      set_pack_width(dimension.pack_width ? dimension.pack_width : 0);
      set_pack_height(dimension.pack_height ? dimension.pack_height : 0);
      set_pack_weight(dimension.pack_weight ? dimension.pack_weight : 0);
      set_carton_quantity(dimension.carton_quantity ? dimension.carton_quantity : 0);
    }
  }, [dimension]);

  return (
    <Container fluid>
      <Formik
        initialValues={{
          ium_length: ium_length,
          ium_width: ium_width,
          ium_height: ium_height,
          ium_weight: ium_weight,
          pium_length: pium_length,
          pium_width: pium_width,
          pium_height: pium_height,
          pium_weight: pium_weight,
          ium_per_pium: ium_per_pium,
          pium_per_layer: pium_per_layer,
          layer_per_pallet: layer_per_pallet,
          pallet_volume: pallet_volume,
          pallet_weight: pallet_weight,
          pack_length: pack_length,
          pack_width: pack_width,
          pack_height: pack_height,
          pack_weight: pack_weight,
          carton_quantity: carton_quantity
        }}
        enableReinitialize={true}
        validationSchema={DimensionSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => (
          <Form
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
            readonly={readonly}
            handleSubmit={submitForm}
          />
        )}
      </Formik>
    </Container>
  );
};

export default DimensionsComponent;
