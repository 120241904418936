import { useState } from 'react';

const useOrders = () => {
  const lsSidebar = localStorage.getItem('reducedSidebar');
  const [reduced, setReduced] = useState(lsSidebar === 'false' ? false : true);

  return { reduced, setReduced };
};

export default useOrders;
