class StorageService {
  static storeBearer(bearer) {
    localStorage.setItem('Bearer', bearer);
  }

  static storeUserData(userData) {
    localStorage.setItem('UserId', userData.id);
    localStorage.setItem('UserCompany', userData.company_id);
    localStorage.setItem('UserType', userData.type);
    localStorage.setItem('UserValidated', userData.validated);
  }

  static getBearer() {
    return localStorage.getItem('Bearer');
  }

  static getUserData() {
    return {
      id: localStorage.getItem('UserId'),
      type: localStorage.getItem('UserType'),
      validated: localStorage.getItem('UserValidated'),
      company_id: localStorage.getItem('UserCompany'),
    };
  }

  static clearBearer() {
    localStorage.removeItem('Bearer');
  }

  static clearUserData() {
    localStorage.removeItem('UserId');
    localStorage.removeItem('UserType');
    localStorage.removeItem('UserValidated');
    localStorage.removeItem('UserCompany');
  }
}

export default StorageService;
