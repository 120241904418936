import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { capitalize } from '../../common/dataTypes/string';
import Table from '../../common/ui/Table';
import Button from '../../common/ui/Button';

const MyAddressTable = (props) => {
  const { collection, showEditLocationModal, handleDelete } = props;

  /* istanbul ignore next */
  const displayAddress = (address) => {
    if (address) {
      const addr1 = address.address1 ? address.address1 : '';
      const addr2 = address.address2 ? address.address2 : '';
      const zipCode = address.zip_code ? address.zip_code : '';
      const city = address.city ? address.city : '';
      const country = address.country ? address.country : '';
      return `${addr1} ${addr2} ${zipCode} ${city} ${country}`;
    }
    return '';
  };

  return (
    <Table id="addressTable" style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>City</th>
          <th>Type</th>
          <th>Name</th>
          <th>Country</th>
          <th>State</th>
          <th>Address</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {collection &&
          collection.map((address) => {
            return (
              <tr key={address.id}>
                <td>{address.city}</td>
                <td>
                  {address.address_type &&
                    capitalize(address.address_type.replace(/[_-]/g, ' '))}
                </td>
                <td>{address.label}</td>
                <td>{address.country}</td>
                <td>{address.state}</td>
                <td>{displayAddress(address)}</td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => {
                      /* istanbul ignore next */
                      showEditLocationModal(address.id);
                    }}
                  >
                    <FontAwesomeIcon
                      className="editAction"
                      icon={faPencilAlt}
                    />
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      /* istanbul ignore next */
                      handleDelete(address.id);
                    }}
                  >
                    <FontAwesomeIcon
                      className="deleteAction"
                      icon={faTrashAlt}
                    />
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default MyAddressTable;
