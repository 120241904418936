import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LocationsService from '../../../services/data/locations_service';
import AddressesService from '../../../services/data/address_service';
import PurchaseOrdersService from '../../../services/data/purchase_orders_service';
import ProductsService from '../../../services/data/products_service';
import InnerLayout from '../../layout/InnerLayout';
import { createNotification, delayedRedirect} from '../../common/notifications';
import { formattedPrice } from '../../common/dataTypes/prices';
import Spinner from '../../common/ui/Spinner';
import { convertToInputFormat } from '../../common/dataTypes/dates';
import Form from './PurchaseOrdersEditForm';

const PurchaseOrdersEditSchema = Yup.object().shape({
  pickingLocation: Yup.object(),
  deliveryLocation: Yup.object(),
  orderDate: Yup.date(),
  pickupDate: Yup.date(),
  deliveryDate: Yup.date(),
  cost: Yup.string(),
});

const PurchaseOrdersEditComponent = ({
  user,
  location,
  company,
  match,
  logOut,
  products,
  loadProducts,
}) => {
  const [purchaseOrder, setPurchaseOrder] = useState(undefined);
  const [addresses, setAddresses] = useState([]);
  const [item_data, set_item_data] = useState([]);
  const [waitingForShipmentCreation, setWaitingForShipmentCreation] = useState(
    false
  );
  const [loading, setLoading] = useState(true);

  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    setWaitingForShipmentCreation(true);
    PurchaseOrdersService.updatePurchaseOrder(match.params.id, values)
      .then((response) => {
        setWaitingForShipmentCreation(false);
        createNotification('success', 'Your purchase order has been updated');
        delayedRedirect('/inventory_and_shipments#purchase_orders');
        actions.setSubmitting(false);
      })
      .catch((error) => {
        setWaitingForShipmentCreation(false);
        createNotification('error', 'Your purchase order was not updated');
        delayedRedirect('/inventory_and_shipments#purchase_orders');
        actions.setSubmitting(false);
      });
  };

  /* istanbul ignore next */
  const getFromId = (collection, id) => {
    return collection.filter((elem) => elem.id == id)[0];
  };

  const loadData = () => {
    if (products && products.length === 0) {
        ProductsService.loadProducts().then((response) => {
          loadProducts(response.data.products);
        });
    }
  
    AddressesService.loadAddresses().then((response) => {
        setAddresses(response.data.addresses);
    });
    return true;
  };

  const setDataFromRequest = (data) => {
    if (data){
        setPurchaseOrder(data);
        set_item_data(data.order_line_items);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    loadData();

    PurchaseOrdersService.loadPurchaseOrder(match.params.id)
      .then((response) => {
        setDataFromRequest(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
    }, [match.params.id, loadProducts]);

  /* istanbul ignore next */
  const getCost = (itemList) => {
    let cost = 0;
    if (itemList && itemList.length > 0) {
      const aux = itemList.map(
        (elem) =>
          (elem.price ? elem.price : 0) * (elem.quantity ? elem.quantity : 0)
      );
      cost = aux.reduce((a, b) => a + b, 0);
      return cost;
    }
    return cost;
  };

  if (loading) {
    return (
      <InnerLayout
        logOut={logOut}
        user={user}
        location={location}
        company={company}
        Z
      >
        <Spinner loading={loading} css={{ display: 'inline' }} />
      </InnerLayout>
    );
  }

  return (
    <InnerLayout
      logOut={logOut}
      user={user}
      location={location}
      company={company}
      Z
    >
      <Formik
        initialValues={{
            pickingLocation: addresses && addresses.length > 0 && getFromId( addresses, purchaseOrder.billing_address_id) && 
            {
                value: getFromId(addresses, purchaseOrder.billing_address_id).id,
                label: getFromId(addresses, purchaseOrder.billing_address_id).label,
            },
            deliveryLocation: { value: 228478, label: "Fluetis" },
            orderDate: purchaseOrder && convertToInputFormat(new Date(purchaseOrder.issued_at)),
            pickupDate: purchaseOrder && convertToInputFormat(new Date(purchaseOrder.issued_at)),
            deliveryDate: purchaseOrder && convertToInputFormat(new Date(purchaseOrder.due_at)),
            actualPickupDate: '',
            actualDeliveryDate: '',
            item_data: item_data,
            cost: formattedPrice( getCost(item_data),company?.preferred_currency),
        }}
        enableReinitialize={true}
        validationSchema={PurchaseOrdersEditSchema}
        onSubmit={(values, actions) => {
          /* istanbul ignore next */
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            addresses={addresses}
            products={products}
            item_data={item_data}
            readonly={location.hash === '#show' ? true : false}
            waitingForShipmentCreation={waitingForShipmentCreation}
            getCost={getCost}
            values={values}
            company={company}
            orderId={match.params.id}
          />
        )}
      </Formik>
    </InnerLayout>
  );
};

export default PurchaseOrdersEditComponent;
