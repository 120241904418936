import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../../common/ui/Button';

const StyledForm = styled(Form)`
  text-align: center;
`;

const StyledContainer = styled(Container)`
  display: inline-block;
  position: relative;
  height: 80vh;
  padding: 0px 18px;
  border-radius: 5px;
  overflow: hidden;
`;

const MyCompanyForm = (props) => {
  const { isSubmitting } = props;

  return (
    <StyledForm id="companyForm">
      <StyledContainer fluid>
        <Row>
          <Col md="12" lg="12">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Company Name
              </label>
              <Field
                className="form-control"
                name="name"
                id="company_name"
                label="Company Name"
                placeholder="Mandatory"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="name"
              />
            </div>
          </Col>
          <Col md="12" lg="12">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Website Url
              </label>
              <Field
                className="form-control"
                name="url"
                id="url"
                label="Website Url"
                placeholder="Optional"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="url"
              />
            </div>
          </Col>
          <Col md="12" lg="12">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Company Registration Number
              </label>
              <Field
                className="form-control"
                name="registrationNumber"
                id="registrationNumber"
                label="Company Registration Number"
                placeholder="Mandatory"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="registrationNumber"
              />
            </div>
          </Col>
          <Col md="12" lg="12">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                VAT Identification Number
              </label>
              <Field
                className="form-control"
                name="taxNumber"
                id="taxNumber"
                label="VAT Identification Number"
                placeholder="Mandatory"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="taxNumber"
              />
            </div>
          </Col>
          <Col md="12" lg="12">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Incorporation Date
              </label>
              <Field
                className="form-control"
                name="incorporationDate"
                id="incorporationDate"
                label="Incorporation Date"
                type="date"
                placeholder="Incorporation Date"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="incorporationDate"
              />
            </div>
          </Col>
          <Col md="12" lg="12">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Key Business Activity
              </label>
              <Field
                className="form-control"
                name="keyBusinessActivity"
                id="keyBusinessActivity"
                label="Key Business Activity"
                placeholder="Mandatory"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="keyBusinessActivity"
              />
            </div>
          </Col>
        </Row>

        <div className="form-group row">
          <label style={{ display: 'block', width: '100%', textAlign: 'left' }}>
            Story
          </label>
          <Field
            className="form-control"
            name="description"
            id="company_description"
            label="Story"
            placeholder="Optional"
            as="textarea"
            style={{ maxWidth: '500px' }}
            noCol
          />
          <ErrorMessage
            className="form-field-error col-sm-12"
            style={{ margin: '5px auto' }}
            component="small"
            name="description"
          />
        </div>
        <div className="actions row">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </div>
      </StyledContainer>
    </StyledForm>
  );
};

export default MyCompanyForm;
