import React, { useState, useEffect } from 'react';
import { Form, ErrorMessage } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from 'react-spinners/PulseLoader';
import PurchaseOrderLineItemsService from '../../../services/data/purchase_order_line_items_service';
import PurchaseOrdersService from '../../../services/data/purchase_orders_service';
import PurchaseOrderItemListTable from '../purchase_orders/PurchaseOrderItemListTable';
import PurchaseOrderItemListForm from '../purchase_orders/PurchaseOrderItemListForm';
import FileDownload from '../../common/dataTypes/fileDownload';
import { createNotification, delayedRedirect } from '../../common/notifications';
import { addWeeks, convertToInputFormat } from '../../common/dataTypes/dates';
import Button from '../../common/ui/Button';
import Field from '../../common/ui/Field';
import Select from '../../common/ui/Select';
import BulkUpload from '../../common/ui/BulkUpload';

const PurchaseOrdersEditFormComponent = ({
  products,
  addresses,
  item_data,
  setFieldValue,
  waitingForShipmentCreation,
  isSubmitting,
  readonly,
  getCost,
  values,
  company,
  orderId
}) => {
  const [visibleCreateNewProduct, setVisibleCreateNewProduct] = useState(false);
  const [itemList, setItemList] = useState([]);

  /* istanbul ignore next */
  const toggleVisibilityCreateNewProduct = () => {
    if (products && products.length > 0) {
      setVisibleCreateNewProduct((oldState) => !oldState);
    } else {
      createNotification('warning', "you don't have products created ");
    }
  };

  /* istanbul ignore next */
  const deleteFromItemList = (index) => {
    let aux = [...itemList];
    aux.splice(index, 1);
    updateFormikField(aux);
  };

  /* istanbul ignore next */
  const updateFormikField = (aux) => {
    setItemList(aux);
    setFieldValue('item_data', aux);
    setFieldValue('cost', getCost(aux));
  };

  /* istanbul ignore next */
  const handleProductAdd = (values, actions) => {
    let aux = [...itemList];
    values.forEach(function(item){
      aux.push({
        seller_sku_code: item.seller_sku_code,
        productName: item.productName,
        quantity: item.quantity,
        price: item.price,
        expiry_date: item.expiry_date,
      });
    });
    updateFormikField(aux);
  };

  const handleCancelOrder = (id) => {
    PurchaseOrdersService.cancelPurchaseOrder(id)
    .then((response) =>{
      createNotification('success', 'The order has been canceled');
      delayedRedirect('/inventory_and_shipments#purchase_orders');
    })
    .catch((error) =>{
      createNotification('error', 'The order could not be canceled');
      delayedRedirect('/inventory_and_shipments#purchase_orders');
    })
  };

  const exportCSVTemplate = () =>{
    PurchaseOrderLineItemsService.getPurchaseOrdersItemsCSV()
    .then((response) =>{
      const download = new FileDownload('purchase_order_items_template.csv', response.data);
      download.downloadFile();
    });
  };

  /* istanbul ignore next */
  const displaySpinner = () => {
    if (waitingForShipmentCreation) {
      return (
        <Spinner
          size={15}
          loading={waitingForShipmentCreation}
          css={{ display: 'inline', float: 'left', marginTop: '10px' }}
        />
      );
    }
  };

  const getNameBySku = (sku) => {
    if (sku && products && products.length > 0) {
      const elem = products.filter((prod) => prod.seller_sku_code == sku)[0];
      return elem.name;
    }
  };

  /* istanbul ignore next */
  const displayFormAddItems = () => {
    if (visibleCreateNewProduct) {
      if (products && products.length === 0) {
        createNotification('warning', "You don't have products");
        return null;
      }
      return (
        <PurchaseOrderItemListForm
          products={products}
          handleProductAdd={handleProductAdd}
          toggleVisibilityCreateNewProduct={toggleVisibilityCreateNewProduct}
        />
      );
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    setItemList(
      item_data.map((elem) => {
        return {
          expiry_date: elem.expiry_date,
          price: elem.price,
          seller_sku_code: elem.seller_sku_code,
          productName: getNameBySku(elem.seller_sku_code),
          quantity: elem.quantity,
        };
      })
    );
  }, [item_data]);

  return (
    <Container fluid style={{ position: 'relative', padding: '0px' }}>
      <Form id="shipmentEdit">
        <Row>
          <Col md="12" lg="4">
            <div className="form-group row">
              <label className="col-sm-3" style={{ marginTop: '0px' }}>
                Picking Location
              </label>
              <Select
                options={addresses.map((address) => {
                  /* istanbul ignore next */
                  return { value: address.id, label: address.label };
                })}
                onChange={(address) => {
                  /* istanbul ignore next */
                  setFieldValue('pickingLocation', { value: address.value, label: address.label });
                }}
                className="col-sm-9 no-padding select-container"
                value={addresses.find((address) => {
                  /* istanbul ignore next */
                  if(values.pickingLocation){
                    return address.id === values.pickingLocation.value;
                  }
                  return null;
                })}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <label className="col-sm-3" style={{ marginTop: '0px' }}>
                Delivery Location
              </label>
              <Select
                options={values.deliveryLocation}
                onChange={(location) => {
                  /* istanbul ignore next */
                  setFieldValue('deliveryLocation', { value: location.value, label: location.label });
                }}
                className="col-sm-9 no-padding select-container"
                value={values.deliveryLocation}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field
                name="cost"
                type="string"
                label={`Order Value (${company?.preferred_currency || 'EUR'})`}
                disabled={true}
                labelColSize={'col-sm-3'}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field
                name="orderDate"
                type="date"
                label="Order Date"
                placeholder="Order Date"
                disabled={readonly}
                labelColSize={'col-sm-3'}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field
                name="pickupDate"
                type="date"
                label="Pickup Date"
                placeholder="Pickup Date"
                disabled={readonly}
                labelColSize={'col-sm-3'}
                onChange={(event) => {
                  /* istanbul ignore next */
                  setFieldValue('pickupDate', event.target.value);
                  /* istanbul ignore next */
                  const deliveryDateValue = addWeeks(
                    new Date(event.target.value),
                    6
                  );
                  /* istanbul ignore next */
                  setFieldValue(
                    'deliveryDate',
                    convertToInputFormat(deliveryDateValue)
                  );
                }}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row" style={{ paddingRight: '10px' }}>
              <Field
                name="deliveryDate"
                type="date"
                label="Delivery Date"
                placeholder="Delivery Date"
                disabled={readonly}
                labelColSize={'col-sm-3'}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              {!readonly && (
                <>
                  <Button variant="primary" style={{ marginBottom: '10px' }} onClick={(_) => toggleVisibilityCreateNewProduct()}>
                    Add Item
                  </Button>
                  <Button variant="danger" style={{ float: 'right', marginRight: '15px' }}onClick={(_) => { handleCancelOrder(orderId)}}>
                    Cancel Order
                  </Button>
                  <BulkUpload
                    fileTypes=".csv"
                    handleSubmit={(values, actions) => {
                      PurchaseOrderLineItemsService.loadItemsFromCSV(values.document)
                      .then((response) => {
                        return handleProductAdd(response.data.purchase_order_items, actions);
                      });
                    }}
                    title="new item list"
                    style={{ float: 'right' }}
                    autoClose={true}
                    format={
                      <div>
                        <p>Click <a rel="noopener noreferrer" href="#" onClick={() => exportCSVTemplate()}>here</a> to download a new blank template.</p>
                      </div>
                    }
                  />
                  <Button variant="primary" style={{ float: 'right', marginRight: '15px' }} onClick={(_) => { setItemList([]); }}>
                    Clear Items
                  </Button>
                </>
              )}
              {displayFormAddItems()}
              <PurchaseOrderItemListTable
                itemList={itemList}
                count={itemList.length}
                products={products}
                deleteFromItemList={deleteFromItemList}
                readonly={readonly}
                company={company}
              />
              <ErrorMessage component="small" name="item_data" />
            </div>
          </Col>
        </Row>
        {!readonly && (
          <div className="actions">
            {displaySpinner()}
            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default PurchaseOrdersEditFormComponent;
