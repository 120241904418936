import React from 'react';
import InnerLayout from './layout/InnerLayout';

const NoPermissionsComponent = (props) => {
  const { user, logOut, company } = props;

  return (
    <InnerLayout logOut={logOut} user={user} company={company}>
      You don't have permissions
    </InnerLayout>
  );
};

export default NoPermissionsComponent;
