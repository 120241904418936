import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

const FlatButton = (props) => {
  const { children, onClick, type, variant, disabled } = props;

  const otherStyles =
    variant === 'primary'
      ? `background-color: #04F3B2;
    color: white;
    font-weight: bold;
    border: none;
    &:hover, &:active, &:focus, &:disabled, &:visited{
        background-color: #04F3B2 !important;
        opacity: .8 !important;
    }
    `
      : `
        background-color: white;
        border: 1px solid #273364;
        color: #273364;

        &:hover {
            background-color: white;
            color: #273364;
            opacity: .8;
        }
      `;

  const StyledButton = styled(Button)`
    width: 100%;
    height: 50px;
    ${otherStyles}
  `;

  return (
    <StyledButton
      type={type}
      disabled={disabled}
      onClick={type !== 'submit' ? (params) => onClick(params) : () => {}}
      onMouseDown={(event)=>{event.preventDefault()}}
    >
      {children}
    </StyledButton>
  );
};

export default FlatButton;
