import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LocationsService from '../../../services/data/locations_service';
import AddressesService from '../../../services/data/address_service';
import ShipmentsService from '../../../services/data/shipments_service';
import ProductsService from '../../../services/data/products_service';
import InnerLayout from '../../layout/InnerLayout';
import { createNotification } from '../../common/notifications';
import { formattedPrice } from '../../common/dataTypes/prices';
import Spinner from '../../common/ui/Spinner';
import { convertToInputFormat } from '../../common/dataTypes/dates';
import Form from './ShipmentsEditForm';

const ShipmentsEditSchema = Yup.object().shape({
  pickingLocation: Yup.object(),
  deliveryLocation: Yup.object(),
  orderDate: Yup.date(),
  pickupDate: Yup.date(),
  deliveryDate: Yup.date(),
  cost: Yup.string(),
});

const ShipmentsEditComponent = ({
  user,
  location,
  company,
  match,
  logOut,
  products,
  loadProducts,
}) => {
  const [shipment, setShipment] = useState(undefined);
  const [locations, setLocations] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [item_data, set_item_data] = useState([]);
  const [waitingForShipmentCreation, setWaitingForShipmentCreation] = useState(
    false
  );
  const [loading, setLoading] = useState(true);

  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    setWaitingForShipmentCreation(true);
    ShipmentsService.updateShipment(match.params.id, values)
      .then((response) => {
        setWaitingForShipmentCreation(false);
        createNotification('success', 'You shipment has been updated');
        actions.setSubmitting(false);
      })
      .catch((error) => {
        setWaitingForShipmentCreation(false);
        createNotification('error', 'Your shipment was not updated');
        actions.setSubmitting(false);
      });
  };

  /* istanbul ignore next */
  const getFromId = (collection, id) => {
    return collection.filter((elem) => elem.id === id)[0];
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (products && products.length === 0) {
      ProductsService.loadProducts().then((response) => {
        loadProducts(response.data.products);
      });
    }
    ShipmentsService.loadShipment(match.params.id)
      .then((response) => {
        setShipment(response.data);
        setLoading(false);
        set_item_data(
          response.data.shipment_items.length === 0
            ? response.data.purchase_order.purchase_order_items
            : response.data.shipment_items
        );
        LocationsService.loadLocations().then((response) => {
          setLocations(response.data.locations);
        });

        AddressesService.loadAddresses().then((response) => {
          setAddresses(response.data.addresses);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [match.params.id, loadProducts]);

  /* istanbul ignore next */
  const getCost = (itemList) => {
    let cost = 0;
    if (itemList.length > 0) {
      const aux = itemList.map(
        (elem) =>
          (elem.price ? elem.price : 0) * (elem.quantity ? elem.quantity : 0)
      );
      cost = aux.reduce((a, b) => a + b, 0);
      return cost;
    }
    return cost;
  };

  if (loading) {
    return (
      <InnerLayout
        logOut={logOut}
        user={user}
        location={location}
        company={company}
        Z
      >
        <Spinner loading={loading} css={{ display: 'inline' }} />
      </InnerLayout>
    );
  }

  return (
    <InnerLayout
      logOut={logOut}
      user={user}
      location={location}
      company={company}
      Z
    >
      <Formik
        initialValues={{
          pickingLocation: addresses &&
            addresses.length > 0 &&
            getFromId(
              addresses,
              shipment.purchase_order.billing_address_id
            ) && {
              value: getFromId(
                addresses,
                shipment.purchase_order.billing_address_id
              ).id,
              label: getFromId(
                addresses,
                shipment.purchase_order.billing_address_id
              ).label,
            },
          deliveryLocation: locations &&
            locations.length > 0 &&
            getFromId(locations, shipment.delivery_location) && {
              value: getFromId(locations, shipment.delivery_location).id,
              label: getFromId(locations, shipment.delivery_location).label,
            },
          orderDate: shipment && shipment.purchase_order.issued_at,
          pickupDate:
            shipment && convertToInputFormat(new Date(shipment.pickup_date)),
          deliveryDate:
            shipment && convertToInputFormat(new Date(shipment.delivery_date)),
          actualPickupDate: '',
          actualDeliveryDate: '',
          item_data: item_data,
          cost: formattedPrice(
            getCost(item_data),
            company?.preferred_currency
          ),
        }}
        enableReinitialize={true}
        validationSchema={ShipmentsEditSchema}
        onSubmit={(values, actions) => {
          /* istanbul ignore next */
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            addresses={addresses}
            locations={locations}
            products={products}
            item_data={item_data}
            readonly={location.hash === '#show' ? true : false}
            waitingForShipmentCreation={waitingForShipmentCreation}
            getCost={getCost}
            values={values}
            company={company}
          />
        )}
      </Formik>
    </InnerLayout>
  );
};

export default ShipmentsEditComponent;
