import reduceReducers from 'reduce-reducers';
import productReducers from './productReducers';
import invoicesAndPaymentsReducers from './invoicesAndPaymentsReducers';
import shipmentReducers from './shipmentReducers';
import loginReducers from './loginReducers';
import usersReducers from './usersReducer';
import companiesReducers from './companiesReducers';

export const DEFAULT_STATE = {
  products: [],
  payments: [],
  refunds: [],
  invoices: [],
  shipments: [],
  loggedIn: false,
  user: undefined,
  company: undefined,
};

const reducers = reduceReducers(
  productReducers,
  invoicesAndPaymentsReducers,
  loginReducers,
  usersReducers,
  shipmentReducers,
  companiesReducers,
);

export default reducers;
