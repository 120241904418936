import moment from 'moment';
import axios from '../axios';

class OrdersService {
  static loadOrders(scope = "week", admin_view = false) {
    return axios.get(`/orders?scope=${scope}&admin_view=${admin_view}`);
  }

  static loadOrder(id) {
    return axios.get(`/orders/${id}`);
  }

  static getOrdersXLSX(scope = "week", admin_view = false, template = false) {
    return axios.request({
      url: `/orders.xlsx?scope=${scope}&admin_view=${admin_view}&template=${template}`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  static createOrder(order) {
    return axios.post('/orders', {
      order: {
        billing_address_id: order.address_id,
        company_id: order.company_id,
        shipping_address_id: order.address_id,
      },
    });
  }

  static getToProcess(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'DRAFT');
    }
  }

  static getActive(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'ACTIVE');
    }
  }

  static getReceived(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'PAYMENT PENDING');
    }
  }

  static getToCollect(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'TO COLLECT');
    }
  }

  static getInTransit(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'IN TRANSIT');
    }
  }

  static getDelivered(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'DELIVERED');
    }
  }

  static getInError(orders) {
    if (orders) {
      return orders.filter((order) => order.status === 'CONTACT SUPPORT');
    }
  }

  static getFilteredPreviousOrderByDate(orders, date) {
    if (orders) {
      return orders.filter((order) =>
        moment(order.purchase_order.issued_at).isAfter(date, 'day')
      );
    }
  }

  static productSold(orderLineItems) {
    if (orderLineItems) {
      const productsArray = orderLineItems.map((oli) => parseInt(oli.quantity));
      if (productsArray.length > 0) {
        return productsArray.reduce((total, amount) => total + amount);
      }
      return 0;
    }
  }

  static productsSales(salesOrders) {
    if (salesOrders) {
      const productsArray = salesOrders
        .filter((so) => so.status === 'finalized')
        .map((so) => parseFloat(so.total));
      if (productsArray.length > 0) {
        return productsArray.reduce((total, amount) => total + amount);
      }
      return 0;
    }
  }

  static ordersProcessed(salesOrders) {
    if (salesOrders) {
      const productsArray = salesOrders.filter(
        (so) => so.status === 'finalized'
      );
      return productsArray.length;
    }
    return 0;
  }

  static aov(salesOrders) {
    if (salesOrders && salesOrders.length > 0) {
      const sales = this.productsSales(salesOrders);
      const countSales = salesOrders.length;
      return sales / countSales;
    }
    return 0;
  }

  /**
   * Send XLSX file to backend for orders bulk creation.
   * @param file Object from input type file
   */
  static bulkCreation(file) {
    let formData = new FormData();
    if (file){
      formData.append('order[orders_file]', file);
    }
    return axios.post('orders', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default OrdersService;
