import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CountrySelectorInput from '../../common/ui/CountrySelectorInput';
import Field from '../../common/ui/Field';
import Select from '../../common/ui/Select';
import ErrorMessage from '../../common/ui/ErrorMessage';

const AddressTypes = [
  { value: 'office', label: 'Office' },
  { value: 'warehouse', label: 'Warehouse' },
  { value: 'port_of_landing', label: 'Port of loading' },
];

const AddressForm = (props) => {
  const { setFieldValue, address } = props;
  const [, setAddressType] = useState();

  useEffect(() => {
    if (address) {
      setAddressType(
        AddressTypes.find((elem) => elem.value === address.address_type)
      );
    }
  }, [address]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="label"
              type="text"
              label="Name"
              placeholder="Optional"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="address1"
              type="text"
              placeholder="Optional"
              label="Address 1"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="address2"
              type="text"
              label="Address 2"
              placeholder="Optional"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="postal_code"
              type="text"
              label="Postal Code"
              placeholder="Optional"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <label
              className="form-field-label no-padding"
              style={{ marginTop: '8px', width: '100px' }}
            >
              Type
            </label>
            <Select
              options={AddressTypes}
              onChange={(ad) => {
                setFieldValue('type', ad.value);
              }}
              className="col-sm-9 no-padding"
              value={
                address &&
                AddressTypes.find((ad) => {
                  return ad.value === address.type;
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="city"
              type="text"
              placeholder="Optional"
              label="City"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="state"
              type="text"
              placeholder="Optional"
              label="State"
              labelStyle={{ marginTop: '8px', width: '100px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <label
              className="form-field-label no-padding"
              style={{ marginTop: '8px', width: '100px' }}
            >
              Country
            </label>
            <CountrySelectorInput
              onChangeHandler={(country) => {
                setFieldValue('country_code', country.value);
              }}
              className="col-sm-9 no-padding"
              defaultCountry={address && address.country_code}
              selectedCountry={address && address.country_code}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressForm;
