import { LOAD_PRODUCTS } from '../actions/actions';

const productReducers = (state, action) => {
	switch (action.type) {
	case LOAD_PRODUCTS:
		let newState = {...state};
		newState.products = action.products;
		return newState;
	default:
		return state;
	}
}

export default productReducers;
