import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { getData } from 'country-list';
import Select from '../../common/ui/Select';

const CountrySelectorInputComponent = ({
  defaultCountry,
  onChangeHandler,
  className,
}) => {
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, [defaultCountry]);
  return (
    <>
      <Select
        options={getData().map((country) => {
          return { value: country.code, label: country.name };
        })}
        value={getData()
          .filter((country) => {
            return country.code === selectedCountry;
          })
          .map((c) => {
            return { value: c.code, label: c.name };
          })}
        className={className}
        onChange={(val) => {
          /* istanbul ignore next */
          setSelectedCountry(val);
          onChangeHandler(val);
        }}
      />
      <ErrorMessage style={{margin: '5px auto'}} className="form-field-error" component="small" name="country" />
    </>
  );
};

CountrySelectorInputComponent.propTypes = {
  onChangeHandler: PropTypes.func,
};

export default CountrySelectorInputComponent;
