import React from 'react';
import PropType from 'prop-types';
import { Form } from 'formik';
import { getName } from 'country-list';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from 'react-spinners/BeatLoader';
import ImageDropzone from '../images/ImageDropzone';
import ListImages from '../images/ListImages';
import CountrySelectorInput from '../common/ui/CountrySelectorInput';
import EditableInput from '../common/ui/EditableInput';
import Field from '../common/ui/Field';
import UnitOfMeasureSelectorInput from '../common/ui/UnitOfMeasureSelectorInput';
import TemperatureOfStorageSelectorInput from '../common/ui/TemperatureOfStorageSelectorInput';
import { formatUnitsOfMeasure } from '../common/dataTypes/string';
import Table from '../common/ui/Table';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';
import NavLink from '../layout/NavLink';
import './Products.css';

const tooltipStyles = css`
  padding: 20px;
  color: black;
  border: 1px solid black !important;
  margin-top: 10px;

  :before {
    border-top: 8px solid black !important;
  }
`;

const ProductEditFormComponent = ({
  values,
  product,
  setFieldValue,
  handleSubmit,
  images,
  setImages,
  readonly,
  admin,
  updatingImages,
  user,
  location,
  searchTerm,
}) => {
  const displayUpdatingImagesSpinner = () => {
    if (updatingImages) {
      return (
        <Col md="4" style={{ padding: '0px' }}>
          <center>
          Updating images, please wait. <Spinner loading={updatingImages} />
          </center>
        </Col>
      );
    }
    return null;
  };
  const displayImageDrop = () => {
    if(!readonly){
      return (
        <Row>
          <Col md="12" style={{ padding: '0px' }}>
            <label className="form-field-label">Images</label>
          </Col>
          {displayUpdatingImagesSpinner()}
          <Col md="12" style={{ padding: '0px' }}>
          </Col>
          <Col md="1" style={{ padding: '0px', textAlign: 'center' }}>
            <ImageDropzone
              images={images}
              setImages={setImages}
              disabled={readonly}
              handleSubmit={handleSubmit}
            />
          </Col>
          <Col md="11">
            <ListImages
              productId={product && product.id.toString()}
              images={images}
              setImages={setImages}
            />
          </Col>
          <Col md="12" style={{ padding: '10px 0px 0px 0px' }}>
            <i style={{ fontSize: '0.8rem' }}> Please upload picture of at least 1600 px by 1600 px.</i>
          </Col>
        </Row>
      );
      }else{
        return(
        <Row>
          <Col md="11">
            <ListImages
              productId={product && product.id.toString()}
              images={images}
              setImages={setImages}
            />
          </Col>
        </Row>
        );
      }
  }
  return (
    <Container fluid>
      <Form id="productEdit">
        <Row>
          <Col style={{ padding: '0px', display: 'flex', alignItems: 'center' }}>
            <NavLink
              user={user}
              location={location}
              searchTerm={searchTerm}
              perform="Products:list"
              path="/products"
              Icon={
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ color: 'black', width: '20px', marginRight: '10px' }}
                />
              }
              label="Back"
            />
          </Col>
        </Row>
        {displayImageDrop()}
        <Row style={{ marginTop: '15px' }}>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="sellerSkuCode"
                    type="text"
                    placeholder="Seller SKU Code"
                    className="inline-field"
                    noCol
                  />
                }
                field={'sellerSkuCode'}
                label={'Seller SKU Code'}
                text={values.sellerSkuCode}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
          <Col md="12" lg="1"></Col>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="fluetisSkuCode"
                    type="text"
                    placeholder="Fluetis SKU Code"
                    className="inline-field"
                    noCol
                  />
                }
                field={'fluetisSkuCode'}
                label={'Fluetis SKU Code'}
                text={values.fluetisSkuCode}
                setFieldValue={setFieldValue}
                disabled={!admin}
                handleSubmit={handleSubmit}
              />
          </Col>
        </Row>        
        <Row>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="brand"
                    type="text"
                    placeholder="Brand"
                    className="inline-field"
                    noCol
                  />
                }
                field={'brand'}
                label={'Brand'}
                text={values.brand}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
          <Col md="12" lg="1"></Col>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="hsCode"
                    type="text"
                    placeholder="HS Code"
                    className="inline-field"
                    noCol
                  />
                }
                field={'hsCode'}
                label={'HS Code'}
                text={values.hsCode}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="platformName"
                    type="text"
                    placeholder="Name"
                    className="inline-field"
                    noCol
                  />
                }
                field={'platformName'}
                label={'Name'}
                text={values.platformName}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
          <Col md="12" lg="1"></Col>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <CountrySelectorInput
                    valueType={'short'}
                    onChangeHandler={(country) =>
                      setFieldValue('country', country.value)
                    }
                    defaultCountry={values.country}
                    selectedCountry={values.country}
                    className="inline-field"
                    noCol
                  />
                }
                field={'country'}
                label={'Country'}
                text={(values.country !== '' && getName(values.country)) || ''}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <UnitOfMeasureSelectorInput
                    name="units_of_measure"
                    className="inline-field"
                    value={values.units_of_measure}
                    noCol
                    onChangeHandler={(unit) =>
                      setFieldValue('units_of_measure', unit.value)
                    }
                  />
                }
                field={'units_of_measure'}
                placeholder={'Units of Measure'}
                label="Unit of Measurement"
                text={formatUnitsOfMeasure(values.units_of_measure)}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
          <Col md="12" lg="1"></Col>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <TemperatureOfStorageSelectorInput
                    name="temperatureOfStorage"
                    className="inline-field"
                    value={values.temperatureOfStorage}
                    noCol
                    onChangeHandler={(unit) =>
                      setFieldValue('temperatureOfStorage', unit.value)
                    }
                  />
                }
                field={'temperatureOfStorage'}
                placeholder={'Temperature Of Storage'}
                label={'Temperature Of Storage'}
                text={values.temperatureOfStorage || ''}
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="5">
            <EditableInput
                  inputComponent={
                    <Field
                      name="measure_value"
                      type="number"
                      placeholder="Measure Value"
                      className="inline-field"
                      noCol
                    />
                  }
                  field={'measure_value'}
                  placeholder={'Measure Value'}
                  label="Measure"
                  text={values.measure_value}
                  setFieldValue={setFieldValue}
                  disabled={readonly}
                  handleSubmit={handleSubmit}
                />
          </Col>
          <Col md="12" lg="1"></Col>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="manufactureShelfLife"
                    type="number"
                    placeholder="(Days)"
                    className="inline-field"
                    noCol
                  />
                }
                field={'manufactureShelfLife'}
                placeholder={'Days'}
                text={values.manufactureShelfLife}
                label=
                {
                  <label className="form-field-label" data-tip data-for="formatInfo">
                    <ReactTooltip
                      id="formatInfo"
                      effect="solid"
                      type="light"
                      css={tooltipStyles}
                      offset={{bottom: 20}}
                    >

                      <p>If your product has no expiry date, please keep it as 999</p>
                    </ReactTooltip>
                    Manufacturing Shelf Life
                    <FontAwesomeIcon
                      style={{marginLeft: '3px'}}
                      icon={faQuestionCircle}
                    >
                    </FontAwesomeIcon>
                  </label>
                }
                setFieldValue={setFieldValue}
                disabled={readonly}
                handleSubmit={handleSubmit}
              />
            <label style={{color: 'red'}}>{values.batchableMessage}</label>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="5">
            <EditableInput
                inputComponent={
                  <Field
                    name="name"
                    type="text"
                    placeholder="Auto"
                    className="inline-field"
                    noCol
                  />
                }
                field={'name'}
                label={'E-commerce Name (Auto)'}
                text={values.name}
                setFieldValue={setFieldValue}
                disabled={true}
                handleSubmit={handleSubmit}
              />
          </Col>
          <Col md="12" lg="1"></Col>
          <Col md="12" lg="5">
            <EditableInput
                  inputComponent={
                    <Field
                      name="exportShelfLife"
                      type="number"
                      placeholder="Days"
                      className="inline-field"
                      noCol
                    />
                  }
                  field={'exportShelfLife'}
                  placeholder={'Days'}
                  label="Shelf Life Guarantee"
                  text={values.exportShelfLife}
                  setFieldValue={setFieldValue}
                  disabled={readonly}
                  handleSubmit={handleSubmit}
                />
          </Col>
        </Row>
        <Row id="format-template" style={{ marginTop: '25px' }}>
          <Col md="12" lg ="11">
            <Table style={{ textAlign: 'center' }} >
              <thead>
                <tr>
                  <th></th>
                  <th>Quantity</th>
                  <th>Barcode</th>
                  <th>Format (Auto)</th>
                  <th>Gross Weight (G)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Single</th>
                  <td>
                    <EditableInput
                        inputComponent={
                          <Field
                            name="singleQuantity"
                            type="number"
                            placeholder="Quantity"
                            className="inline-field"
                            noCol
                          />
                        }
                        field={'singleQuantity'}
                        placeholder={'Quantity'}
                        noLabel
                        text={values.singleQuantity}
                        setFieldValue={setFieldValue}
                        disabled={readonly}
                        handleSubmit={handleSubmit}
                      />
                  </td>
                  <td>
                    <EditableInput
                      inputComponent={
                        <Field
                          name="upc"
                          type="text"
                          placeholder="Barcode"
                          className="inline-field"
                          noCol
                        />
                      }
                      noLabel
                      field={'upc'}
                      text={values.upc}
                      setFieldValue={setFieldValue}
                      disabled={readonly}
                      handleSubmit={handleSubmit}
                    />
                  </td>
                  <td>
                    <EditableInput
                              inputComponent={
                                <Field
                                  name="singleFormat"
                                  type="text"
                                  placeholder="Auto"
                                  className="inline-field"
                                  noCol
                                />
                              }
                              noLabel
                              placeholder={"Auto"}
                              field={'singleFormat'}
                              text={values.singleFormat}
                              setFieldValue={setFieldValue}
                              disabled={true}
                              handleSubmit={handleSubmit}
                            />
                  </td>
                  <td>
                    <EditableInput
                      inputComponent={
                        <Field
                          name="iumWeight"
                          type="number"
                          placeholder="Weight"
                          className="inline-field"
                          noCol
                        />
                      }
                      noLabel
                      field={'iumWeight'}
                      placeholder={'Weight'}
                      text={values.iumWeight}
                      setFieldValue={setFieldValue}
                      disabled={readonly}
                      handleSubmit={handleSubmit}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Pack (Optional)</th>
                  <td>
                    <EditableInput
                          inputComponent={
                            <Field
                              name="packQuantity"
                              type="number"
                              placeholder="Quantity"
                              className="inline-field"
                              noCol
                            />
                          }
                          field={'packQuantity'}
                          placeholder={'Quantity'}
                          noLabel
                          text={values.packQuantity}
                          setFieldValue={setFieldValue}
                          disabled={readonly}
                          handleSubmit={handleSubmit}
                        />
                  </td>
                  <td>
                    <EditableInput
                        inputComponent={
                          <Field
                            name="packBarcode"
                            type="text"
                            placeholder="Barcode"
                            className="inline-field"
                            noCol
                          />
                        }
                        noLabel
                        placeholder={"Barcode"}
                        field={'packBarcode'}
                        text={values.packBarcode}
                        setFieldValue={setFieldValue}
                        disabled={readonly}
                        handleSubmit={handleSubmit}
                      />
                  </td>
                  <td>
                    <EditableInput
                              inputComponent={
                                <Field
                                  name="packFormat"
                                  type="text"
                                  placeholder="Auto"
                                  className="inline-field"
                                  noCol
                                />
                              }
                              noLabel
                              placeholder={"Auto"}
                              field={'packFormat'}
                              text={values.packFormat}
                              setFieldValue={setFieldValue}
                              disabled={true}
                              handleSubmit={handleSubmit}
                            />
                  </td>
                  <td>
                    <EditableInput
                        inputComponent={
                          <Field
                            name="packWeight"
                            type="number"
                            placeholder="Weight"
                            className="inline-field"
                            noCol
                          />
                        }
                        noLabel
                        field={'packWeight'}
                        placeholder={'Weight'}
                        text={values.packWeight}
                        setFieldValue={setFieldValue}
                        disabled={readonly}
                        handleSubmit={handleSubmit}
                      />
                  </td>
                </tr>
                <tr>
                  <th>Carton</th>
                  <td>
                    <EditableInput
                          inputComponent={
                            <Field
                              name="cartonQuantity"
                              type="number"
                              placeholder="Quantity"
                              className="inline-field"
                              noCol
                            />
                          }
                          field={'cartonQuantity'}
                          placeholder={'Quantity'}
                          noLabel
                          text={values.cartonQuantity}
                          setFieldValue={setFieldValue}
                          disabled={readonly}
                          handleSubmit={handleSubmit}
                        />
                  </td>
                  <td>
                    <EditableInput
                          inputComponent={
                            <Field
                              name="cartonBarcode"
                              type="text"
                              placeholder="Barcode"
                              className="inline-field"
                              noCol
                            />
                          }
                          noLabel
                          placeholder={"Barcode"}
                          field={'cartonBarcode'}
                          text={values.cartonBarcode}
                          setFieldValue={setFieldValue}
                          disabled={readonly}
                          handleSubmit={handleSubmit}
                        />
                  </td>
                  <td>
                    <EditableInput
                            inputComponent={
                              <Field
                                name="cartonFormat"
                                type="text"
                                placeholder="Auto"
                                className="inline-field"
                                noCol
                              />
                            }
                            noLabel
                            placeholder={"Auto"}
                            field={'cartonFormat'}
                            text={values.cartonFormat}
                            setFieldValue={setFieldValue}
                            disabled={true}
                            handleSubmit={handleSubmit}
                          />
                  </td>
                  <td>
                    <EditableInput
                          inputComponent={
                            <Field
                              name="piumWeight"
                              type="number"
                              placeholder="Weight"
                              className="inline-field"
                              noCol
                            />
                          }
                          noLabel
                          field={'piumWeight'}
                          placeholder={'Weight'}
                          text={values.piumWeight}
                          setFieldValue={setFieldValue}
                          disabled={readonly}
                          handleSubmit={handleSubmit}
                      />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

ProductEditFormComponent.propTypes = {
  isSubmitting: PropType.bool,
  values: PropType.object,
  setFieldValue: PropType.func,
  handleSubmit: PropType.func,
};

export default ProductEditFormComponent;
