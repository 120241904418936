import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import InvoicesAndPaymentsService from '../../services/data/invoices_service';
import Can from '../roles/Can';
import InnerLayout from '../layout/InnerLayout';
import Invoices from './invoices/Invoices';
import Payments from './payments/Payments';
import PaginationComponent from '../common/ui/PaginateComponent';

const AccountingAndPaymentComponent = (props) => {
  const {
    invoices,
    payments,
    user,
    company,
    logOut,
    location,
    loadInvoicesAndPayments,
  } = props;

  /* istanbul ignore next */
  useEffect(() => {
    if (invoices && invoices.length <= 0) {
      InvoicesAndPaymentsService.loadInvoices()
        .then((response) =>
          loadInvoicesAndPayments(
            response.data.invoices || [],
            response.data.payments || [],
            response.data.refunds || []
          )
        )
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, []);

  if (user) {
    return (
      <Can
        role={user.type.toLowerCase()}
        perform="AccountingAndPayment:list"
        yes={() => (
          <InnerLayout
            logOut={logOut}
            user={user}
            location={location}
            company={company}
          >
            <Tabs defaultActiveKey="invoices" id="tab_accounting">
              <Tab eventKey="invoices" title="Invoices">
                <PaginationComponent
                  componentList={<Invoices company={company}/>}
                  list={invoices}
                  perPage={20}
                />
              </Tab>
              {/* <Tab eventKey="payments" title="Payments">
                <PaginationComponent
                  componentList={<Payments company={company}/>}
                  list={payments}
                  perPage={10}
                />
              </Tab> */}
            </Tabs>
          </InnerLayout>
        )}
        no={() => <Redirect to="/no_permissions" />}
      />
    );
  }
  return null;
};

export default AccountingAndPaymentComponent;
