import React from 'react';
import { Form } from 'formik';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import FileDownload from '../dataTypes/fileDownload';

/**
 * Component to render generic Bootstrap Modal component.
 * List of properties:
 *  - show: Indicates when modal is shown or hidden
 *  - onHide: A callback fired when the header closeButton or non-static backdrop is clicked.
 *  - body: Content of modal body
 *  - header: Content of modal header
 *  - loading: (boolean) Show or hide loading message
 *  - asForm: Indicates if modal must act as a form
 *  - buttons: Array of buttons descriptions. IE:
 *      [
 *        {
 *          id: 'close', // Button identifier, madatory
 *          show: true, // Show or hide button (optional, default false)
 *          variant: 'secondary', // Bootstrap button variant (optional, default primary)
 *          text: 'Close', // Button's text
 *          onClick: () => alert("Hello world") // Callback to call on click (optional)
 *        }
 *      ]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const GenericlModalComponent = (props) => {
  const { body, show, onHide, header, loading, buttons, asForm, download, next, previous, position } = props;

  const modalHeader = <Modal.Header closeButton style={{textAlign: 'center !important', fontSize: '20px'}}>
    {header && header}
    {download &&
      <a onClick={() => {
        const download = new FileDownload('image.jpeg', "");
        download.downloadFileFromURL(body.props.src);
      }}>
        <FontAwesomeIcon
          icon={faDownload}
          size="25"
          style={{ marginRight: '10px', minWidth: '20px', cursor: "pointer" }}
        />  
      </a>
    }
  </Modal.Header>;

  const modalBody = body && <Modal.Body style={{ display: 'flex', position: 'relative' }}>
    {download &&
      <FontAwesomeIcon
        icon={faArrowLeft}
        size="25"
        style={{ position: 'absolute', bottom: '50%', left: '5%', minWidth: '30px', cursor: "pointer" }}
        onClick={() => {
          onHide();
          previous(position);
        }}
      />
    } 
    { body }
    {download &&
      <FontAwesomeIcon
        icon={faArrowRight}
        size="25"
        style={{ position: 'absolute', bottom: '50%', right: '5%', minWidth: '30px', cursor: "pointer" }}
        onClick={() => {
          onHide();
          next(position);
        }}
      />
    } 
  </Modal.Body>;

  const modalFooter = (loading || buttons) && <Modal.Footer>
    {
      loading && <span style={{marginRight: '200px'}}>
            {'Loading...'}
          </span>
    }
    {buttons.map((button) =>
        (
            button.show && <Button
                key={button.id}
                variant={button.variant ?? 'primary'}
                onClick={button.onClick}
                type={button.type}
                disabled={button.disabled}
            >
              {button.text}
            </Button>
        )
    )}
  </Modal.Footer>;

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      {
        asForm
        ? (
          <Form>
            {modalHeader}
            {modalBody}
            {modalFooter}
          </Form>
        )
        : (
          <div>
            {modalHeader}
            {modalBody}
            {modalFooter}
          </div>
        )
      }
    </Modal>
  );
};

export default GenericlModalComponent;
