import { css } from '@emotion/core';

export const colImage = css`
  text-align: center;
  margin-right: 50px;
  padding-bottom: 20px;
  .imageIcon {
    color: blue;
    font-size: 25px;
    visibility: hidden;
  }

  .imageActions {
    min-height: 25px;
  }

  #markAsDefaultIcon {
    position: relative;
    bottom: 32px;
    left: -18px;
    cursor: pointer;
  }

  #removeIcon {
    position: relative;
    bottom: 140px;
    left: 70px;
    cursor: pointer;
  }

  :hover{
    img{
      border: 1px solid blue;
      opacity: 0.3;  
    }
  
    .imageIcon{
      visibility: visible;
    }
`;
