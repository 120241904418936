import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from '../common/ui/Modal';

const ImageComponent = ({
  productId,
  images,
  image,
  position,
  deleteImage,
  previous,
  next,
  onlyModal,
  resetPosition
}) => {

  const [displayModal, setDisplayModal] = useState(false);

  /* istanbul ignore next */
  const closeDisplayModal = () => {
    if (onlyModal){
      resetPosition();
    }

    else{
      setDisplayModal(false);
    }
  };

  /* istanbul ignore next */
  const showDisplayModal = () => {
    setDisplayModal(true);
  };

  /* istanbul ignore next */
  const getSrc = () => {
    return image.public_url ? image.public_url : image.url_image_local;
  };

  return (
    <>
      <img src={getSrc()} onClick={() => showDisplayModal()} alt="Product" height="150" width="150"></img>
      <div className="imageActions">
        <FontAwesomeIcon
          icon={faTrash}
          id="removeIcon"
          className="imageIcon"
          onClick={() => deleteImage(position)}
        >
          X
        </FontAwesomeIcon>
      </div>

      {(displayModal || onlyModal) && (
        <Modal
          onHide={closeDisplayModal}
          onCancel={closeDisplayModal}
          show={showDisplayModal}
          next={next}
          previous={previous}
          position={position}
        >
          <img src={getSrc()} alt="Product" width="100%"></img>
        </Modal>
      )}
    </>
  );
};

ImageComponent.propTypes = {
  image: PropTypes.object,
  productId: PropTypes.string,
};

export default ImageComponent;
