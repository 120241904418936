import React from 'react';
import { Link } from 'react-router-dom';

const LinkComponent = (props) => {
  const { className, to, variant, children, style, searchTerm } = props;

  let finalStyle = { ...style };
  if (variant === 'button') {
    finalStyle = {
      backgroundColor: '#04F3B2',
      border: 'none',
      color: 'white',
      borderRadius: '5px',
      padding: '6px 12px',
      maxHeight: '36px',
      ...style,
    };
  }

  return (
    <Link className={className} to={{pathname: to, state: {searchTerm: searchTerm}}} style={finalStyle}>
      {children}
    </Link>
  );
};

export default LinkComponent;
