/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Table from '../../common/ui/Table';
import ShipmentsTableRow from './ShipmentsTableRow';

const tooltipStyles = css`
  padding: 20px;
  color: black;
  border: 1px solid black !important;

  :before {
    border-top: 8px solid black !important;
  }
`;

const ShipmentsTableComponent = ({
  orders,
  invoices,
  reloadComponent,
  reloadComponentState,
  setShowPalletizingModal,
  spStatus,
  company
}) => {
  const contentTooltipByState = () => {
    switch (spStatus) {
      case 'DRAFT':
        return (
          <li>
            To move from ‘Draft’ status to ‘Active’ status, please click on the
            Finalize button once you a ready to proceed with this shipment.
          </li>
        );
      case 'ACTIVE':
        return (
          <li>
            To move from ‘Active’ status to ‘Payment Pending’ status, Fluetis
            will review your shipment request and attach an invoice.
            <br></br>The shipment will automatically move to ‘Payment Pending’
            status. This should take a maximum of 48h.
          </li>
        );
      case 'PAYMENT PENDING':
        return (
          <li>
            To move from ‘Payment Pending’ status to ‘To Collect’ status, you
            will need to process the payment and confirm that the goods{' '}
            <br></br> are ready for collection. Please proceed the payment
            within 48h from invoice as it is how long the quote is valid for.
          </li>
        );

      case 'TO COLLECT':
        return (
          <li>
            To move from ‘To collect’ status to ‘In transit’, Fluetis will
            confirm the collection of the goods.
          </li>
        );

      case 'IN TRANSIT':
        return (
          <li>
            To move from “In Transit” status to ‘Delivered’ status, Fluetis will
            indicate the goods as received. <br></br> The stock will then appear
            in your inventory view.
          </li>
        );

      case 'DELIVERED':
        return <li>Your shipment is Delivered</li>;
      default: 
        return (
          <li>
            To move from ‘Draft’ status to ‘Active’ status, please click on the
            Finalize button once you a ready to proceed with this shipment.
          </li>
        );
    }
  };
  return (
    <Table>
      <thead>
        <tr>
          <th>Id</th>
          <th>     
          </th>
          <th data-tip data-for="paymentInfo">
          <ReactTooltip
              id="paymentInfo"
              effect="solid"
              type="light"
              css={tooltipStyles}
            >
              <ul style={{listStyleType: 'none'}}>{contentTooltipByState()}</ul>
            </ReactTooltip>
            Status*
          </th>
          <th>ETD</th>
          <th>Sales value</th>
          <th>Volume (cbm)</th>
          <th>Pallets</th>
          <th>Packing list</th>
          <th>Palletized for picking</th>
          <th data-tip data-for="invoiceInfo">
            <ReactTooltip
              id="invoiceInfo"
              effect="solid"
              type="light"
              css={tooltipStyles}
            >
              <ul>Awaiting quote: Fluetis team is working on sending you a quote for this shipment within 48h.</ul>
              <ul>Awaiting payment: Fluetis team has sent you a quote by email. Please proceed with the payment.</ul>
            </ReactTooltip>
            Invoice*
          </th>
          <th>Paid</th>
          <th>Collected</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {orders &&
          orders.map((order) => {
            return (
              <ShipmentsTableRow
                key={order.id}
                order={order}
                invoices={invoices}
                reloadComponent={reloadComponent}
                reloadComponentState={reloadComponentState}
                setShowPalletizingModal={setShowPalletizingModal}
                company={company}
              />
            );
          })}
      </tbody>
    </Table>
  );
};

ShipmentsTableComponent.propTypes = {
  orders: PropTypes.array,
  invoices: PropTypes.array,
};

export default ShipmentsTableComponent;
