import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Field from './Field';
import Button from './Button';

const SearchSchema = Yup.object().shape({
  search: Yup.string(),
});

const SearchComponent = (
  {
    collection, setCollection,
    /**
     * Array of objects describing searchable fields and how it must match
     * @example
     * [
     *   {id: 'id', type: 'integer'},
     *   {id: 'name', type: 'text'},
     *   {id: 'brand', type: 'text'},
     *   {id: 'product_type', type: 'text'}
     * ]
     *
     */
    searchableFields,
    allCollection,
    setSearchField,
    searchField,
  }
) => {
  const [searchTerm, setSearchTerm] = useState();

  const searchFilter = (search) => {
    const words = search.split(' ');
    const allElements = collection;
    let lastSearch = [];
    let partialCollection;
    words.map((word, index) => {
      partialCollection = (index === 0 ? allElements : lastSearch).filter((element) => {
        // Check if any of searchable fields match searching term
        let ret = searchableFields.some(field => {
          if (!element[field.id]) {
            return false;
          }
          switch (field.type) {
            case 'text':
              return element[field.id].toLowerCase().includes(word.toLowerCase());
            case 'integer':
              return element[field.id] === parseInt(word);
            default:
              return false;
          }

        });
        return ret
      });
      if(words[0] === ''){
        lastSearch = allCollection;
      }else{
        lastSearch = partialCollection;
      }
      
      return null;
    });
    return lastSearch;
  };

  const handleSubmit = (values) => {
    setSearchTerm(values.search);
    const products = searchFilter(values.search);
    setSearchField(values.search);
    setCollection(products);
  };

  useEffect(() => {
    if (searchField){
      const values={search: searchField};
      handleSubmit(values);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{
        search: searchTerm || searchField || '',
      }}
      enableReinitialize
      validationSchema={SearchSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {() => (
        <Form id="search">
          <Container fluid style={{ margin: '10px 0px', padding: '0px' }}>
            <Row style={{ padding: '0px' }}>
              <Col style={{ padding: '0px' }}>
                <div className="input-group" >
                  <Field name="search" type="text" noCol style={{borderRadius: '5px'}} />
                  <span className="input-group-addon">
                    <Button
                      variant="primary"
                      type="submit"
                      className="float-right"
                      style={{height: '38px', marginLeft: '5px'}}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

SearchComponent.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCollection: PropTypes.func.isRequired,
};

export default SearchComponent;
