import React from 'react';
import Button from '../../common/ui/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Field from '../../common/ui/Field';

const ProductCategoryEditSchema = Yup.object().shape({
  name: Yup.string().required(),
});

/**
 * Form for creating a product category.
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const AddProductCategoryController = (props) => {
  const { handleCreate } = props;

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={ProductCategoryEditSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleCreate(values.name).then((response) => {
          if (response) {
            actions.resetForm();
            actions.setSubmitting(false);
          }
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-group">
            <Field
              label="Category name"
              labelColSize={''}
              name="name"
              type="text"
              placeholder="Name"
              className="inline-field"
              noCol
              errorMessageDanger={true}
            />
          </div>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddProductCategoryController;
