import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

/**
 * Field to choose valid unit of measure
 * @param name Field name
 * @param className Field className attribute
 * @param noCol
 * @returns {JSX.Element}
 * @constructor
 */

 const TemperatureOptions = [
  { value: '23C+', label: '23C+' },
  { value: '18-22C', label: '18-22C' },
  { value: '2-7C', label: '2-7C' },
  { value: '-18C', label: '-18C' },
];

const TemperatureOfStorageSelectorInputComponent = ({
  className,
  onChangeHandler,
  style,
  value
}) => {
  return (
    <Select
      className={`${className}`}
      options={TemperatureOptions}
      onChange={onChangeHandler}
      value={TemperatureOptions.find(to => to.value === value)}
    />
  );
};

TemperatureOfStorageSelectorInputComponent.propTypes = {
  onChangeHandler: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  noCol: PropTypes.number,
};

export default TemperatureOfStorageSelectorInputComponent;
