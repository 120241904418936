import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingBasket,
  faTruckLoading,
  faDollarSign,
  faChartLine,
  faCreditCard,
  faCog,
  faMapMarkedAlt,
  faBullhorn
} from '@fortawesome/free-solid-svg-icons';
import NavLink from './NavLink';

const UpLinks = ({ user, location }) => {
  if (user && user.validated) {
    return (
      <>
        <NavLink
          user={user}
          location={location}
          perform="Dashboard:list"
          path="/"
          Icon={
            <FontAwesomeIcon
              icon={faChartLine}
              style={ styles.font_awesome_icon }
            />
          }
          label="Dashboard"
        />
        <NavLink
          user={user}
          location={location}
          perform="Products:list"
          path="/products"
          Icon={
            <FontAwesomeIcon
              icon={faShoppingBasket}
              style={ styles.font_awesome_icon }
            />
          }
          label="Products"
        />
        <NavLink
          user={user}
          location={location}
          perform="InventoryAndShipments:list"
          path="/inventory_and_shipments"
          Icon={
            <FontAwesomeIcon
              icon={faTruckLoading}
              style={ styles.font_awesome_icon }
            />
          }
          label="Inventory & Shipments"
        />
        <NavLink
          user={user}
          location={location}
          perform="OrdersAndSales:list"
          path="/sales_overview"
          Icon={
            <FontAwesomeIcon
              icon={faCreditCard}
              style={ styles.font_awesome_icon }
            />
          }
          label="Orders & Sales"
        />
        <NavLink
          user={user}
          location={location}
          perform="AccountingAndPayment:list"
          path="/accounting_and_payment"
          Icon={
            <FontAwesomeIcon
              icon={faDollarSign}
              style={ styles.font_awesome_icon }
            />
          }
          label="Accounting & Payment"
        />
        <NavLink
          user={user}
          location={location}
          perform="Delivery:list"
          path="/delivery"
          Icon={
            <FontAwesomeIcon
              icon={faMapMarkedAlt}
              style={ styles.font_awesome_icon }
            />
          }
          label="Delivery"
        />
        <NavLink
          user={user}
          location={location}
          perform="AdminTools"
          path="/admin_tools"
          Icon={
            <FontAwesomeIcon
              icon={faCog}
              style={ styles.font_awesome_icon }
            />
          }
          label="Admin tools"
        />
      </>
    );
  }
  return (
    <div className="navbar-nav">
      <Link
        className={`nav-link ${
          location && location.pathname === '/' ? 'active' : ''
        }`}
        to="/"
      >
        <span className="nav-text">Dashboard</span>
      </Link>
    </div>
  );
};
const styles = {
  font_awesome_icon: { marginRight: '10px', minWidth: '20px', fontSize: "20px" }
}

export default UpLinks;
