import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DocumentWithLinkInput from '../../../common/ui/DocumentWithLinkInput';

const AgreementNewForm = ({ setFieldValue, isSubmitting, values }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <label>{'Document'}</label>
          <br />
          <DocumentWithLinkInput
            defaultValue=""
            onChangeHandler={(file) => {
              /* istanbul ignore next */
              setFieldValue('document', file);
            }}
            label="Document"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AgreementNewForm;
