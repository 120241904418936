import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Tab } from 'react-bootstrap';
import Can from '../roles/Can';
import Overview from './delivery/Overview';
import Tracking from './delivery/Tracking';
import InnerLayout from '../layout/InnerLayout';
import Tabs from '../common/ui/Tabs';

const DeliveryComponent = (props) => {
  const {
    logOut,
    user,
    location,
    company
  } = props;

  if (user) {
    return (
      <Can
        role={user.type.toLowerCase()}
        perform="Delivery:list"
        yes={() => (
          <InnerLayout
            logOut={logOut}
            user={user}
            location={location}
            company={company}
          >
            <Tabs
              defaultActiveKey="overview"
              id="delivery"
            >
              <Tab eventKey="overview" title="Overview">
                <Overview></Overview>
              </Tab>
              <Tab eventKey="tracking" title="Tracking">
                <Tracking></Tracking>
              </Tab>
            </Tabs>
          </InnerLayout>
        )}
        no={() => <Redirect to="/no_permissions" />}
      />
    );
  }
  return null;
};

DeliveryComponent.propTypes = {
  logOut: PropTypes.func.isRequired,
};

export default DeliveryComponent;
