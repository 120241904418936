import { css } from '@emotion/core';
export const wrapperStyles = css`
  overflow-x: hidden;
  &.toggled #sidebar-wrapper {
    margin-left: 0;
  }
  & .nav-link:hover {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
    }
  }
`;

export const contentSuperiorStyles = css`
  background-color: #e6e6e6;
  padding: 0px;
`;

export const contentContainerStyles = css`
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  min-height: 100vh;
  height: auto;
  margin-top: 20px;
`;