import React from 'react';
import { Button } from 'react-bootstrap';

const ButtonComponent = (props) => {
  const { children, onClick, type, variant, style, disabled, id } = props;

  let finalStyle = {};

  switch (variant) {
    default:
    case 'primary':
      finalStyle = {
        ...style,
        backgroundColor: '#04F3B2',
        border: 'none',
        color: 'white',
      };
      break;
    case 'inverse':
      finalStyle = {
        ...style,
        backgroundColor: 'white',
        border: '1px solid #04F3B2',
        color: '#04F3B2',
      };
      break;
    case 'secondary':
      finalStyle = {
        ...style,
        backgroundColor: 'dimgray',
        border: '1px solid #263363',
        color: 'white',
      };
      break;
    case 'danger':
      finalStyle = {
        ...style,
        backgroundColor: 'red',
        border: 'none',
        color: 'white',
      };
      break;
    case 'link':
      finalStyle = {
        backgroundColor: 'transparent',
        color: 'blue',
        border: 'none',
        ...style,
      };
      break;
    case 'icon':
      finalStyle = {
        backgroundColor: 'transparent',
        color: '#212529',
        border: 'none',
        ...style,
      };
      break
  }

  return (
    <Button
      type={type}
      style={finalStyle}
      disabled={disabled}
      onClick={type !== 'submit' ? (params) => onClick(params) : () => {}}
      id={id}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
