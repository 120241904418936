import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from './WharehouseForm';
import ProductsService from '../../../services/data/products_service';
import { createNotification } from '../../common/notifications';

const WharehousesSchema = Yup.object().shape({
  wharehouses: Yup.string(),
});

const WharehousesComponent = ({ wharehouses, product, readonly }) => {
  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    ProductsService.updateProduct(product.id, {
      address_metadata_id: values.wharehouse.value,
    })
      .then((response) => {
        createNotification('success', 'You have updated the Warehouse');
        actions.setSubmitting(false);
      })
      .catch((error) => {
        createNotification('error', 'The Warehouse update cannot be done');
        actions.setSubmitting(false);
      });
  };

  if (wharehouses && wharehouses.length > 0) {
    return (
      <Formik
        initialValues={{
          wharehouse:
            product &&
            product &&
            product.address_metadata_id &&
            wharehouses
              .filter((wh) => wh.id === product.address_metadata_id)
              .map((wh2) => {
                return { label: wh2.label, value: wh2.id };
              })[0],
        }}
        enableReinitialize
        validationSchema={WharehousesSchema}
        onSubmit={(values, actions) => {
          /* istanbul ignore next */
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            wharehouses={wharehouses}
            readonly={readonly}
            values={values}
          />
        )}
      </Formik>
    );
  }
  return (
    <>
      <div style={{marginLeft: '2px'}}>No warehouses. Please create a new one.</div>
      <p style={{marginLeft: '2px'}}>Please create a new one in Profile / My Addresses</p>
    </>
  );
};

export default WharehousesComponent;
