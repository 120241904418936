import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompaniesService from '../../../services/data/companies_service';
import Search from '../../common/ui/Search';
import AgreementTable from './AgreementTable';

const Agreements = () => {
  const [companies, setCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  /* istanbul ignore next */
  const setSearchField = (term) => {
    setSearchTerm(term);
  }

  const loadAgreements = () => {
    CompaniesService.loadCompanies().then((response) => {
      const companies = response.data.companies
        .filter((company) => company.company_type === 'supplier')
        .map((company) => {
          return {
            id: company.id,
            name: company.name,
            agreement: company.agreement,
          };
        });
      setCompanies(companies);
      setAllCompanies(companies);
    });
  };

  useEffect(() => {
    loadAgreements();
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Search
              collection={companies}
              setCollection={setCompanies}
              searchableFields={[{ id: 'name', type: 'text' }]}
              allCollection={allCompanies}
              setSearchField={setSearchField}
              searchField={searchTerm}
            />
          </Col>
        </Row>
      </Container>
      <AgreementTable collection={companies} loadAgreements={loadAgreements} />
    </>
  );
};

export default Agreements;
