import axios from '../axios';

class LocationService {
	static loadLocations = () => axios.get('/locations');
	static createLocation = (data) => axios.post('/locations', {
		location: {
			address: data.address,
			country_code: data.country_code,
			label: data.label,
			name: data.name
		}
	});
}

export default LocationService;
