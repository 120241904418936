import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from './MyAccountingForm';
import CompaniesService from '../../../services/data/companies_service';
import { createNotification, delayedRedirect } from '../../common/notifications';

const AccountingSchema = Yup.object().shape({
  beneficiaryName: Yup.string().nullable(),
  bank: Yup.string().nullable(),
  accountNumber: Yup.string().max(20).nullable(),
  bankCode: Yup.string().nullable(),
  iban: Yup.string().max(24).nullable(),
  branchCode: Yup.string().nullable(),
  addressLine1: Yup.string().nullable(),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  country: Yup.string().nullable(),
  postalCode: Yup.string().nullable(),
  sortCode: Yup.string().nullable(),
  currency: Yup.string(),
});

const MyAccountingComponent = ({company}) => {
  /* istanbul ignore next */
  const handleAccountingSubmit = (values, actions) => {
    CompaniesService.updateCompany(company.id, values, true)
    .then(() => {
      createNotification('success', 'You have updated the accounting details');
      delayedRedirect('/users/edit');
      actions.setSubmitting(false);
    })
    .catch(() => {
      createNotification('error', 'Error updating accounting details');
      actions.setSubmitting(false);
    });
  };
  return (
    <Formik
      initialValues={{
        beneficiaryName: company?.beneficiary_name,
        bank: company?.bank,
        accountNumber: company?.account_number,
        bankCode: company?.bank_code,
        iban: company?.iban,
        branchCode: company?.branch_code,
        addressLine1: company?.address_line_1,
        addressLine2: company?.address_line_2,
        city: company?.city,
        state: company?.state,
        country: company?.country,
        postalCode: company?.postal_code,
        sortCode: company?.sort_code,
        currency: company?.preferred_currency,
      }}
      enableReinitialize
      validationSchema={AccountingSchema}
      onSubmit={(values, actions) => handleAccountingSubmit(values, actions)}
    >
      {({ isSubmitting, values, setFieldValue, submitForm }) => (
        <Form
          isSubmitting={isSubmitting}
          values={values}
          setFieldValue={setFieldValue}
          handleSubmit={submitForm}
        />
      )}
    </Formik>
  );
};
export default MyAccountingComponent;
