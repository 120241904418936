import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '../../common/ui/Button';
import { formattedDate } from '../../common/dataTypes/dates';
import { formattedPriceWithoutCurrency } from '../../common/dataTypes/prices';

const ShipmentsItemListTableComponent = ({
  itemList,
  products,
  deleteFromItemList,
  readonly,
  company
}) => {
  /* istanbul ignore next */
  const getName = (id) => {
    if (id && products && products.length > 0) {
      const elem = products.filter((prod) => prod.id === id)[0];
      return elem.name;
    }
  };

  /* istanbul ignore next */
  const handleDelete = (index) => {
    deleteFromItemList(index);
  };

  return (
    <Table
      striped
      bordered
      hover
      style={{ marginBottom: '90px', marginTop: '20px' }}
      id="shipment-list-table"
    >
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Expiry Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {itemList.map((item, index) => {
          return (
            <tr>
              <td>{item.productId}</td>
              <td>{getName(item.productId)}</td>
              <td>{item.quantity}</td>
              <td>{formattedPriceWithoutCurrency(item.price)}</td>
              <td>{formattedDate(item.expiration_date)}</td>
              <td>
                {!readonly && (
                  <Button
                    variant="link"
                    onClick={(_) => handleDelete(index)}
                    style={{ padding: '0px' }}
                  >
                    <FontAwesomeIcon
                      className="deleteAction"
                      icon={faTrashAlt}
                    />
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ShipmentsItemListTableComponent;
