import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const Actions = (props) => {
  const { messages } = props
  const [visibleActions] = useState(true);

  const [actions] = useState([
    {
      type: 'primary',
      message: (
        <>
          Click <a href="https://1drv.ms/b/s!AtKppvslEqieoyFELElfdajj2jHX?e=jfW38U">here</a> to learn how to follow the steps to
          activate your account.
        </>
      ),
    },
    {
      type: 'primary',
      message: (
        <>
          Start creating products.{' '}
          <Link to="/products/new"> Create products here.</Link>
        </>
      ),
    },
  ]);

  const displayMessages = () =>{
    if (messages){
      return (
        messages.map((message) => (
          <Alert variant={"primary"}>{ ReactHtmlParser (message.body) }</Alert>
      )));
    }
  }

  if (visibleActions) {
    return (
      <div
        style={{
          maxHeight: 220,
          overflowY: 'auto',
          marginBottom: '.75rem',
          border: '2px solid rgba(0,0,0,.125)',
          borderRadius: '5px',
          padding: '.75rem',
          paddingBottom: 0,
        }}
      >
        {displayMessages()}
      </div>
    );
  }
  return null;
};

export default Actions;
