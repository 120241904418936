import axios from 'axios';
import StorageService from './storage_service';
import { createNotification, delayedRedirect } from '../components/common/notifications';

export const host = process.env.REACT_APP_BACKEND_API;

const csrfToken = document.querySelector('[name="csrf-token"]') || {
  content: 'no-csrf-token',
};
const bearer = StorageService.getBearer();

const instance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken.content,
    'Access-Control-Allow-Origin': host,
    Authorization: `Bearer ${bearer}`,
  },
  baseURL: host,
});

instance.defaults.headers.post['Content-Type'] =
  'application/json; charset=utf-8';

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const publicUrls = [
      '/users/sign_in',
      '/confirmation?confirmation_token=',
      '/users/password/new',
      '/join_as_a_seller',
      '/users/confirmation/new',
      '/password/edit?reset_password_token=',
    ];
    if (publicUrls.find((url) => window.location.href.includes(url)) === undefined) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 404) {
        createNotification(
          'warning',
          'The requested resource does not exist or has been deleted'
        );
        delayedRedirect('/no_permissions');
      } else if (statusCode === 401) {
        StorageService.clearBearer();
        StorageService.clearUserData();
        createNotification(
          'warning',
          'The requested resource is not authorized'
        );
        delayedRedirect('/users/sign_in');
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
