import React from 'react';
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import OuterField from '../common/ui/OuterField';
import FlatButton from '../common/ui/FlatButton';
import ContactMessagesService from '../../services/data/contact_messages_service';
import { createNotification } from '../common/notifications';

const ContactNewSchema = Yup.object().shape({
  subject: Yup.string(),
  body: Yup.string(),
});

const StyledForm = styled(Form)`
  text-align: center;
`;

const StyledContainer = styled(Container)`
  padding-top: 5%;
`;

const ContactComponent = () => {
  /* istanbul ignore next */
  const handleCreate = (values, actions) => {
    ContactMessagesService.createContactMessage(values)
      .then((response) => {
        createNotification('success', 'You will receive a reply soon');
      })
      .catch((error) => {
        createNotification('danger', 'Error creating the message');
      });
  };

  return (
    <Formik
      initialValues={{
        subject: '',
        body: '',
      }}
      validationSchema={ContactNewSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleCreate(values, actions);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <StyledForm id="contactNew">
          <StyledContainer fluid>
            <Row>
              <Col lg="12">
                <OuterField
                  name="subject"
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  label="Subject"
                  setFieldValue={setFieldValue}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <OuterField
                  name="body"
                  type="text"
                  as="textarea"
                  className="form-control"
                  placeholder="Message"
                  label="Body"
                  setFieldValue={setFieldValue}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FlatButton
                  variant="primary"
                  type="submit"
                  style={{ marginTop: '15px' }}
                >
                  Submit
                </FlatButton>
              </Col>
            </Row>
          </StyledContainer>
        </StyledForm>
      )}
    </Formik>
  );
};

export default ContactComponent;
