import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../../common/ui/Field';

const CertificationNewFormComponent = ({ setFieldValue }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="name"
              label="Name"
              type="text"
              placeholder="Name"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="start_date"
              type="date"
              label="Start date"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="end_date"
              type="date"
              label="End date"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <label
              className="form-field-label col-sm-3"
              style={{ marginTop: '8px' }}
            >
              {'Document'}
            </label>
            <input
              name="document"
              type="file"
              accept="application/pdf"
              className="form-control col-sm-9"
              style={{ border: 'none', paddingLeft: '0px' }}
              onChange={(event) => {
                /* istanbul ignore next */
                setFieldValue('document', event.currentTarget.files[0]);
              }}
            />
            <ErrorMessage component="small" name="document" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

CertificationNewFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  closeAddCertificationModal: PropTypes.func,
};

export default CertificationNewFormComponent;
