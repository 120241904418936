import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { formattedPrice } from '../common/dataTypes/prices';
import Badge from '../common/ui/Badge';
import Button from '../common/ui/Button';
import Link from '../common/ui/Link';
import './Products.css';

const ProductComponent = (props) => {
  const { product, deleteProduct, priceLists, searchTerm } = props;

  const getPriceFromPriceListsIndex = () => {
    let priceListsIds = []
    priceLists.map(pl => priceListsIds.push(pl.id.toString()));

    let onlyPrices = [];
    Object.keys(product.variant_data.prices).filter(p => priceListsIds.includes(p)).map(pl => onlyPrices.push(pl));
    return onlyPrices.find(p => product.variant_data.prices[p] !== "0.0000")
  }

  const getPrice = () => {
    const id = getPriceFromPriceListsIndex();
    return product.variant_data.prices[id];
  }

  const getCurrency = () => {
    const property = parseInt(getPriceFromPriceListsIndex());

    if(property !== -1){
      return priceLists.find(pl => pl.id === property)?.currency_iso.toLowerCase()
    }
  }

  return (
    <tr className="products-table-row" id={`product-${product.id}`}>
      <td>{product.seller_sku_code}</td>
      <td>
        {
          <Link
            variant=""
            to={`/products/${product.id}`}
            searchTerm={searchTerm}
          >
            {product.fluetis_sku_code}
          </Link>
        }
      </td>
      <td>{product.brand}</td>
      <td>{product.name}</td>
      <td>{product.upc}</td>
      <td>{product.images_count}</td>
      <td>
        <Link
          variant=""
          to={`/products/${product.id}`}
          style={{ marginRight: '5px', padding: '9px 12px' }}
          searchTerm={searchTerm}
        >
          <FontAwesomeIcon className="editAction" icon={faPencilAlt} />
        </Link>
        <Button className="deleteActionButton" variant="link" onClick={() => deleteProduct(product)}>
          <FontAwesomeIcon className="deleteAction" icon={faTrashAlt} />
        </Button>
      </td>
    </tr>
  );
};

ProductComponent.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    seller_sku_code: PropTypes.string.isRequired,
    product_type: PropTypes.string.isRequired,
    buy_price: PropTypes.string.isRequired,
    variant_data: PropTypes.shape({
      stock_on_hand: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  deleteProduct: PropTypes.func.isRequired,
};

export default ProductComponent;
