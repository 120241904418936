import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import Field from '../../common/ui/NormalField';
import Select from '../../common/ui/Select';
import Button from '../../common/ui/Button';

const ProductAddSchema = Yup.object().shape({
  id: Yup.string(),
  productId: Yup.number(),
  productName: Yup.string(),
  quantity: Yup.number(),
  price: Yup.number(),
  expiration_date: Yup.date(),
});

const ShipmentsItemListFormComponent = ({
  products,
  handleProductAdd,
  toggleVisibilityCreateNewProduct,
}) => {
  /* istanbul ignore next */
  const handleIdChange = (event, setFieldValue) => {
    setFieldValue('id', event.target.value);
    const elem = products.filter((elem) => {
      return elem.id === parseInt(event.target.value);
    })[0];
    if (elem) {
      setFieldValue('productId', parseInt(event.target.value));
    }
  };
  /* istanbul ignore next */
  const productSelectValues =
    products &&
    products.map((product) => {
      return { value: product.id, label: product.name };
    });
  return (
    <Formik
      initialValues={{
        id: '',
        productId: products && products[0].id,
        productName: products && products[0].name,
        quantity: 0,
        price: 0,
        expiration_date: undefined,
      }}
      validationSchema={ProductAddSchema}
      onSubmit={(values, actions) => {
        if(typeof values.quantity === "string"){
          /* istanbul ignore next */
          values.quantity = parseFloat(values.quantity).toFixed(1);
          /* istanbul ignore next */
          values.price = parseFloat(values.price).toFixed(2);
        }
        else{
           /* istanbul ignore next */
          values.quantity = values.quantity.toFixed(1);
          /* istanbul ignore next */
          values.price = values.price.toFixed(2);
        }
        
        /* istanbul ignore next */
        handleProductAdd([values], actions);
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form inline id="productAdd">
            <Row>
              <Col md="4" lg="2">
                <div className="form-group">
                  <Field
                    name="id"
                    type="text"
                    placeholder=""
                    onChange={(event) => handleIdChange(event, setFieldValue)}
                    label="Item Id"
                    labelStyle={{
                      display: 'block',
                      width: '100%',
                      float: 'none',
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
              <Col md="4" lg="2">
                <div className="form-group">
                  <label className="col-sm-3">Name</label>
                  <Select
                    options={productSelectValues}
                    onChange={(psv) => {
                      /* istanbul ignore next */
                      setFieldValue('productId', psv.value);
                      setFieldValue('productName', psv.label);
                    }}
                    className="col-sm-12 no-padding"
                  />
                </div>
              </Col>
              <Col md="4" lg="2">
                <div className="form-group">
                  <Field
                    name="quantity"
                    type="number"
                    placeholder=""
                    label="Quantity"
                    labelStyle={{
                      display: 'block',
                      width: '100%',
                      float: 'none',
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
              <Col md="4" lg="2">
                <div className="form-group">
                  <Field
                    name="price"
                    type="number"
                    placeholder="0"
                    label="Price"
                    labelStyle={{
                      display: 'block',
                      width: '100%',
                      float: 'none',
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
              <Col md="4" lg="2">
                <div className="form-group">
                  <Field
                    name="expiration_date"
                    type="date"
                    placeholder="expiration_date"
                    label="Expiry Date"
                    labelStyle={{
                      display: 'block',
                      width: '100%',
                      float: 'none',
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
              <Col md="4" lg="2">
                <div className="form-group" style={{ textAlign: 'center' }}>
                  <label>Actions</label>
                  <br />
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ marginRight: '10px' }}
                    className=""
                  >
                    Add
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className=""
                    onClick={(_) => toggleVisibilityCreateNewProduct()}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ShipmentsItemListFormComponent;
