import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../../common/ui/Field';
import DocumentWithLinkInput from '../../../common/ui/DocumentWithLinkInput';

const CertificationEditFormComponent = ({
  values,
  certification,
  setFieldValue,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="name"
              label="Name"
              type="text"
              placeholder="Name"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="start_date"
              type="date"
              label="Start date"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group row">
            <Field
              name="end_date"
              type="date"
              label="End date"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>{'Document'}</label>
          <br />
          <DocumentWithLinkInput
            defaultValue={certification.document_url}
            onChangeHandler={(file) => {
              /* istanbul ignore next */
              setFieldValue('document', file);
            }}
            label={certification.document_file_name}
          />
        </Col>
      </Row>
    </Container>
  );
};

CertificationEditFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  closeEditCertificationModal: PropTypes.func,
  certification: PropTypes.object,
};

export default CertificationEditFormComponent;
