import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import InvoicesService from '../../../services/data/invoices_service';
import { createNotification } from '../../common/notifications';
import Modal from '../../common/ui/Modal';
import Form from './InvoiceForm';
import { convertToInputFormat } from '../../common/dataTypes/dates';

const InvoiceEditSchema = Yup.object().shape({
    order_number: Yup.string().required(),
    company: Yup.object().required(),
    invoiced_at: Yup.date().required(),
    due_at: Yup.date().required(),
    payment_status: Yup.object().required(),
    total: Yup.number().required(),
    document_url: Yup.string().required(),
});

const InvoiceEditComponent = (props) => {
    const [invoice, setInvoice] = useState([]);
    const {
        invoiceId,
        closeEditInvoiceModal,
        loadInvoices,
        showEditInvoice,
        companies,
    } = props;

    /* istanbul ignore next */
    const handleEditSubmit = (values, actions) => {
        InvoicesService.updateInvoice(invoiceId, values)
          .then((response) => {
            createNotification('success', 'You have updated the invoice');
            closeEditInvoiceModal();
            loadInvoices();
          })
          .catch((error) => {
            console.log(error);
            createNotification('danger', 'Error updating the invoice');
          });
    };

    /* istanbul ignore next */
    useEffect(() => {
        if (invoiceId){
            InvoicesService.loadInvoice(invoiceId)
                .then((response) => {
                    setInvoice(response.data.invoice);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [invoiceId]);

    return (
        <Formik
            initialValues={{
                order_number: invoice && invoice.order_number,
                company: invoice && {
                    value: invoice.platform_company_id,
                    label: invoice.company_name,
                },
                invoiced_at: invoice && convertToInputFormat(new Date(invoice.invoiced_at)),
                due_at: invoice && convertToInputFormat(new Date(invoice.due_at)),
                payment_status: invoice && {
                    value: invoice.payment_status,
                    label: invoice.payment_status ? invoice.payment_status : "None",
                },
                total: invoice && invoice.total,
                document_url: invoice && invoice.document_url,
            }}
            validationSchema={InvoiceEditSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                handleEditSubmit(values, actions);
            }}
            >
            {({ isSubmitting, setFieldValue, values }) => (
                <Modal
                    onHide={closeEditInvoiceModal}
                    onCancel={closeEditInvoiceModal}
                    title="Invoice Edit"
                    show={showEditInvoice}
                    >
                    <Form
                        id="invoiceEdit"
                        setFieldValue={setFieldValue}
                        invoice={values}
                        companies={companies}
                        edit={true}
                    />
                </Modal>
            )}
        </Formik>
    );
    
};

export default InvoiceEditComponent;

