import React from 'react';
import { Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Select from '../../common/ui/Select';
import Button from '../../common/ui/Button';

const WharehouseComponent = ({
  setFieldValue,
  isSubmitting,
  wharehouses,
  readonly,
  values,
}) => {
  return (
    <Container fluid>
      <Form id="wharehousesForm" style={{'padding-bottom': "100px"}}>
        <label><strong>Please choose the warehouse where te product is coming from</strong></label>
        <Row>
          <Col md="3">
            <Select
              options={wharehouses.map((wh) => {
                return { label: wh.label, value: wh.id };
              })}
              onChange={(wh) => {
                setFieldValue('wharehouse', { label: wh.label, value: wh.value });
              }}
              value={values.wharehouse}
              disabled={readonly}
            />
          </Col>
          { !readonly && (
            <Col md="3">
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ textAlign: 'left' }}
              >
                Submit
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default WharehouseComponent;
