
import React from 'react';
import Badge from '../../common/ui/Badge';
import { formattedPrice } from '../../common/dataTypes/prices';
import { formattedDate } from '../../common/dataTypes/dates';

const InvoiceComponent = (props) => {
  const { invoice } = props;

  return(
    <tr>
        <td>{invoice.order_number}</td>
        <td>{invoice.reference}</td>
        <td>{formattedDate(invoice.invoiced_at)}</td>
        <td>{formattedDate(invoice.due_at)}</td>
        <td>
            {
              invoice.payment_status ? <Badge content={invoice.payment_status} /> : "None"
            }
        </td>
        <td>{formattedPrice(invoice.total, 'sgd')}</td>
        <td><a href={invoice.document_url} target="_blank" rel="noopener noreferrer">Document</a></td>
    </tr>
  );
};

export default InvoiceComponent;
