export const formattedPrice = (value, preferredCurrency) => {
  if (value && value !== null) {
    return `${getCurrency(preferredCurrency)} ${parseFloat(value).toFixed(2)}`;
  }
  return `${getCurrency(preferredCurrency)} ${0}`;
};

export const formattedPriceWithoutCurrency = (value) => {
  if (value && value !== null) {
    return `${parseFloat(value).toFixed(2)}`;
  }
  return `${0}`;
};

export const getCurrency = (preferredCurrency) => {
  if (preferredCurrency) {
    return preferredCurrency?.toUpperCase();
  }
  return 'EUR';
  /*
  switch (preferredCurrency) {
    case 'sgd':
      return 'S$';
    case 'usd':
      return '$';
    case 'gbp':
      return '£';
    case 'eur':
      return '€';
    default:
      return '$';
  }*/
};
