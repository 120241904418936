export const formattedDate = (date) => {
  if (date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dat = new Date(date);
    return `${('0' + dat.getDate()).slice(-2)} ${
      monthNames[dat.getMonth()]
    } ${dat.getFullYear()}`;
  }
  return ' ';
};

export const addWeeks = (dt, n) => {
  return new Date(dt.setDate(dt.getDate() + n * 7));
};

export const convertToInputFormat = (dt) => {
  const date = ('0' + dt.getDate()).slice(-2);
  const month = ('0' + (dt.getMonth() + 1)).slice(-2);
  const year = dt.getFullYear();
  return year + '-' + month + '-' + date;
};
