import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../common/ui/Modal';
import Form from './CompanyAgreementEditForm';
import CompanyAgreementsService from '../../../services/data/company_agreements_service';
import { createNotification } from '../../common/notifications';

const CompanyAgreementEditSchema = Yup.object().shape({
    document: Yup.string(),
});

const CompanyAgreementEdit = ({
    showEditCompanyAgreement,
    company_agreement,
    closeEditCompanyAgreementModal,
    loadCompanyAgreements,
}) => {
    const handleUpdateSubmit = (values, actions) => {
        CompanyAgreementsService.updateCompanyAgreement(company_agreement.id, values)
            .then((response) => {
                actions.setSubmitting(false);
                createNotification('success', 'Has been created', 'The company agreement');
                loadCompanyAgreements();
                closeEditCompanyAgreementModal();
            })
            .catch((error) => {
                actions.setSubmitting(false);
                createNotification(
                    'error',
                    'was not possible to create',
                    'The company agreement'
                );
                closeEditCompanyAgreementModal();
            });
    };

    return (
        <Formik
            initialValues={{
                name: company_agreement.name,
                document: '',
            }}
            validationSchema={CompanyAgreementEditSchema}
            onSubmit={(values, actions) => {
                handleUpdateSubmit(values, actions);
            }}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Modal
                    onHide={closeEditCompanyAgreementModal}
                    onCancel={closeEditCompanyAgreementModal}
                    title="Edit Company Agreement"
                    show={showEditCompanyAgreement}
                >
                    <Form
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        values={values}
                        company_agreement={company_agreement}
                    />
                </Modal>
            )}
        </Formik>
    );
};

export default CompanyAgreementEdit;