import React, { useState } from 'react';
import Table from '../../common/ui/Table';
import Button from '../../common/ui/Button';
import Certification from './Certification';
import CertificationNew from './certifications/CertificationNew';

const CertificationsComponent = ({
  certifications,
  product,
  loadCertifications,
  readonly
}) => {
  const [showCreateCertification, setShowCreateCertification] = useState(false);

  /* istanbul ignore next */
  const closeAddCertificationModal = () => {
    setShowCreateCertification(false);
  };

  /* istanbul ignore next */
  const showAddCertificationModal = () => {
    setShowCreateCertification(true);
  };

  const renderContent = () => {
    if (certifications && certifications.length > 0) {
      return (
        <>
          <Table id="certificationsTable" style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>Certification name</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Document upload</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {certifications.map((certification) => (
                <Certification
                  key={certification['id']}
                  certification={certification}
                  product={product}
                  loadCertifications={loadCertifications}
                />
              ))}
            </tbody>
          </Table>
        </>
      );
    } else {
      return <div id="noCertifications">No certifications. Please upload some.</div>;
    }
  };

  return (
    <>
    {!readonly && (
      <Button variant="primary" onClick={(_) => showAddCertificationModal()} style={{marginBottom: '10px'}}>
        Create certification
      </Button>
    )}
      {renderContent()}
      <CertificationNew
        showCreateCertification={showCreateCertification}
        productId={product && product.id}
        closeAddCertificationModal={closeAddCertificationModal}
        loadCertifications={loadCertifications}
      />
    </>
  );
};

export default CertificationsComponent;
