import axios from '../axios';
import BaseService from './base_service';

class ProductService extends BaseService {
  static loadProducts() {
    return axios.get('/products');
  }

  static getProductsXLSX(template = false) {
    return axios.request({
      url: '/products.xlsx?template=' + template,
      method: 'GET',
      responseType: 'blob',
    });
  }

  /**
   * Request product's inventory in CSV format (for phase 1).
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getInventoryCSV() {
    return axios.request({
      url: '/inventories',
      method: 'GET',
      responseType: 'blob',
    });
  }

  static loadProductsWithoutVariants() {
    return axios.get('/products?plain="true"');
  }

  static createProduct(product) {
    return axios.post('/products', this._formDataFromProductObject(product), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static createBulkProduct(file) {
    const formData = new FormData();
    if (file) {
      formData.append('product[products_file]', file);
    }
    return axios.post('/products', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static loadProduct(id) {
    return axios.get(`/products/${id}`);
  }

  static updateProduct(id, product, update_images = false) {
    return axios.patch(
      `/products/${id}`,
      this._formDataFromProductObject(product, update_images),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  /**
   * @private
   * Get a FormData object from a product to be sent to backed.
   * @param product Object with product information
   * @returns {FormData}
   * @see ProductEditSchema
   */
  static _formDataFromProductObject(product, update_images = false) {
    const formData = new FormData();
    const standard_fields = [
      {
        object_id: 'description',
        data_id: 'platform_description',
      },
      'brand',
      {
        object_id: 'productType',
        data_id: 'product_type',
      },
      {
        object_id: 'hsCode',
        data_id: 'hs_code',
      },
      {
        object_id: 'sellerSkuCode',
        data_id: 'seller_sku_code',
      },
      {
        object_id: 'fluetisSkuCode',
        data_id: 'fluetis_sku_code',
      },
      {
        object_id: 'country',
        data_id: 'country_of_origin',
      },
      'upc',
      {
        object_id: 'country',
        data_id: 'country_of_origin',
      },
      'units_of_measure',
      'measure_value',
      {
        object_id: 'quantity_per_UOM',
        data_id: 'quantity_per_uom',
      },
      {
        object_id: 'platformName',
        data_id: 'platform_name',
      },
      {
        object_id: 'temperatureOfStorage',
        data_id: 'temperature_of_storage',
      },
      {
        object_id: 'manufactureShelfLife',
        data_id: 'manufacture_shelf_life',
      },
      {
        object_id: 'exportShelfLife',
        data_id: 'export_shelf_life',
      },
      {
        object_id: 'singleQuantity',
        data_id: 'single_quantity',
      },
      {
        object_id: 'singleFormat',
        data_id: 'single_format',
      },
      {
        object_id: 'iumWeight',
        data_id: 'ium_weight',
      },
      {
        object_id: 'packQuantity',
        data_id: 'pack_quantity',
      },
      {
        object_id: 'packBarcode',
        data_id: 'pack_barcode',
      },
      {
        object_id: 'packFormat',
        data_id: 'pack_format',
      },
      {
        object_id: 'packWeight',
        data_id: 'pack_weight',
      },
      {
        object_id: 'cartonQuantity',
        data_id: 'carton_quantity',
      },
      {
        object_id: 'cartonBarcode',
        data_id: 'carton_barcode',
      },
      {
        object_id: 'cartonFormat',
        data_id: 'carton_format',
      },
      {
        object_id: 'piumWeight',
        data_id:   'pium_weight',
      },
      'ingredients',
      'about_the_brand',
      'allergens_warning',
      'highlight1',
      'highlight2',
      'highlight3',
      'how_to_use',
      'address_metadata_id',
      'category_ids',
      'prices',
      'price_value_fluetisrsp',
      'price_value_brrp',
      'price_value_eur',
      'price_value_usd',
      'price_value_gbp',
      'price_value_inr',
      'price_value_aud',
      'price_value_sgd',
      'cost',
      'cost_per_cartons_sale',
      'currency'
    ];

    standard_fields.forEach((field) => {
      const f =
        typeof field !== 'string'
          ? field
          : {
              object_id: field,
              data_id: field,
            };

      if (product[f.object_id] !== undefined) {
        this._paramToFormData(
          formData,
          `product[${f.data_id}]`,
          product[f.object_id]
        );
      }
    });


    if (product.name_creation){
      let name = product.brand + " " + product.name_creation + " - " + product.measure_value + " " + product.units_of_measure.toUpperCase();
      formData.append('product[name]', name);
      formData.append('product[platform_name]', product.name_creation);
    }else{
      if (product.platformName){
        product.name = product.brand + " " + product.platformName + " - " + product.measure_value + " " + product.units_of_measure.toUpperCase()
        formData.append('product[name]', product.name);
      } 
    }
    
    if (update_images) {
      if (product.image) {
        formData.append('product[images][][image]', product.image);
        formData.append('product[images][][default]', true);
        formData.append('product[images][][image_id]', '');
      }
      if (product.images && product.images.length > 0 ) {
        product.images.map((image, index) => {
          image.url_image_local ? formData.append('product[images][][image]', image.image) : formData.append('product[images][][image]', image);
          formData.append('product[images][][default]', false);
          formData.append('product[images][][image_id]', image.id || '');
          formData.append('product[images][][position]', index);
          return null;
        });
      }else{
        formData.append('product[images][]', []);
      }
    }

    if (product.category_ids) {
      if (product.category_ids.length > 0) {
        product.category_ids.map((cat_id) => {
          formData.append('product[category_ids][]', cat_id);
          return null;
        });
      } else {
        formData.append('product[category_ids]', '[]');
      }
    }

    return formData;
  }

  static markAsDefault(productId, position, images) {
    const formData = new FormData();
    images.map((image, pos) => {
      if (pos === position) {
        formData.append('product[images][][default]', true);
      } else {
        formData.append('product[images][][default]', false);
      }
      formData.append('product[images][][image]', image.image);
      formData.append('product[images][][image_id]', image.id);
      return null;
    });

    return axios.patch(`/products/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static stockAdjustment(productId, quantity) {
    return axios.post(`/products/${productId}/stock_adjustment`, {
      stock_adjustment: { quantity },
    });
  }

  /**
   * Request to backed for deleting a product
   * @param id Product ID
   * @returns {Promise<AxiosResponse<any>>}
   */
  static deleteProduct(id) {
    return axios.delete(`/products/${id}`);
  }
}

export default ProductService;
