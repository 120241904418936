import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ProductService from '../../../services/data/products_service';
import LocationsService from '../../../services/data/locations_service';
import AddressesService from '../../../services/data/address_service';
import ShipmentsService from '../../../services/data/shipments_service';
import { formattedPrice } from '../../common/dataTypes/prices';
import {
  createNotification,
  delayedRedirect,
} from '../../common/notifications';
import InnerLayout from '../../layout/InnerLayout';
import Form from './ShipmentsNewForm';

const ShipmentsNewSchema = Yup.object().shape({
  pickingLocation: Yup.object(),
  deliveryLocation: Yup.object(),
  orderDate: Yup.date(),
  pickupDate: Yup.date(),
  deliveryDate: Yup.date(),
  item_data: Yup.array().of(
    Yup.object()
      .shape({
        productId: Yup.number(),
        quantity: Yup.number(),
        price: Yup.number(),
        expiration_date: Yup.date(),
      })
      .required('Required')
  ),
  cost: Yup.string(),
});

const ShipmentsNewComponent = ({
  user,
  location,
  company,
  products,
  loadProducts,
  logOut,
}) => {
  const [, setLoadingProducts] = useState(true);
  const [locations, setLocations] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [waitingForShipmentCreation, setWaitingForShipmentCreation] = useState(
    false
  );

  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    setWaitingForShipmentCreation(true);
    if (values.item_data.length > 0) {
      ShipmentsService.createShipment(values)
        .then((response) => {
          setWaitingForShipmentCreation(false);
          createNotification('success', 'Shipment created');
          delayedRedirect('/inventory_and_shipments#shipments');
        })
        .catch((error) => {
          setWaitingForShipmentCreation(false);
          createNotification('error', 'Shipment cannot be created');
          delayedRedirect('/inventory_and_shipments#shipments');
        });
    } else {
      createNotification('warning', 'Please add items to your shipment');
      actions.setSubmitting(false);
      setWaitingForShipmentCreation(false);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (products && products.length === 0) {
      ProductService.loadProducts().then((response) => {
        loadProducts(response.data.products, false);
        setLoadingProducts(false);
      });
    } else {
      setLoadingProducts(false);
    }

    LocationsService.loadLocations().then((response) => {
      setLocations(response.data.locations);
    });

    AddressesService.loadAddresses().then((response) => {
      setAddresses(response.data.addresses);
    });
    // eslint-disable-next-line
  }, [loadProducts, products]);

  return (
    <InnerLayout
      logOut={logOut}
      user={user}
      location={location}
      company={company}
    >
      <Formik
        initialValues={{
          pickingLocation: addresses &&
            addresses[0] && {
              value: addresses[0].id,
              label: addresses[0].label,
            },
          deliveryLocation: locations &&
            locations[0] && {
              value: locations[0].id,
              label: locations[0].label,
            },
          orderDate: '',
          pickupDate: '',
          deliveryDate: '',
          actualPickupDate: '',
          actualDeliveryDate: '',
          item_data: [],
          cost: formattedPrice(0, company?.preferred_currency),
        }}
        enableReinitialize={true}
        validationSchema={ShipmentsNewSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            addresses={addresses}
            locations={locations}
            products={products}
            company={company}
            waitingForShipmentCreation={waitingForShipmentCreation}
            values={values}
          />
        )}
      </Formik>
    </InnerLayout>
  );
};

export default ShipmentsNewComponent;
