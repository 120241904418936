import axios from '../axios';

class DimensionsService {
    static createDimension = (dimension, productId) => axios.patch(`/products/${productId}`, {
        product: {
            ium_length: dimension.ium_length,
            ium_width: dimension.ium_width,
            ium_height: dimension.ium_height,
            ium_weight: dimension.ium_weight,
            pium_length: dimension.pium_length,
            pium_width: dimension.pium_width,
            pium_height: dimension.pium_height,
            pium_weight: dimension.pium_weight,
            ium_per_pium: dimension.ium_per_pium,
            pium_per_layer: dimension.pium_per_layer,
            layer_per_pallet: dimension.layer_per_pallet,
            pallet_volume: dimension.pallet_volume,
            pallet_weight: dimension.pallet_weight,
            pack_length: dimension.pack_length,
            pack_width: dimension.pack_width,
            pack_height: dimension.pack_height,
            pack_weight: dimension.pack_weight,
        }
    })
}

export default DimensionsService;
