import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../../common/ui/Button';
import Select from '../../common/ui/Select';

const CurrencyTypes = [
  { value: 'sgd', label: 'SGD' },
  { value: 'usd', label: 'USD' },
  { value: 'gbp', label: 'GBP' },
  { value: 'eur', label: 'EUR' },
  { value: 'inr', label: 'INR' },
  { value: 'aud', label: 'AUD' },
];

const MyAccoutingForm = ({ isSubmitting, setFieldValue, values }) => {
  return (
    <Container fluid style={{ position: 'relative', height: '80vh' }}>
      <Form>
        <Row style={{ padding: '12px' }}>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Beneficiary Name
              </label>
              <Field
                name="beneficiaryName"
                id="beneficiary_name"
                label="Beneficiary Name"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="beneficiaryName"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Bank
              </label>
              <Field
                name="bank"
                id="bank"
                label="Bank"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="bank"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Account Number
              </label>
              <Field
                name="accountNumber"
                id="account_number"
                label="Account Number"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="accountNumber"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Bank Code
              </label>
              <Field
                name="bankCode"
                id="bank_code"
                label="Bank Code"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="bankCode"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Iban
              </label>
              <Field
                name="iban"
                id="iban"
                label="Iban"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="iban"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Branch Code
              </label>
              <Field
                name="branchCode"
                id="branch_code"
                label="Branch Code"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="branchCode"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
               Address Line 1
              </label>
              <Field
                name="addressLine1"
                id="addres_line_1"
                label="Address Line 1"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="addressLine1"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Address Line 2
              </label>
              <Field
                name="addressLine2"
                id="address_line2"
                label="Address Line 2"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="addressLine2"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                City
              </label>
              <Field
                name="city"
                id="city"
                label="City"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="city"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                State
              </label>
              <Field
                name="state"
                id="state"
                label="State"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="state"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Country
              </label>
              <Field
                name="country"
                id="country"
                label="Country"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="country"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Postal Code
              </label>
              <Field
                name="postalCode"
                id="postal_code"
                label="Postal Code"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="postalCode"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
              >
                Sort Code
              </label>
              <Field
                name="sortCode"
                id="sort_code"
                label="Sort Code"
                placeholder="Optional"
                className="form-control"
                style={{ maxWidth: '500px' }}
                noCol
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="sortCode"
              />
            </div>
          </Col>
          <Col md="6" lg="6">
            <div className="form-group row">
              <label className="col-sm-12 no-padding" style={{ marginTop: '8px' }}>
                Currency
              </label>
              <Select
                options={CurrencyTypes}
                onChange={(ad) => {
                  setFieldValue('currency', ad.value);
                }}
                className="col-sm-12 no-padding accounting-currency"
                value={CurrencyTypes.find((ct) => {
                  return ct.value === values.currency;
                })}
              />
              <ErrorMessage
                className="form-field-error col-sm-12"
                style={{ margin: '5px auto' }}
                component="small"
                name="currency"
              />
            </div>
          </Col>
        </Row>
        <div className="actions">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default MyAccoutingForm;
