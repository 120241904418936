import axios from '../axios';

class ContactsService {
  static loadContacts = () => axios.get('/contacts', {});
  static loadContact = (id) => axios.get(`/contacts/${id}`, {});
  static createContact = (data) =>
    axios.post('/contacts', {
      contact: {
        first_name: data.name,
        position: data.position,
        email: data.email,
        allow_login: data.allowLogin,
        phone_number: data.phone_number,
      },
    });
  static updateContact = (id, data) =>
    axios.patch(`/contacts/${id}`, {
      contact: {
        first_name: data.name,
        position: data.position,
        email: data.email,
        allow_login: data.allowLogin,
        phone_number: data.phone_number,
      },
    });
  static deleteContact = (id) => axios.delete(`/contacts/${id}`, {});
}

export default ContactsService;
