/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logoWhite from '../../assets/images/logo-white-version.svg';
import { externalContentWrapper, externalWrapper } from './OuterLayoutStyles';

const OuterLayout = (props) => {
  const { children, externalContentWrapperStyles } = props;
  return (
    <Container fluid id="externalWrapper" css={externalWrapper}>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <div style={{ margin: '50% auto', color: 'white' }}>
                  <Link to="/users/sign_in">
                    <img alt="logo" src={logoWhite} />
                  </Link>

                  <h3 style={{ textShadow: '0 0 1px black' }}>
                    International expansion made easy
                  </h3>
                </div>
              </Col>
              <Col>
                <div
                  id="externalContentWrapper"
                  css={externalContentWrapper}
                  style={externalContentWrapperStyles}
                >
                  {children}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default OuterLayout;
