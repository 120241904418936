import React from 'react';
import { host } from '../../../../services/axios';
import Table from '../../../common/ui/Table';
import { formattedDate } from '../../../common/dataTypes/dates';

const AgreementsTable = ({ agreements }) => {
  return (
    <Table id="agreementsTable" style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Terms of conditions</th>
          <th>Date uploaded</th>
        </tr>
      </thead>
      <tbody>
        {agreements.map((agreement) => (
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={host + agreement.document_url}
              >
                {agreement.document_file_name}
              </a>
            </td>
            <td>{formattedDate(agreement.created_at)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AgreementsTable;
