import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../common/ui/Field';
import DocumentWithLinkInput from '../../common/ui/DocumentWithLinkInput';

const AgreementEditFormComponent = ({ values, company, setFieldValue }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="form-group">
            <Field
              name="name"
              label="Company Name"
              type="text"
              placeholder="Name"
              labelStyle={{ marginTop: '8px' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>{'Document'}</label>
          <br />
          <DocumentWithLinkInput
            defaultValue={company.agreement_url}
            onChangeHandler={(file) => {
              /* istanbul ignore next */
              setFieldValue('document', file);
            }}
            label={company.agreement_file_name}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AgreementEditFormComponent;
