import React, { useState } from 'react';
import Button from '../common/ui/Button';
import PropTypes from 'prop-types';

const UserComponent = (props) => {
  const { user, updateUserValidated, deleteUser, toggleDenyUser } = props;
  const [validated, setValidated] = useState(user.validated);
  const [togglingDenyUser, setTogglingDenyUser] = useState();

  /* istanbul ignore next */
  const handleChange = () => {
    setValidated(!validated);
    updateUserValidated(user.id, !validated);
  };

  /* istanbul ignore next */
  const handleDelete = () => {
    deleteUser(user.id);
  };

  /* istanbul ignore next */
  const handleDeny = () => {
    setTogglingDenyUser(true);
    toggleDenyUser(user).then(() => setTogglingDenyUser(false));
  };

  return (
    <tr className="userDetails">
      <td>{user.id}</td>
      <td>{user.email}</td>
      <td>{user.type}</td>
      <td>
        <input
          type="checkbox"
          checked={validated}
          value={validated}
          onChange={handleChange}
        />
      </td>
      <td>
        <Button variant="danger" onClick={handleDelete}>Delete</Button>
        <Button
          variant={user.denied_at ? "danger" : "primary"}
          onClick={handleDeny}
          disabled={togglingDenyUser}
        >
          {user.denied_at ? "Denied" : "Allowed"}
        </Button>
      </td>
    </tr>
  );
};

UserComponent.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserValidated: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  toggleDenyUser: PropTypes.func.isRequired,
};

export default UserComponent;
