import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import StorageService from '../services/storage_service';
import { createNotification } from './common/notifications';

const PrivateRoute = ({component: Component, ...rest}) => {
  const [logged, setLogged] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const bearer = StorageService.getBearer();
    let logged = false;

    if (bearer !== '' && bearer !== undefined) {
      logged = true;
    }
    if (bearer === null) {
      logged = false;
    }
    setLogged(logged);
  }, []);

  useEffect(() => {
    if (!logged && mounted) {
      createNotification('warning', 'Please login again in the platform');
    }
  }, [mounted, logged]);

  if (logged) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} {...rest.properties} />;
        }}
      />
    );
  }
  if (!logged && mounted) {
    return <Redirect to={'/users/sign_in'} />;
  }
  return null;
};

export default PrivateRoute;
