import axios from '../axios';

class CompaniesService {
  static loadCompanies = (locationId = null) =>
    axios.get('/companies', { params: { location_id: locationId } });
  static loadCompany = (companyId) => axios.get(`/companies/${companyId}`, {});
  static updateCompany = (companyId, data, updateBank = false) => {
    let payload = {
      company: {
        name: data.name,
        description: data.description,
        website: data.url,
        tax_number: data.taxNumber,
        registration_number: data.registrationNumber,
        incorporation_date: data.incorporationDate,
        key_business_activity: data.keyBusinessActivity,
      },
    };

    if (updateBank) {
      payload.company = {
        bank: data?.bank,
        account_number: data?.accountNumber,
        iban: data?.iban,
        preferred_currency: data?.currency,
        beneficiary_name: data?.beneficiaryName,
        bank_name: data?.bankName,
        bank_code: data?.bankCode,
        branch_code: data?.branchCode,
        address_line_1: data?.addressLine1,
        address_line_2: data?.addressLine2,
        city: data?.city,
        state: data?.state,
        country: data?.country,
        postal_code: data?.postalCode,
        sort_code: data?.sortCode
      };
    }
    return axios.patch(`/companies/${companyId}`, payload);
  };

  static updateAgreement = (companyId, data) => {
    let formData = new FormData();

    formData.append('company[agreement]', data.document);
    return axios.patch(`/companies/${companyId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
}

export default CompaniesService;
