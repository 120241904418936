import React from 'react';
import Table from '../../common/ui/Table';
import { formattedPrice } from '../../common/dataTypes/prices';
import PurchaseOrdersTableRow from './PurchaseOrdersTableRow';

const PurchaseOrdersTableComponent = (props) => {
    const {
        collection,
        salesOrderLineItems,
        products,
        companies,
        user,
        getCount,
        getAmount,
        getProductBySku,
        totalQuantity,
        totalSales,
    } = props;

    return(
        <Table style={{ width: '99%' }}>
            <thead>
                <tr>
                    <th>Day</th>
                    <th>Customer Name</th>
                    <th>Order Number</th>
                    <th>Status</th>
                    <th>Order Quantity</th>
                    <th>Net sales (SGD)</th>
                </tr>
            </thead>
            <tbody>
                {collection.map((order) => {
                    let orderLineItems;
                    if (salesOrderLineItems && salesOrderLineItems.length > 0){
                        orderLineItems = salesOrderLineItems.filter((lineItem) => {
                            const ids = order.order_line_items.map((li) => li.id.toString());
                            return ids.includes(lineItem.id.toString());
                        });
                    }

                    return(
                        <PurchaseOrdersTableRow
                            key={order.id}
                            order={order}
                            orderLineItems={orderLineItems}
                            products={products}
                            companies={companies}
                            user={user}
                            getCount={getCount}
                            getAmount={getAmount}
                            getProductBySku={getProductBySku}
                        />
                    );
                })}
                <tr className="orderRow">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                        style={{
                        border: '1px solid #dee2e6',
                        borderRight: 'none',
                        }}
                    >
                        {totalQuantity}
                    </td>
                    <td
                        style={{
                        border: '1px solid #dee2e6',
                        borderLeft: 'none',
                        }}
                    >
                        {formattedPrice(totalSales, 'sgd')}
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default PurchaseOrdersTableComponent;

