import React from 'react';
import InnerLayout from './layout/InnerLayout';

const NotFoundComponent = (props) => {
  const { user, logOut, company } = props;

  return (
    <InnerLayout logOut={logOut} user={user} company={company}>
      Not found
    </InnerLayout>
  );
};

export default NotFoundComponent;
