/** @jsx jsx */
/** @jsxFrag React.Fragment */
// eslint-disable-next-line
import React, { useState } from 'react';
import { jsx, css } from '@emotion/core';
import { Field } from 'formik';

const FieldComponent = (props) => {
  const [inputValue, setInputValue] = useState('');
  const {
    name,
    type,
    className,
    placeholder,
    as,
    children,
    setFieldValue,
  } = props;

  const clNames = type === 'file' ? '' : `field ${className}`;

  const inputStyles = css`
    padding: 15px;
    height: 50px;
    margin-bottom: 15px;

    &:focus {
      color: #28396b;
      background-color: #fff;
      border-color: #28396b;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(40, 57, 107, 0.45);
    }
  `;

  const updateInputValue = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
    setFieldValue(name, e.target.value);
  };

  const rowsValue = () =>{
    return (as === "textarea") ? 20 : false;
  }

  return (
    <>
      <Field
        key={name}
        name={name}
        type={type}
        as={as}
        value={inputValue}
        className={clNames}
        placeholder={placeholder}
        onChange={updateInputValue}
        autofocus
        css={inputStyles}
        rows={rowsValue()}
      >
        {children}
      </Field>
    </>
  );
};

export default FieldComponent;
