import React from 'react';
import Table from '../../common/ui/Table';
import Link from '../../common/ui/Link';
import { formattedDate } from '../../common/dataTypes/dates';

const PurchaseOrdersRowDetail = ({
    visible,
    getProductBySku,
    orderLineItems,
    products,
    count,
}) => {
    if (visible && count > 0){
        return(
            <tr>
                <td colSpan="6" className="top-align">
                    <Table>
                        <thead>
                            <tr>
                                <td>Seller SKU</td>
                                <td>Brand</td>
                                <td>Name</td>
                                <td>Quantity</td>
                                <td>Unit Price</td>
                                <td>Expiry Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            {orderLineItems.map((orderLineItem) => {
                                const product = getProductBySku(orderLineItem.seller_sku_code, products);

                                if (product){
                                    return(
                                        <tr key={orderLineItem.id}>
                                            <td>
                                                <Link to={`/products/${product.id}`}>{product.id}</Link>
                                            </td>
                                            <td>{product.brand}</td>
                                            <td>{product.name}</td>
                                            <td>{orderLineItem.quantity ? parseInt(orderLineItem.quantity) : 0}</td>
                                            <td>{orderLineItem.price}</td>
                                            <td>{formattedDate(orderLineItem.expiry_date)}</td>
                                        </tr>
                                    );
                                }

                                return null;
                            })}
                        </tbody>
                    </Table>
                </td>
            </tr>
        );
    }

    if (visible && count === 0){
        return(
            <tr>
                <td colspan="6">No items</td>
            </tr>
        );
    }

    return null;
};

export default PurchaseOrdersRowDetail;