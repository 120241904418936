import axios from '../axios';

class CertificationsService {
    static loadCertifications = (productId) => axios.get('/certifications', { params: {product_id: productId}});
    static createCertification = (productId, certification) => {
        let formData = new FormData();
        formData.append('certification[name]', certification.name);
        formData.append('certification[start_date]', certification.start_date);
        formData.append('certification[end_date]', certification.end_date);
        formData.append('certification[document]', certification.document);
        formData.append('certification[product_id]', productId);
        return axios.post(`/certifications`, formData,
                   {
                       headers: {
               'Content-Type': 'multipart/form-data'
               }
                   }
                  )
    };

    static updateCertification = (certId, certification) => {
        let formData = new FormData();
        formData.append('certification[name]', certification.name);
        formData.append('certification[start_date]', certification.start_date);
        formData.append('certification[end_date]', certification.end_date);
        formData.append('certification[document]', certification.document);
        return axios.patch(`/certifications/${certId}`, formData,
                   {
                       headers: {
               'Content-Type': 'multipart/form-data'
               }
                   }
                  )
    }

    static deteleCertification = (certId) => {
        return axios.delete(`/certifications/${certId}`)
    }
}

export default CertificationsService;
