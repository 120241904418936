import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InventoryTabContent from './InventoryTabContent';

const InventoryTabComponent = ({
  productsInStock,
  productsNoStock,
  inventoryValue,
  company
}) => {
  return (
    <Row>
      <Col style={{ padding: '0px' }}>
        <InventoryTabContent
          productsInStock={productsInStock}
          productsNoStock={productsNoStock}
          inventoryValue={inventoryValue}
          company={company}
        />
      </Col>
    </Row>
  );
};

export default InventoryTabComponent;
