class BaseService {
  /**
   * Append values to formData, managing all types of variables.
   * @param formData FormData Object
   * @param name The name of the field whose data is contained in value.
   * @param value The field's value.
   * @private
   */
  static _paramToFormData(formData, name, value) {
    if (Array.isArray(value)) {
      this._arrayToFormData(formData, name, value);
    } else if (typeof value === 'object') {
      Object.keys(value).forEach((object_key) => {
        this._paramToFormData(formData, `${name}[${object_key}]`, value[object_key]);
      })
    } else {
      formData.append(name, value);
    }
  }

  /**
   * Append an array to formData
   * @param formData FormData Object
   * @param name The name of the field whose data is contained in value.
   * @param arrayValue The field's value.
   * @private
   */
  static _arrayToFormData(formData, name, arrayValue) {
    if (arrayValue.length > 0) {
      arrayValue.map((element) => {
        this._paramToFormData(formData, `${name}[]`, element)
        return null;
      });
    } else {
      formData.append(name, '[]');
    }
  }
}

export default BaseService;