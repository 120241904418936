import React from 'react';
import PropTypes from "prop-types";
import Table from '../Table';
import EditableTableRow from './EditableTableRow';

/**
 * Table component for listing and inline edit objects
 * @param props Properties
 * @returns {JSX.Element}
 * @constructor
 */
const EditableTableComponent = (props) => {
  const {
    /**
     * Array of objects
     */
    collection,
    /**
     * Array of objects with columns which will be shown in the table
     * @example
     * [
     *  { id: 'name', name: 'Category', type: 'text' },
     *  { id: 'brand', name: 'type', type: 'text' },
     *  ]
     */
    columns,
    /**
     * Function to handle edition
     */
    handleEdit,
    /**
     * Function to handle deletion
     */
    handleDelete } = props;

  return (
    <Table id="editableTable">
      <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.id}>{column.name}</th>
        ))}
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      {collection.map((item) => (
        <EditableTableRow key={item.id} rowObject={item} columns={columns} handleEdit={handleEdit} handleDelete={handleDelete} />
      ))}
      </tbody>
    </Table>
  );
};

EditableTableComponent.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default EditableTableComponent;
