import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoginService from '../../services/login';
import { createNotification, delayedRedirect } from '../common/notifications';
import Form from './SignUpForm';
import OuterLayout from '../layout/OuterLayout';

const SignUpSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
  password_confirmation: Yup.string().required(),
});

const SignUpComponent = () => {
  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    LoginService.signup(values)
      .then((response) => {
        if (response.status === 200) {
          createNotification('success', 'You have been registered');
          delayedRedirect('/');
        }
      })
      .catch((errors) => {
        createNotification('error', 'Email already has been taken');
      });
  };

  return (
    <OuterLayout>
      <Formik
        initialValues={{
          email: '',
          password: '',
          password_confirmation: '',
        }}
        enableReinitialize={true}
        validationSchema={SignUpSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => (
          <Form
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={submitForm}
          />
        )}
      </Formik>
    </OuterLayout>
  );
};

export default SignUpComponent;
