import React, { useState, useEffect, useCallback } from 'react';
import InnerLayout from '../layout/InnerLayout';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ProductService from '../../services/data/products_service';
import Form from './ProductEditForm';
import { createNotification } from '../common/notifications';
import ExtraInformation from './ProductExtraInformation';
import { useLocation } from 'react-router-dom';

const ProductEditSchema = Yup.object().shape({
  name: Yup.string(),
  images: Yup.string(),
  description: Yup.string(),
  brand: Yup.string(),
  productType: Yup.string(),
  hsCode: Yup.string().min(6).required(),
  country: Yup.string(),
  upc: Yup.string().min(6).max(14).required(),
  sellerSkuCode: Yup.string().required(),
  fluetisSkuCode: Yup.string().required(),
  units_of_measure: Yup.string(),
  measure_value: Yup.number(),
  quantity_per_UOM: Yup.number(),
  temperatureOfStorage: Yup.string().nullable(),
  manufactureShelfLife: Yup.number().required(),
  exportShelfLife: Yup.number(),
  singleQuantity: Yup.number(),
  singleFormat: Yup.string(),
  iumWeight: Yup.number(),
  packQuantity: Yup.number(),
  packBarcode: Yup.string(),
  packFormat: Yup.string(),
  packWeight: Yup.number(),
  cartonQuantity: Yup.number(),
  cartonBarcode: Yup.string(),
  cartonFormat: Yup.string(),
  piumWeight: Yup.number()
});

const ProductEditComponent = ({
  match,
  user,
  location,
  company,
  logOut,
  shipments,
}) => {
  const [product, setProduct] = useState();
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [productType, setProductType] = useState('');
  const [sellerSkuCode, setSellerSkuCode] = useState('');
  const [fluetisSkuCode, setFluetisSkuCode] = useState('');
  const [country, setCountry] = useState('');
  const [upc, setUpc] = useState('');
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [images, setImages] = useState([]);
  const [updatingImages, setUpdatingImages] = useState(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState('');
  const [measureValue, setMeasureValue] = useState('');
  const [quantityPerUOM, setQuantityPerUOM] = useState(0);
  const [hsCode, setHsCode] = useState('');
  const [platformName, setPlatformName] = useState('');
  const [temperatureOfStorage, setTemperatureOfStorage] = useState('');
  const [manufactureShelfLife, setManufactureShelfLife] = useState(0);
  const [batchableMessage, setBatchableMessage] = useState('');
  const [exportShelfLife, setExportShelfLife] = useState(0);
  const [singleQuantity, setSingleQuantity] = useState(0);
  const [singleFormat, setSingleFormat] = useState('');
  const [iumWeight, setIumWeight] = useState(0);
  const [packQuantity, setPackQuantity] = useState(0);
  const [packBarcode, setPackBarcode] = useState('');
  const [packFormat, setPackFormat] = useState('');
  const [packWeight, setPackWeight] = useState(0);
  const [cartonQuantity, setCartonQuantity] = useState(0);
  const [cartonBarcode, setCartonBarcode] = useState('');
  const [cartonFormat, setCartonFormat] = useState('');
  const [piumWeight, setPiumWeight] = useState(0);
  const stateLocation = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  const setLocalImages = (images, updateProduct = true) => {
    setImages(images);
    const product = {
      images: images,
    };
    if (updateProduct) {
      setUpdatingImages(true);
      ProductService.updateProduct(match.params['id'], product, true)
        .then((response) => {
          _setProductFromProductServiceRequest(response.data);
          setUpdatingImages(false);
          createNotification('success', 'has been updated', 'The product');
        })
        .catch((error) => {
          console.log(error.body);
          setUpdatingImages(false);
          createNotification('error', 'was not possible to update', 'The product');
        });
    }
  };

  const loadProduct = useCallback(() => {
    setLoadingProduct(true);
    ProductService.loadProduct(match.params['id'])
      .then((response) => {
        /* istanbul ignore next */
        _setProductFromProductServiceRequest(response.data);
        /* istanbul ignore next */
        setLoadingProduct(false);
        /* istanbul ignore next */
        setLocalImages(response.data.images, false);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [match.params]);

  /* istanbul ignore next */
  const handleEditSubmit = (values, actions) => {
    ProductService.updateProduct(match.params['id'], values)
      .then((response) => {
        _setProductFromProductServiceRequest(response.data);

        createNotification('success', 'has been updated', 'The product');
        actions.setSubmitting(false);
      })
      .catch((error) => {
        createNotification(
          'error',
          'was not possible to update',
          'The product'
        );
        console.log(error);
      });
  };

  /**
   * Set state values from a product which come from a ProductService requiers
   * @param requestData
   * @private
   * @see ProductService
   */
  /* istanbul ignore next */
  const _setProductFromProductServiceRequest = (requestData) => {
    if (requestData) {
      setName(requestData.name);
      setImages(requestData.images);
      setBrand(requestData.brand);
      setProductType(requestData.product_type);
      const auxCountry = requestData.country_of_origin;
      setCountry(auxCountry);
      const auxUpc = requestData.upc;
      setUpc(auxUpc);
      const auxHsCode = requestData.hs_code;
      setHsCode(auxHsCode);
      setUnitsOfMeasure(requestData.units_of_measure);
      setMeasureValue(requestData.measure_value);
      setQuantityPerUOM(requestData.quantity_per_uom);
      setPlatformName(requestData.platform_name);
      setTemperatureOfStorage(requestData.temperature_of_storage);
      setManufactureShelfLife(requestData.manufacture_shelf_life);
      setExportShelfLife(requestData.export_shelf_life);
      setSingleQuantity(requestData.single_quantity);
      setSingleFormat(requestData.single_format);
      setIumWeight(requestData.ium_weight);
      setPackQuantity(requestData.pack_quantity);
      setPackBarcode(requestData.pack_barcode);
      setPackFormat(requestData.pack_format);
      setPackWeight(requestData.pack_weight);
      setCartonQuantity(requestData.carton_quantity);
      setCartonBarcode(requestData.carton_barcode);
      setCartonFormat(requestData.carton_format);
      setPiumWeight(requestData.pium_weight);
      setSellerSkuCode(requestData.seller_sku_code);
      setFluetisSkuCode(requestData.fluetis_sku_code);
      let message = (requestData.batchable || requestData.batchable == null) ? '' : 'No expiry date';
      setBatchableMessage(message);

      // To product is saved in any of child components, set props for api
      requestData.country = auxCountry;
      requestData.upc = auxUpc;
      setProduct({
        ...requestData,
        country: auxCountry,
        upc: auxUpc,
        hsCode: auxHsCode
      });
    }
  };

  useEffect(() => {
    if (stateLocation.state){
      setSearchTerm(stateLocation.state.searchTerm);
    }

    loadProduct();
    // eslint-disable-next-line
  }, []);

  return (
    <InnerLayout
      logOut={logOut}
      user={user}
      location={location}
      company={company}
    >
      <Formik
        initialValues={{
          name: name || '',
          images: images || '',
          brand: brand || '',
          hsCode: hsCode || '',
          sellerSkuCode: sellerSkuCode || '',
          fluetisSkuCode: fluetisSkuCode || '',
          country: country || '',
          upc: upc || '',
          units_of_measure: unitsOfMeasure || '',
          measure_value: measureValue || '',
          quantity_per_UOM: quantityPerUOM || '',
          platformName: platformName || '',
          temperatureOfStorage: temperatureOfStorage || '', 
          manufactureShelfLife: manufactureShelfLife || '', 
          batchableMessage: batchableMessage || '',
          exportShelfLife: exportShelfLife || '', 
          singleQuantity: singleQuantity || '', 
          singleFormat: singleFormat || '', 
          iumWeight: iumWeight || '', 
          packQuantity: packQuantity || '', 
          packBarcode: packBarcode || '', 
          packFormat: packFormat || '', 
          packWeight: packWeight || '', 
          cartonQuantity: cartonQuantity || '', 
          cartonBarcode: cartonBarcode || '', 
          cartonFormat: cartonFormat || '', 
          piumWeight: piumWeight || '', 
        }}
        enableReinitialize={true}
        validationSchema={ProductEditSchema}
        onSubmit={(values, actions) => {
          handleEditSubmit(values, actions);
        }}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => (
          <Form
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={submitForm}
            images={images}
            readonly = {location.hash === '#show' ? true : false}
            admin = {user.type === 'Admin' ? true : false}
            setImages={setLocalImages}
            product={product}
            updatingImages={updatingImages}
            searchTerm={searchTerm}
            user={user}
            location={location}
          />
        )}
      </Formik>
      <ExtraInformation
        product={product}
        loadingProduct={loadingProduct}
        company={company}
        readonly = {location.hash === '#show' ? true : false}
        enabled={true}
      />
    </InnerLayout>
  );
};

export default ProductEditComponent;
