import axios from '../axios';

class ShipmentsService {
  static loadShipments() {
    return axios.get('/shipments');
  }

  static shipmentsToCSV() {
    return axios.get('/shipments.csv');
  }

  static loadShipment(id) {
    return axios.get(`/shipments/${id}`);
  }

  static createShipment(data) {
    return axios.post('/shipments', {
      address_id: parseInt(data.pickingLocation.value),
      location_id: parseInt(data.deliveryLocation.value),
      item_data: data.item_data.map((item) => ({
        product_id: item.productId,
        quantity: item.quantity,
        price: item.price,
        expiration_date: item.expiration_date,
      })),
      order_date: data.orderDate,
      pickup_date: data.pickupDate,
      delivery_date: data.deliveryDate
    });
  }

  static updateShipment(id, data) {
    return axios.patch(`/shipments/${id}`, {
      address_id: parseInt(data.pickingLocation && data.pickingLocation.value),
      location_id: parseInt(data.deliveryLocation && data.deliveryLocation.value),
      item_data:
        data.item_data &&
        data.item_data.map((item) => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          expiration_date: item.expiration_date,
        })),
      purchase_order_status: data.purchase_order_status,
      sale_order_status: data.sale_order_status,
      sale_order_packed_status: data.sale_order_packed_status,
      pallet_info: data.pallet_info,
      order_date: data.orderDate,
      pickup_date: data.pickupDate,
      delivery_date: data.deliveryDate
    });
  }

  static deleteShipment(id) {
    return axios.delete(`/shipments/${id}`);
  }

  static setShipmentToActive(id) {
    return this.updateShipment(id, {
      purchase_order_status: 'active',
      sale_order_status: 'active',
      sale_order_packed_status: '',
    });
  }

  static setShipmentToFinalized(id, pallet_info) {
    return this.updateShipment(id, {
      sale_order_status: 'finalized',
      sale_order_packed_status: 'packed',
      purchase_order_status: '',
      pallet_info: pallet_info,
    });
  }

  static getShipmentsCSV(status) {
    if (status === '') {
      return axios.get(`/shipments.csv`);
    }
    return axios.get(`/shipments.csv?status=${'"' + status + '"'}`);
  }

  static completedCondition(shipment) {
    return (
      shipment.status !== 'DRAFT' &&
      shipment.status !== 'ACTIVE' &&
      shipment.status !== 'PAYMENT PENDING' &&
      shipment.status !== 'CONTACT SUPPORT'
    );
  }
}

export default ShipmentsService;
