import React from 'react';
import Table from '../common/ui/Table';
import { formattedPrice } from '../common/dataTypes/prices';
import OrdersOfflineTableRow from './OrdersOfflineTableRow';

const OrdersOfflineTableComponent = (props) => {
  const {
    collection,
    products,
    companies,
    user,
    getCount,
    getAmount,
    totalQuantity,
    totalSales,
  } = props;
  return (
    <Table style={{ width: '99%' }}>
      <thead>
        <tr>
          <th>Day</th>
          <th>Customer Name</th>
          <th>Order Number</th>
          <th>FC Status</th>
          <th>Ordered Quantity</th>
          <th>Tracking Status</th>
          <th>Tracking Link</th>
        </tr>
      </thead>
      <tbody>
        {collection.map((order) => {
          let orderLineItems = order.order_line_items;
          return (
            <OrdersOfflineTableRow
              key={order.id}
              order={order}
              orderLineItems={orderLineItems}
              products={products}
              companies={companies}
              user={user}
              getCount={getCount}
              getAmount={getAmount}
            />
          );
        })}
        <tr className="orderRow">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td
            style={{
              border: '1px solid #dee2e6',
              borderRight: 'none',
            }}
          >
            {totalQuantity}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OrdersOfflineTableComponent;
