export const containsElem = (array, element) => {
  let value = false;
  array.map((elem) => {
    if (elem.id === element.id) {
      value = true;
    }
    return null;
  });
  return value;
};

export const uniqueInsert = (array, elem) => {
  if (!containsElem(array, elem)) {
    array.push(elem);
  }
};

export const unique = (array, field) => {
  const uniqueData = [];
  array.map((elem) => {
    if (uniqueData.map((ud) => ud[field]).indexOf(elem[field]) === -1) {
      uniqueData.push(elem);
    }
    return null;
  });
  return uniqueData;
};

export const sum = (array) => {
  return array.reduce((a, b) => a + b, 0);
};

export const sliceCollectionInChunks = (collection, chunk = 200) => {
  let i, j, temparray;
  let result = [];
  for (i = 0, j = collection.length; i < j; i += chunk) {
    temparray = collection.slice(i, i + chunk);
    result.push(temparray);
  }
  return result;
};

export const dig = (obj, target) => 
  target in obj ? obj[target] : Object.values(obj).reduce((acc, val) => {
      if (acc !== undefined) return acc;
      if (typeof val === 'object') return dig(val, target);
      }, undefined);