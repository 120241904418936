import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FlatButton from '../common/ui/FlatButton';
import LoginService from '../../services/login';
import SharedLinks from './SharedLinks';
import OuterLayout from '../layout/OuterLayout';

const UserConfirmationSent = (props) => {
  const [confirmed, setConfirmed] = useState(false);
  /* istanbul ignore next */
  const handleClick = () => {
    const url = props.history.location.pathname + props.history.location.search;
    LoginService.confirmUser(url)
      .then(() => {
        setConfirmed(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setConfirmed(true);
        } else {
          console.log(error);
        }
      });
  };

  if (!confirmed) {
    return (
      <OuterLayout>
        <Container fluid id="userConfirmationSent">
          <Row>
            <Col>
              {' '}
              You can confirm your account email through the button below:
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col>
              <FlatButton variant="primary" onClick={(_) => handleClick()}>
                Confirm
              </FlatButton>
            </Col>
          </Row>
        </Container>
        <SharedLinks
          login
          signUp={false}
          forgotPassword={false}
          confirmation={false}
        />
      </OuterLayout>
    );
  }
  /* istanbul ignore next */
  return (
    <OuterLayout>
      <div>Your account is confirmed</div>
      <SharedLinks
        login
        signUp={false}
        forgotPassword={false}
        confirmation={false}
      />
    </OuterLayout>
  );
};

export default UserConfirmationSent;
