import { LOAD_INVOICES_AND_PAYMENTS } from "../actions/actions";

const invoicesAndPaymentsReducers = (state, action) => {
  switch (action.type) {
    case LOAD_INVOICES_AND_PAYMENTS:
      const newState = { ...state };
      newState.invoices = action.invoices;
      newState.payments = action.payments;
      newState.refunds = action.refunds;
      return newState;
    default:
      return state;
  }
};

export default invoicesAndPaymentsReducers;
