import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Field from '../../common/ui/Field';
import DocumentWithLinkInputComponent from '../../common/ui/DocumentWithLinkInput';

const CompanyAgreementEditFormComponent = ({ values, company_agreement, setFieldValue }) => {
    return(
        <Container Fluid>
            <Row>
                <Col>
                    <div className="form-group">
                        <Field
                            name="name"
                            label="Company Name"
                            type="text"
                            placeholder="Name"
                            labelStyle={{ marginTop: '8px' }}
                            disabled="disabled"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>{'Document'}</label>
                    <br />
                    <DocumentWithLinkInputComponent
                        defaultValue={company_agreement.document_url}
                        onChangeHandler={(file) => {
                            /* istanbul ignore text */
                            setFieldValue('document', file);
                        }}
                        label={company_agreement.document_file_name}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CompanyAgreementEditFormComponent;