import React from 'react';
import styled from '@emotion/styled';
import { Field, ErrorMessage } from 'formik';
import './Field.css';

const FieldComponent = (props) => {
  const {
    name,
    type,
    className,
    placeholder,
    as,
    children,
    label,
    style,
    disabled,
    labelStyle,
    onChange,
    noCol,
    checked,
    multiple,
    id,
    labelColSize,
    errorMessageColSize,
    errorMessageDanger
  } = props;

  let clName = '';
  if (className) {
    clName = className;
  }

  const clNames =
    type === 'checkbox'
      ? ''
      : `form-control ${noCol ? '' : 'col-sm-9'} ${clName}`;

  // Properties for Formik Field
  const fieldProps = {
    name: name,
    type: type,
    as: as,
    className: clNames,
    placeholder: placeholder,
    style: style,
    disabled: disabled,
    checked: checked,
    multiple: multiple,
    id: id,
    step: "any"
  };

  // Add onChange to Field properties if necessary.
  if (onChange) {
    fieldProps.onChange = onChange;
  }

  const LabelStyleFinal = styled.label`
  `;

  const labelCol = (labelColSize !== null) ? labelColSize : '';

  return (
    <>
      {label && (
        <LabelStyleFinal className={`form-field-label ${labelCol}`} style={labelStyle}>
          {label}
        </LabelStyleFinal>
      )}
      <Field {...fieldProps}>
        {children}
      </Field>
      <ErrorMessage
        className={`form-field-error ${errorMessageColSize !== null ? errorMessageColSize : 'col-sm-12'} ${errorMessageDanger && 'text-danger'}`}
        style={{margin: '5px auto'}}
        component="small"
        name={name}
      />
    </>
  );
};

export default FieldComponent;
