import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Tab } from 'react-bootstrap';
import InnerLayout from '../layout/InnerLayout';
import Can from '../roles/Can';
import Tabs from '../common/ui/Tabs';
// eslint-disable-next-line
import OrdersOffline from './OrdersOffline';
import OrdersService from '../../services/data/orders_service';
import ProductsService from '../../services/data/products_service';
import CompaniesService from '../../services/data/companies_service';

const OrdersAndSalesComponent = (props) => {
  const { logOut, user, location, company, products } = props;
  const [companies, setCompanies] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);

  useEffect(() => {
    OrdersService.loadOrders("week", false)
      .then((response) => {
        setOrders(response.data.orders);
        setOrdersLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    CompaniesService.loadCompanies()
      .then((response) => {
        setCompanies(response.data.companies);
      })
      .catch((error) => {
        console.log(error);
      });
      // eslint-disable-next-line
  }, []);

  if (user) {
    return (
      <Can
        role={user.type.toLowerCase()}
        perform="OrdersAndSales:list"
        yes={() => (
          <InnerLayout
            logOut={logOut}
            user={user}
            location={location}
            company={company}
          >
            <Tabs defaultActiveKey="offline" id="tabs_orders_and_sales">
              <Tab eventKey="offline" title="Overview">
                {
                  <OrdersOffline
                    orders={orders}
                    adminView={false}
                    ordersLoading={ordersLoading}
                    setOrdersLoading={setOrdersLoading}
                    company={company}
                    companies={companies}
                    user={user}
                  />
                }
              </Tab>
            </Tabs>
          </InnerLayout>
        )}
        no={() => <Redirect to="/no_permissions" />}
      />
    );
  }
  return null;
};

OrdersAndSalesComponent.propTypes = {
  logOut: PropTypes.func.isRequired,
};

export default OrdersAndSalesComponent;
