import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PriceListService from '../../../services/data/price_list_service';
import { createNotification } from '../../common/notifications';
import ProductService from '../../../services/data/products_service';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './Costing.css';

const costingSchema = Yup.object().shape({
  price_value_gbp: Yup.number(),
  price_value_eur: Yup.number(),
  price_value_usd: Yup.number(),
  price_value_sgd: Yup.number(),
  price_value_inr: Yup.number(),
  price_value_aud: Yup.number(),
  price_value_fluetisrsp: Yup.number(),
  price_value_brrp: Yup.number(),
  cost: Yup.number(),
  cost_per_carton_sales: Yup.number(),
  currency: Yup.string(),
});

const CostingComponent = ({ product, company, readonly }) => {
  const [priceLists, setPriceLists] = useState([]);

  const loadPriceList = () => {
    PriceListService.loadPriceLists()
      .then((response) => {
        setPriceLists(response.data['price_lists']);
      })
      .catch((error) => {
        createNotification(
          'error',
          'was not possible to load',
          'The costing tab'
        );
        console.log(error);
      });
  };

  useEffect(() => {
    loadPriceList();
    // eslint-disable-next-line
  }, [product]);

  const getIdFromKey = (key) => {
    let priceList;
    switch (key) {
      case 'supplier_cost_gbp':
        priceList = priceLists.find((el) => el.code === 'SUPPLIERCOSTGBP');
        if (priceList) {
          return priceList.id;
        }
        break;
      case 'supplier_cost_eur':
        priceList = priceLists.find((el) => el.code === 'SUPPLIERCOSTEUR');
        if (priceList) {
          return priceList.id;
        }
        break;
      case 'supplier_cost_usd':
        priceList = priceLists.find((el) => el.code === 'SUPPLIERCOSTUSD');
        if (priceList) {
          return priceList.id;
        }
        break;
      case 'supplier_cost_sgd':
        priceList = priceLists.find((el) => el.code === 'SUPPLIERCOSTSGD');
        if (priceList) {
          return priceList.id;
        }
        break;
      case 'supplier_cost_inr':
        priceList = priceLists.find((el) => el.code === 'SUPPLIERCOSTINR');
        if (priceList) {
          return priceList.id;
        }
        break;
      case 'fluetis_recommended_price':
        priceList = priceLists.find((el) => el.code === 'FLUETISRSP');
        if (priceList) {
          return priceList.id;
        }
        break;
      case 'brand_recommended_price':
        priceList = priceLists.find((el) => el.code === 'BRRP');
        if (priceList) {
          return priceList.id;
        }
        break;
      default:
        priceList = priceLists.find((el) => el.code === 'SUPPLIERCOSTGBP');
        if (priceList) {
          return priceList.id;
        }
        break;
    }
  };

  const getValueFromKey = (key, product) => {
    switch (key) {
      case 'supplier_cost_gbp':
        return product.price_value_gbp;
      case 'supplier_cost_eur':
        return product.price_value_eur;
      case 'supplier_cost_usd':
        return product.price_value_usd;
      case 'supplier_cost_sgd':
        return product.price_value_sgd;
      case 'supplier_cost_inr':
        return product.price_value_inr;
      case 'supplier_cost_aud':
        return product.price_value_aud;
      case 'fluetis_recommended_price':
        return product.price_value_fluetisrsp;
      case 'brand_recommended_price':
        return product.price_value_brrp;
      default:
        return product.price_value_fluetisrsp;
    }
};

  const getValueFromList = (key) => {
    const value = getValueFromKey(key, product);
    if (value) {
      return parseFloat(value).toFixed(2);
    }
  };

  const handleSubmit = (values, actions) => {
    ProductService.updateProduct(product.id, values)
      .then((response) => {
        createNotification('success', 'has been updated', 'The product');
        actions.setSubmitting(false);
      })
      .catch((error) => {
        createNotification(
          'error',
          'was not possible to update',
          'The product'
        );
        console.log(error);
      });
  };

  return (
    <Container fluid>
      <Formik
        initialValues={{
          price_value_gbp: getValueFromList('supplier_cost_gbp') === 'NaN' ? 0 : getValueFromList('supplier_cost_gbp'),
          price_value_eur: getValueFromList('supplier_cost_eur') === 'NaN' ? 0 : getValueFromList('supplier_cost_eur'),
          price_value_usd: getValueFromList('supplier_cost_usd') === 'NaN' ? 0 : getValueFromList('supplier_cost_usd'),
          price_value_sgd: getValueFromList('supplier_cost_sgd') === 'NaN' ? 0 : getValueFromList('supplier_cost_sgd'),
          price_value_inr: getValueFromList('supplier_cost_inr') === 'NaN' ? 0 : getValueFromList('supplier_cost_inr'),
          price_value_aud: getValueFromList('supplier_cost_aud') === 'NaN' ? 0 : getValueFromList('supplier_cost_aud'),
          price_value_fluetisrsp: getValueFromList('fluetis_recommended_price') === 'NaN' ? 0 : getValueFromList('fluetis_recommended_price'),
          price_value_brrp: getValueFromList('brand_recommended_price') === 'NaN' ? 0 : getValueFromList('brand_recommended_price'),
          cost: product && product.cost || 0,
          cost_per_cartons_sale: product && product.cost_per_cartons_sale || 0,
          currency: product && product.currency || 'gbp',
        }}
        enableReinitialize={true}
        validationSchema={costingSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => (
          <Form>
            <Row>
              <Col>
                <label class="flex-label" style={{ marginBottom: '1em' }}>
                  Cost per carton
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="2" md="6">
                <label class="flex-label">
                  Currency
                </label>
              </Col>
              <Col lg="6" md="12">
                <Field as="select" name="currency" class="flex-input-select" disabled={readonly}>
                  <option value="gbp" selected={company?.preferred_currency === 'gbp'}> GBP </option>
                  <option value="eur" selected={company?.preferred_currency === 'eur'}> EUR </option>
                  <option value="usd" selected={company?.preferred_currency === 'usd'}> USD </option>
                  <option value="sgd" selected={company?.preferred_currency === 'sgd'}> SGD </option>
                  <option value="inr" selected={company?.preferred_currency === 'inr'}> INR </option>
                  <option value="aud" selected={company?.preferred_currency === 'aud'}> AUD </option>
                </Field>
              </Col>
            </Row>
            <Row>
              <Col lg="2" md="6">
                <label class="flex-label" data-tip data-for="formatInfoFirst">
                  <ReactTooltip
                    id="formatInfoFirst"
                    effect="solid"
                    type="light"
                    offset={{bottom: 20}}
                  >
                    <p>Please add 2 decimals</p>
                  </ReactTooltip>
                  1-10 cartons
                  <FontAwesomeIcon
                    style={{marginLeft: '3px'}}
                    icon={faQuestionCircle}
                  >
                  </FontAwesomeIcon>
                </label>
              </Col>
              <Col lg="6" md="12">
                <Field name="cost" type="number" step="any" class="flex-input" style={{ height: '1.5em', width: '3.4em' }} />
                <span style={{ marginLeft: '15px' }}>{company?.preferred_currency}</span>
                <ErrorMessage component="small" name="cost" className={'text-danger'} />
              </Col>
            </Row>
            <Row>
              <Col lg="2" md="6">
                <label class="flex-label" data-tip data-for="formatInfoSecond">
                  <ReactTooltip
                    id="formatInfoSecond"
                    effect="solid"
                    type="light"
                    offset={{bottom: 20}}
                  >
                    <p>Please add 2 decimals</p>
                  </ReactTooltip>
                  More than 10 cartons
                  <FontAwesomeIcon
                    style={{marginLeft: '3px'}}
                    icon={faQuestionCircle}
                  >
                  </FontAwesomeIcon>
                </label>
              </Col>
              <Col lg="6" md="12">
                <Field name="cost_per_cartons_sale" type="number" step="any" class="flex-input" style={{ height: '1.5em', width: '3.4em' }} disabled={readonly} />
                <span style={{ marginLeft: '15px' }}>{company?.preferred_currency}</span>
                <ErrorMessage component="small" name="cost_per_cartons_sale" className={'text-danger'} />
              </Col>
            </Row>

            {!readonly && (
            <button type="submit" className="btn btn-primary" style={{ marginTop: '1em' }}>
              Submit
            </button>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CostingComponent;
