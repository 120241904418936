import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import PurchaseOrdersService from '../../../services/data/purchase_orders_service';
import ProductService from '../../../services/data/products_service';
import { Row, Col } from 'react-bootstrap';
import PaginateComponent from '../../common/ui/PaginateComponent';
import DateSelector from '../../common/ui/DateSelector';
import BulkUpload from '../../common/ui/BulkUpload';
import { convertToInputFormat } from '../../common/dataTypes/dates';
import PurchaseOrdersTable from './PurchaseOrdersTable';
import PurchaseOrdersSearch from './PurchaseOrdersSearch';
import AdjustPaginateButtons from '../../common/ui/AdjustPaginateButtons';
import Spinner from '../../common/ui/Spinner';
import Link from '../../common/ui/Link';
import Button from '../../common/ui/Button';
import FileDownload from '../../common/dataTypes/fileDownload';
import { createNotification } from '../../common/notifications';

const PurchaseOrdersComponent = (props) => {
    const { user, localProducts, adminView, company } = props;
    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [orderLineItems, setOrderLineItems] = useState([]);
    const [localOrders, setLocalOrders] = useState([]);
    const [allLocalOrders, setAllLocalOrders] = useState([]);
    const [salesOrderLineItems, setSalesOrderLineItems] = useState([]);
    const [reloadCleanData, ] = useState(false);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [dateScope, setDateScope] = useState("week");
    const [totalSales, setTotalSales] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [bulkUploadBegin, setBulkUploadBegin] = useState("");
    const [bulkUploadSummary, setBulkUploadSummary] = useState("");
    const [bulkUploadErrors, setBulkUploadErrors] = useState([]);
    const [bulkUploadEnd, setBulkUploadEnd] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const today = new Date();

    let aux = new Date();
    aux.setDate(aux.getDate() - 7);
    const [selectedDate, setSelectedDate] = useState({
      startDate: convertToInputFormat(aux),
      endDate: convertToInputFormat(today),
    });

    /* istanbul ignore next */
    const setSearchField = (term) => {
      setSearchTerm(term);
    }
  
    useEffect(() => {
      loadAllOrders();
    }, []);

    useEffect(() => {
      if (orders?.length > 0 && products?.length > 0) {
        setOrdersLoading(true);
        setSalesOrderLineItems(orderLineItems);
        setOrdersLoading(false);
      }
    }, [localOrders, orders, orderLineItems, reloadCleanData, products]);

    useEffect(() => {
      let aux = [];
      localOrders.map((lo) => aux.push(...lo.order_line_item_ids));
      const selectedLi = salesOrderLineItems.filter((li) => aux.includes(li.id));
      calculateTotals(selectedLi);
      // eslint-disable-next-line
    }, [localOrders]);

    useEffect(() => {
      if(dateScope != ""){
        setOrdersLoading(true);
        loadAllOrders(dateScope);
      }
    }, [dateScope]);

    const calculateTotals = (selectedLineItems) => {
      setTotalQuantity(getCount(selectedLineItems));
      setTotalSales(getAmount(selectedLineItems));
    };

    const loadAllOrders = (dateScope = "week") => {
      PurchaseOrdersService.loadOrders(dateScope).then((response) => {
          setOrders(response.data.purchase_orders);
          setLocalOrders(response.data.purchase_orders);
          setAllLocalOrders(response.data.purchase_orders);
          setOrderLineItems(response.data.purchase_order_line_items);
          setSalesOrderLineItems(response.data.purchase_order_line_items);
          setProducts(response.data.products);
          setOrdersLoading(false);
        })
        .catch((error) => {
          console.log(error);
      });
      const filteredOrders = orders;
      return filteredOrders;
    };

    const loadOrdersData = (startDate, endDate) => {
      const ordersFiltered = localOrders.filter(
        (lo) => lo.issued_at <= endDate && lo.issued_at >= startDate
      );

      const ordersLoad = [];
      // eslint-disable-next-line
      ordersFiltered.map((order) => {
        let items = salesOrderLineItems.filter((oli) => order.order_line_item_ids.includes(oli.id));
        if (getCount(items) > 0) {
          ordersLoad.push(order);
        }
      });
      return ordersLoad;
    };

    const setOrdersDataByDate = (startDate, endDate) => {
      const ordersLoad = loadOrdersData(startDate, endDate);
      setLocalOrders(ordersLoad);
    };

    const getProductBySku = (sku, products) => {
      return products.find((p) => p.seller_sku_code == sku);
    };

    const getCount = (orderLineItems) => {
      let count = 0;
      if (orderLineItems) {
        orderLineItems.map((orderLineItem) => {
          if (orderLineItem.quantity) {
            count += parseInt(orderLineItem.quantity);
          }
          return null;
        });
      }

      return count;
    };

    const getAmount = (orderLineItems) => {
      let amount = 0;
      if (orderLineItems.length > 0) {
        orderLineItems.map((orderLineItem) => {
          amount += parseInt(orderLineItem.quantity) * parseFloat(orderLineItem.price);
          return null;
        });
      }

      return amount;
    };

    const resetBulkUploadInfo = () => {
      setBulkUploadBegin("");
      setBulkUploadSummary("");
      setBulkUploadErrors([]);
      setBulkUploadEnd("");
      return true;
    };

    const bulkUploadInfo = () => {
      let errors = "";
      bulkUploadErrors.forEach((error) => {
        errors = errors + error + "\n";
      });
      return(
        <div>
          <p>{bulkUploadBegin}</p>
          <p>{bulkUploadSummary}</p>
          <p>{errors}</p>
          <p>{bulkUploadEnd}</p>
        </div>
      );
    }

    const bulkUploadHandler = (values, actions) => {
      createNotification('warning', 'Uploading your excel');
      setBulkUploadBegin("Uploading excel, this might take a while...");
      return PurchaseOrdersService.bulkCreation(values.document)
        .then((response) => {
          if (response.data.errors) {
            response.data.errors.forEach((error) => {
              createNotification('error', error);
            });
            setBulkUploadEnd("Your file could not be processed");
          } else {
            setBulkUploadSummary(response.data.length + " purchase orders were created/updated")
            setBulkUploadEnd("Your file has been processed correctly");
            createNotification('success', 'Your file has been processed');
            loadAllOrders();
          }
        })
        .catch((error) => {
          setBulkUploadErrors(error.response.data.errors);
          setBulkUploadEnd("Your file could not be processed");
          error.response.data.errors.forEach((error) => {
            createNotification('error', error);
          });
        });
    };

    const exportAllHandler = (dateScope,template = false, adminView) => {
      createNotification('success', 'Your orders are being exported. The file will be downloaded in a while');
      PurchaseOrdersService.getPurchaseOrdersXLSX(dateScope, template, adminView).then((response) => {
        const download = new FileDownload('purchase_orders.xlsx', response.data);
        download.downloadFile();
      });
    };

    const displayNewPurchaseOrderButton = () =>{
      if(!adminView){
        return(
          <Link className="btn btn-primary" to="/purchase_orders/new" variant="button">
            Create New Purchase Order
          </Link>
        );
      }
    };

    const displayBulkUploadButton = () =>{
      if(!adminView){
        return(
          <BulkUpload
              fileTypes=".xlsx, .csv"
              format={
                <div>
                  <p>
                    Click <a rel="noopener noreferrer" href="#" onClick={() => exportAllHandler(dateScope, true, adminView)}>here</a> to download a new blank template.
                  </p>
                </div>
              }
              handleSubmit={(values, actions) => {
                return bulkUploadHandler(values, actions);
              }}
              messages={bulkUploadInfo()}
              messagesReset={resetBulkUploadInfo}
              title="New Purchase Orders"
              onClose={loadAllOrders}
              />
        );
      }
    };


  
    if (ordersLoading) {
      return (
        <Row>
          <Col>
            <Spinner loading={ordersLoading} />
          </Col>
        </Row>
      );
    }
    return (
      <>
        <Row>
          <Col lg="2">
            <DateSelector
              loadData={setOrdersDataByDate}
              setSelectedDate={setSelectedDate}
              setDateScope={setDateScope}
              dateScope={dateScope}
            />
          </Col>
          <Col>
            <PurchaseOrdersSearch
              collection={orders}
              allCollection={allLocalOrders}
              loadInitialOrders={localOrders}
              setCollection={setLocalOrders}
              companies={company}
              user={user}
              setSearchField={setSearchField}
              searchField={searchTerm}
            />
          </Col>
        </Row>
        <Row style={{ margin: '10px 20px', padding: '0px' }}>
          <Col>
            {displayNewPurchaseOrderButton()}
            {displayBulkUploadButton()}
            <Button variant="primary" style={{ float: 'right', marginRight: '10px' }} onClick={() => { exportAllHandler(dateScope, false, adminView);}}>
              Export All
            </Button>
          </Col>
        </Row>
        <PaginateComponent
          componentList={
            <PurchaseOrdersTable
              collection={orders}
              salesOrderLineItems={salesOrderLineItems}
              products={products}
              companies={company}
              user={user}
              getCount={getCount}
              getAmount={getAmount}
              getProductBySku={getProductBySku}
              totalQuantity={totalQuantity}
              totalSales={totalSales}
            />
          }
          list={localOrders}
          perPage={perPage}
          containerClassName="pagination-reduced"
        />
        <AdjustPaginateButtons
          list={localOrders}
          perPageList={[10, 50]}
          setPerPage={setPerPage}
        />
      </>
    );
};
  
PurchaseOrdersComponent.propTypes = {
    logOut: PropTypes.func.isRequired,
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
};
  
export default PurchaseOrdersComponent;
  