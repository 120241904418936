import React from 'react';
import { Tabs } from 'react-bootstrap';
import './Tabs.css';

/**
 * Encapsulates react-bootstrap Tabs component.
 * Gets defaultActiveKey from location.hash. If not present you can control the defaultActiveKey with
 * defaultTab prop (location must be a prop).
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TabsComponent = (props) => {
  const { children, location, defaultTab } = props;
  const defaultActiveKey = (location && location.hash) ? location.hash.substring(1) : defaultTab;

  return (
    <Tabs defaultActiveKey={defaultActiveKey} {...props}>
      {children}
    </Tabs>
  );
};

export default TabsComponent;
