import React from 'react';
import { Link } from 'react-router-dom';
import Can from '../roles/Can';

const NavLink = ({ user, location, perform, path, Icon, label, searchTerm, products }) => {
  return (
    <>
      <Can
        role={user.type.toLowerCase()}
        perform={perform}
        yes={() => (
          <div className="navbar-nav">
            <Link
              className={`nav-link ${
                location && location.pathname === path ? 'active' : ''
              }`}
              to={{pathname: path, state: {searchTerm: searchTerm, products: products}}}
            >
              {Icon}
              <span className="nav-text">{label}</span>
            </Link>
          </div>
        )}
        no={() => null}
      />
    </>
  );
};

export default NavLink;
