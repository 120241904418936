import React from 'react';
import { Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import FlatButton from '../common/ui/FlatButton';
import Field from '../common/ui/OuterField';
import ErrorMessage from '../common/ui/ErrorMessage';
import SharedLinks from './SharedLinks';

const SignUpFormComponent = (props) => {
  return (
    <>
      <Container>
        <Form id="signUpForm">
          <Row>
            <Col>
              <div className="">
                <Field
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  setFieldValue={props.setFieldValue}
                />
                <ErrorMessage component="small" name="email" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="">
                <Field
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  setFieldValue={props.setFieldValue}
                />
                <ErrorMessage component="small" name="password" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <Field
                  type="password"
                  name="password_confirmation"
                  className="form-control"
                  placeholder="Password"
                  setFieldValue={props.setFieldValue}
                />
                <ErrorMessage component="small" name="password_confirmation" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="">
                <FlatButton
                  variant="primary"
                  type="submit"
                  disabled={props.isSubmitting}
                >
                  Sign up
                </FlatButton>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      <SharedLinks login signUp={false} forgotPassword={false} confirmation />
    </>
  );
};

export default SignUpFormComponent;
