import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import deliveryLogo from '../../../assets/images/delivery.png';

const TrackingComponent = ({}) => {

  return (
    <Container fluid>
      <Row>
        <Col md="12" lg="3"></Col>
        <Col md="12" lg="6">
            <div className="flex-centered">                
                <a target="_blank" href="https://app.detrack.com/dashboard/#/tracking/s/1b12b5d2680d1230fed04620c0dfe26a68be104c">
                    <img alt="logo" height={400} src={deliveryLogo}></img>
                </a>
                <h5>Wondering when your parcel will arrive? Please click on this handy link below. </h5>
            </div>
        </Col>
        <Col md="12" lg="3"></Col>
      </Row>
    </Container>
  );
};

TrackingComponent.propTypes = {
};

export default TrackingComponent;
