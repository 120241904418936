import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import Select from '../../../components/common/ui/Select';
import Button from '../../../components/common/ui/Button';
import CompaniesService from '../../../services/data/companies_service';
import OrdersService from '../../../services/data/orders_service';
import { createNotification } from '../../../components/common/notifications';

const SaleOrderSchema = Yup.object().shape({
  company: Yup.object(),
});

const SalesOrderManagement = () => {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    CompaniesService.loadCompanies().then((response) => {
      setCompanies(
        response.data.companies.map((company) => {
          return {
            label: company.name,
            value: company.id,
            address_id: company.address_ids[0],
          };
        })
      );
    });
  }, []);
  return (
    <Formik
      initialValues={{
        subject: '',
        body: '',
      }}
      validationSchema={SaleOrderSchema}
      onSubmit={(values, actions) => {
        if (values.company.address_id !== undefined) {
          OrdersService.createOrder({
            address_id: values.company.address_id,
            company_id: values.company.value,
          })
            .then((response) => {
              createNotification(
                'success',
                'You have created an order for this Company'
              );
            })
            .catch((error) => {
              createNotification(
                'error',
                'Error creating the Order for the selected Company'
              );
            });
        } else {
          createNotification(
            'error',
            'Cannot create an Order for this Company. Please create at least one address attached to that Company.'
          );
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form id="contactNew">
          <Container fluid>
            <Row>
              <Col>
                <label>Company</label>
                <Select
                  id="companySelect"
                  name="company"
                  options={companies}
                  onChange={(val) => setFieldValue('company', val)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  id="submitOrder"
                  variant="primary"
                  type="submit"
                  style={{ marginTop: '15px' }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default SalesOrderManagement;
