import axios from '../axios';

class CompanyAgreementsService {
    static loadCompanyAgreements = () => axios.get('/company_agreements');

    static createCompanyAgreement = (values) => {
        let formData = new FormData();
        formData.append('document', values.document);
        return axios.post('/company_agreements', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    static updateCompanyAgreement = (id, values) => {
        let formData = new FormData();
        formData.append('document', values.document);
        return axios.patch(`/company_agreements/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    static deleteCompanyAgreement = (id) => axios.delete(`/company_agreements/${id}`);
}

export default CompanyAgreementsService;