import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import LocationsService from '../../services/data/locations_service';
import AddressesService from '../../services/data/address_service';
import CertificationsService from '../../services/data/certifications_service';
import Overview from './extra_information/Overview';
import Wharehouses from './extra_information/Wharehouses';
import Companies from './extra_information/Companies';
import Certifications from './extra_information/Certifications';
import Dimensions from './extra_information/Dimensions';
import Costing from './extra_information/Costing';
import ProductStock from './ProductStock';

const ProductExtraInformationComponent = ({ product, enabled, company, readonly }) => {
  const [, setLocations] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [wharehouses, setWharehouses] = useState([]);
  const [, setLoadingLocations] = useState(true);
  const [, setLoadingCertifications] = useState(true);

  /* istanbul ignore next */
  const loadLocations = useCallback(() => {
    LocationsService.loadLocations()
      .then((response) => {
        const allLocations = response.data.locations;
        if (product) {
          const productLocationsIds = product.variant_data.locations.map(
            (location) => location.location_id
          );
          let filteredLocations = allLocations.filter((location) =>
            productLocationsIds.includes(location['id'])
          );
          setLocations(filteredLocations);
          setLoadingLocations(false);
        }
      })
      .catch((error) => console.log(error));
  }, [product]);

  /* istanbul ignore next */
  const loadAddressesWharehouses = useCallback(() => {
    AddressesService.loadAddresses()
      .then((response) => {
        const wharehouses = response.data.addresses.filter(
          (address) => address.address_type === 'warehouse'
        );
        setWharehouses(wharehouses);
      })
      .catch((error) => console.log(error));
  }, []);

  /* istanbul ignore next */
  const loadCertifications = useCallback(() => {
    if (product) {
      CertificationsService.loadCertifications(product.id)
        .then((response) => {
          setCertifications(response.data.certifications);
          setLoadingCertifications(false);
        })
        .catch((error) => console.log(error));
    }
  }, [product]);

  useEffect(() => {
    loadLocations();
    loadCertifications();
    loadAddressesWharehouses();
    // eslint-disable-next-line
  }, []);

  return (
    <Tabs defaultActiveKey="basic" id="productExtraInformation">
      <Tab eventKey="basic" title="Description" disabled={!enabled}>
        <Overview disabled={!enabled} product={product} readonly={readonly} />
      </Tab>
      <Tab
        eventKey="certifications"
        title="Certifications documents"
        disabled={!enabled}
      >
        <Certifications
          certifications={certifications}
          loadCertifications={loadCertifications}
          readonly={readonly}
          product={product}
        />
      </Tab>
      <Tab eventKey="dimensions" title="Dimensions" disabled={!enabled}>
        <Dimensions
          dimension={product}
          productId={product && product.id}
          readonly={readonly}
        />
      </Tab>
      <Tab eventKey="wharehouse" title="Warehouse" disabled={!enabled}>
        <Wharehouses wharehouses={wharehouses} product={product} readonly={readonly} />
      </Tab>
    </Tabs>
  );
};

export default ProductExtraInformationComponent;
