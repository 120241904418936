import React from 'react';
import { Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from 'react-spinners/FadeLoader';
import Field from '../common/ui/OuterField';
import ErrorMessage from '../common/ui/ErrorMessage';
import FlatButton from '../common/ui/FlatButton';
import SharedLinks from './SharedLinks';

const LoginFormComponent = (props) => {
  return (
    <>
      <Container fluid>
        <Form id="loginForm">
          <Row>
            <Col>
              <div className="">
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  setFieldValue={props.setFieldValue}
                />
                <ErrorMessage component="small" name="email" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="form-control"
                  setFieldValue={props.setFieldValue}
                />
                <ErrorMessage component="small" name="password" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="" style={{textAlign: 'center'}}>
                {props.isLogin ? (
                  <Spinner
                    loading={props.isLogin}
                    css={{ display: 'inline-block' }}
                  />
                ) : (
                  <FlatButton
                    variant="primary"
                    type="submit"
                    disabled={props.isSubmitting}
                    style={{ float: 'right' }}
                  >
                    Log in
                  </FlatButton>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      <SharedLinks
        login={false}
        signUp={true}
        forgotPassword={true}
        confirmation={false}
        anyProblem={true}
      />
    </>
  );
};

export default LoginFormComponent;
