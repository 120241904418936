import React, { useState, useEffect } from 'react';
import Table from '../common/ui/Table';
import Button from '../common/ui/Button';
import UsersService from '../../services/data/users_service';
import { createNotification } from '../common/notifications';
import User from './User';
import UserNew from './UserNew';

const UserListComponent = () => {
  const [users, setUsers] = useState([]);
  const [showUserNew, setShowUserNew] = useState(false);

  /* istanbul ignore next */
  const loadUsers = () => {
    UsersService.loadUsers()
      .then((response) => {
        setUsers(response.data.sort((a,b) => a.id - b.id));
      })
      .catch((error) => console.log(error));
  };

  /* istanbul ignore next */
  const updateUserValidated = (id, value) => {
    UsersService.updateUserValidated(id, value);
  };

  /* istanbul ignore next */
  const deleteUser = (id) => {
    return UsersService.deleteUser(id).then((response) => {
      createNotification('success', 'You have deleted the admin');
      loadUsers();
    }).catch(error => console.log(error));
  };

  /**
   * Toggle deny status of user.
   * @param user
   */
  /* istanbul ignore next */
  const toggleDenyUser = (user) => {
    return UsersService.toggleDeny(user).then((response) => {
      createNotification('success', 'User deny status changed.');
      loadUsers();
    }).catch(error => console.log(error));
  };

  /* istanbul ignore next */
  const createUser = (values) => {
    return UsersService.createUser(values).then((response) => {
      createNotification('success', 'You have created an admin');
      loadUsers();
    }).catch(error => console.log(error));
  };

  /* istanbul ignore next */
  const closeShowUserNew = () => {
    setShowUserNew(false);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      <h4>Users</h4>
      <Button variant="primary" onClick={() => setShowUserNew(!showUserNew)}>Create User</Button>
      <UserNew showUserNew={showUserNew} closeShowUserNew={closeShowUserNew} handleSubmit={createUser}/> 
      <Table id="userList" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>id</th>
            <th>Email</th>
            <th>Type</th>
            <th>Validated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users && users.map((user) => (
            user.deleted_at == null &&
              <User
                key={user.id}
                user={user}
                updateUserValidated={updateUserValidated}
                deleteUser={deleteUser}
                toggleDenyUser={toggleDenyUser}
              />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default UserListComponent;
