import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoginService from '../../services/login';
import { createNotification, delayedRedirect } from '../common/notifications';
import Form from './ConfirmationForm';
import OuterLayout from '../layout/OuterLayout';

const ConfirmationSchema = Yup.object().shape({
  email: Yup.string().required(),
});

const ConfirmationComponent = () => {
  /* istanbul ignore next */
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    LoginService.emailConfirmation(values)
      .then((response) => {
        actions.setSubmitting(false);
        if (response.status === 200) {
          createNotification(
            'success',
            'You will receive instructions to change your password if the email you provided is correct'
          );
          delayedRedirect('/');
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key) => {
            return createNotification(
              'error',
              key + ' ' + error.response.data.errors[key]
            );
          });
        }
      });
  };

  return (
    <OuterLayout externalContentWrapperStyles={{ marginTop: '290px' }}>
      <Formik
        initialValues={{
          email: '',
        }}
        enableReinitialize
        validationSchema={ConfirmationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => (
          <Form
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={submitForm}
          />
        )}
      </Formik>
    </OuterLayout>
  );
};

export default ConfirmationComponent;
