import React from 'react';
import PropType from 'prop-types';
import { Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import CountrySelectorInput from '../common/ui/CountrySelectorInput';
import Button from '../common/ui/Button';
import Field from '../common/ui/Field';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';
import UnitOfMeasureSelectorInput from '../common/ui/UnitOfMeasureSelectorInput';
import TemperatureOfStorageSelectorInput from '../common/ui/TemperatureOfStorageSelectorInput';

const tooltipStyles = css`
  padding: 20px;
  color: black;
  border: 1px solid black !important;

  :before {
    border-top: 8px solid black !important;
  }
`;

const ProductNewFormComponent = ({
  setFieldValue,
  isSubmitting,
  enabled,
  values,
}) => {
  /* istanbul ignore next */
  const handleCustomInputByType = (key, val) => {
    setFieldValue(key, val);
  };

  return (
    <Container fluid style={{ position: 'relative', height: '70vh' }}>
      <Form id="productNew">
        <Row style={{marginTop: '15px'}}>
          <Col md="6" lg="4">
            <div className="form-group row">
              <Field name="name_creation" type="text" placeholder="" label="Name" />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <label className="form-field-label" style={{ marginTop: '-2px' }}>
                Seller SKU Code
              </label>
              <Field
                name="sellerSkuCode"
                type="text"
                placeholder=""
                style={{ marginLeft: '13px' }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Col md="6" lg="4">
            <div className="form-group row">
              <Field name="brand" type="text" placeholder="" label="Brand" />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field name="upc" type="text" placeholder="" label="Barcode" style={{ marginLeft: '13px' }} />
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Col md="6" lg="4">
            <div className="form-group row">
              <label className="form-field-label" style={{ marginTop: '5px' }}>
                Country
              </label>
              <CountrySelectorInput
                valueType={'short'}
                onChangeHandler={(val) => {
                  handleCustomInputByType('country', val.value);
                }}
                defaultCountry={values && values.country}
                selectedCountry={values && values.country}
                className="col-sm-9 no-padding"
              />
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="form-group row">
              <Field
                name="hsCode"
                type="text"
                placeholder=""
                label="HS Code"
                style={{ marginLeft: '13px' }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: '25px'}}>
          <Col md="6" lg="4">
            <div className="form-group row">
              <label className="form-field-label" style={{ marginTop: '-3px' }}>
                Temperature of Storage
              </label>
              <TemperatureOfStorageSelectorInput
                name="temperatureOfStorage"
                className="inline-field col-sm-9 no-padding"
                onChangeHandler={(unit) =>
                  setFieldValue('temperatureOfStorage', unit.value)
                }
              />
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="form-group row">
              <label className="form-field-label" data-tip data-for="formatInfoManufacture" style={{ marginTop: '-5px' }}>
                  <ReactTooltip
                    id="formatInfoManufacture"
                    effect="solid"
                    type="light"
                    css={tooltipStyles}
                    offset={{bottom: 30}}
                  >
                    <p>If your product has no expiry date, please keep it as 999</p>
                  </ReactTooltip>
                  Manufacturing Shelf Life
                  <FontAwesomeIcon
                    style={{marginLeft: '3px'}}
                    icon={faQuestionCircle}
                  >
                  </FontAwesomeIcon>
                </label>
              <Field
                name="manufactureShelfLife"
                type="number"
                placeholder="(Days)"
                style={{ marginLeft: '13px' }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Col>
            <div className="form-group row">
              <label className="form-field-label" data-tip data-for="formatInfo">
                <ReactTooltip
                  id="formatInfo"
                  effect="solid"
                  type="light"
                  css={tooltipStyles}
                >
                  <ul>
                    Please input the format corresponding to the smallest item that has a barcode:
                    <li>a bottle of olive oil: 1x750ML</li>
                    <li>a packet of cookies: 1x100G</li>
                    <li>a milkshake of 100ML with no barcode, but with a barcode on the pack of 6: 6 x 100ML</li>
                  </ul>
               </ReactTooltip>
                Format  
                <FontAwesomeIcon
                  style={{marginLeft: '3px'}}
                  icon={faQuestionCircle}
                >
                </FontAwesomeIcon>
                </label>
              <div>
                <Field
                  name="singleQuantity"
                  type="number"
                  placeholder="Quantity per UOM"
                  className=""
                  noCol
                />
              </div>
              <div className="field-separator">X</div>
              <div style={{ paddingLeft: '-10px' }}>
                <Field
                  name="measure_value"
                  type="number"
                  placeholder="Measure Value"
                  className=""
                  style={{ width: '175px' }}
                  noCol
                />
              </div>
              <div>
                <UnitOfMeasureSelectorInput
                  name="units_of_measure"
                  className="units-of-measure-container"
                  onChangeHandler={(val) =>
                    handleCustomInputByType('units_of_measure', val.value)
                  }
                />
              </div>
              {/*</Row>*/}
            </div>
          </Col>
        </Row>
        <div className="actions">
          {!enabled && (
            <i style={{ float: 'left', marginTop: '6px' }}>
              Please click submit to create the product before filling more
              information below
            </i>
          )}
          <Button
            style={{marginLeft: '15px'}}
            variant="primary"
            type="submit"
            className="float-right"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Container>
  );
};

ProductNewFormComponent.propTypes = {
  isSubmitting: PropType.bool,
  setFieldValue: PropType.func,
};

export default ProductNewFormComponent;
