import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { boolean } from 'yup';
import usePrevious from '../../../hooks/usePrevious';

const EditableInputComponent = ({
  field,
  label,
  noLabel,
  inputComponent,
  labelContainerClassName,
  handleSubmit,
  setFieldValue,
  text,
  direction,
  disabled,
  placeholder,
}) => {
  const [editable, setEditable] = useState(false);
  const [localText, setLocalText] = useState('');
  const [oldText, setOldText] = useState('');
  const prevText = usePrevious(oldText);

  const displayText = () => {
    let text = typeof localText === boolean ? localText.toString() : localText;
    text = typeof text === 'object' ? text.join(', ') : text;
    if (!text && placeholder) {
      text = placeholder;
    }
    return (
      <span
        style={
          noLabel
            ? { whiteSpace: 'pre-line' }
            : { marginTop: '8px', whiteSpace: 'pre-line' }
        }
        className={
          text === placeholder ? 'font-weight-light font-italic text-muted' : ''
        }
      >
        {text}
      </span>
    );
  };

  /* istanbul ignore next */
  const handleEditClick = (event) => {
    event.preventDefault();
    setEditable(true);
  };


  /* istanbul ignore next */
  const handleAcceptClick = (event) => {
    event.preventDefault();
    handleSubmit();
    setEditable(false);
  };

  /* istanbul ignore next */
  const handleCancelClick = (event) => {
    event.preventDefault();
    setLocalText(prevText);
    setOldText(prevText);
    setFieldValue(field, prevText);
    setEditable(false);
  };

  const displayLabel = () => {
    if (noLabel) {
      return null;
    }
    return (
      <div
        className={`form-group edit-field-label-container ${
          labelContainerClassName ? labelContainerClassName : ''
        }`}
      >
        {label && direction === 'block' ? (
          <label className="form-field-label" style={{ marginRight: '5px' }}>
            {label}
          </label>
        ) : (
          <label className="form-field-label" style={{ marginRight: '5px', minHeight: '15px' }}>
            {label}
          </label>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (typeof text === 'boolean') {
      setLocalText(text.toString());
      if (prevText === '') {
        setOldText(text.toString());
      }
    } else {
      setLocalText(text);
      if (prevText === '') {
        setOldText(text);
      }
    }
  }, [text, prevText]);

  let TextStyle = { flex: '2' };
  if (noLabel) {
    TextStyle = { flex: '2', justifyContent: 'flex-end', display: 'flex' };
  }
  if (noLabel && !editable) {
    TextStyle = { flex: '0', justifyContent: 'center', display: 'flex' };
  }

  let editFieldActions = { flex: '0.4' };
  if (noLabel) {
    editFieldActions = {
      flex: '1',
      justifyContent: 'flex-start',
      display: 'flex',
      marginLeft: '5px',
    };
  }
  if (noLabel && !editable) {
    editFieldActions = { justifyContent: 'center', marginLeft: '5px' };
  }

  return (
    <div className={noLabel ? '' : 'form-group'}>
      <Container fluid>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {displayLabel()}
          <div style={TextStyle}>
            {editable ? inputComponent : displayText()}
          </div>
          <div
            className="edit-field-actions-container"
            style={editFieldActions}
          >
            <div className={noLabel ? '' : 'float-right'}>
              {editable ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size={22}
                    onClick={(event) => handleAcceptClick(event)}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    size={22}
                    style={{ marginLeft: '2px' }}
                    onClick={(event) => handleCancelClick(event)}
                  />
                </>
              ) : (
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  size={22}
                  onClick={(event) => {
                    /* istanbul ignore next */
                    if (!disabled) {
                      handleEditClick(event);
                    }
                  }}
                  className={disabled ? 'text-muted' : ''}
                />
              )}
            </div>
          </div>
        </Row>
      </Container>
      <ErrorMessage component="small" name={field} />
    </div>
  );
};

EditableInputComponent.propTypes = {
  inputComponent: PropTypes.any,
  field: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.any,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default EditableInputComponent;
