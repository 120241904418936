import axios from '../axios';

class ShipmentItemsService {
  static loadShipmentItemsFromCSV(file) {
    const formData = new FormData();
    if (file) {
      formData.append('shipment_items[shipment_items_file]', file);
    }
    return axios.post('/shipment_items', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default ShipmentItemsService;
