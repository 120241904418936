import React from 'react';
import { Field } from 'formik';

const NormalFieldComponent = (props) => {
  const { name, type, className, placeholder, as, children, label, style } = props;

  const clNames = type === 'file' ? '' : `form-control ${className}`;

  return (
    <>
      <label>{label}</label>
      <Field
        name={name}
        type={type}
        as={as}
        className={clNames}
        placeholder={placeholder}
        style={style}
      >
        {children}
      </Field>
    </>
  );
};

export default NormalFieldComponent;
