import axios from '../axios';

class PurchaseOrdersService {
  static loadOrders(scope = "week", admin_view = false) {
    return axios.get(`/purchase_orders?scope=${scope}&admin_view=${admin_view}`);
  }

  static getPurchaseOrdersXLSX(scope = "week", template = false ,admin_view = false) {
    return axios.request({
      url: `/purchase_orders.xlsx?scope=${scope}&template=${template}&admin_view=${admin_view}`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  static loadPurchaseOrder(id) {
    return axios.get(`/purchase_orders/${id}`);
  }

  static createPurchaseOrder(data) {
    return axios.post('/purchase_orders', this._purchaseOrderObject(data));
  }

  static updatePurchaseOrder(id, data) {
    return axios.patch(`/purchase_orders/${id}`, this._purchaseOrderObject(data));
  }

  static cancelPurchaseOrder(id) {
    let purchase_order_object = { 
      purchase_order:{ 
        status: "cancelled",
      }
    }
    return axios.patch(`/purchase_orders/${id}`, purchase_order_object);
  }

  /**
   * Send XLSX file to backend for orders bulk creation.
   * @param file Object from input type file
   */
  static bulkCreation(file) {
    let formData = new FormData();
    if (file){
      formData.append('purchase_order[purchase_orders_file]', file);
    }
    return axios.post('purchase_orders', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * @private
   * Get a purchase order object from a form to be sent to backed.
   * @param data Object with purchase order information
   * @returns {purchase_order_object}
   */
  static _purchaseOrderObject(data) {
    let purchase_order_object = { 
      purchase_order:{ 
        order_number: data.orderNumber,
        issued_at: data.orderDate,
        due_at: data.deliveryDate,
        billing_address_id: data.pickingLocation && parseInt(data.pickingLocation.value),
        shipping_address_id: parseInt(data.deliveryLocation.value),
        purchase_order_line_items_attributes: 
          data.item_data.map((item) => ({
            seller_sku_code: item.seller_sku_code,
            quantity: item.quantity,
            price: item.price,
            expiry_date: item.expiry_date,
         }))
      }
    }
    return purchase_order_object;
  }
}

export default PurchaseOrdersService;
