import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const DocumentWithLinkInputComponent = ({ label, onChangeHandler, defaultValue }) => {
  const [localDocument, setLocalDocument] = useState('');

  const handleImageChange = (event) => {
    setLocalDocument(URL.createObjectURL(event.target.files[0]));
  };

  const displayPreviewDocument = () => {
    if (localDocument !== '' && localDocument !== undefined) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={localDocument}>
          {label ? label : 'Document'}
        </a>
      );
    }
    return null;
  };

  useEffect(() => {
    if (defaultValue !== '' && localDocument === '') {
      setLocalDocument(defaultValue);
    }
  }, [defaultValue, localDocument]);

  return (
    <>
      {displayPreviewDocument()}
      <Field
        name="email"
        render={({ field, form }) => (
          <div className="form-group">
            <input
              name="document"
              type="file"
              accept="image/jpeg,image/gif,image/png,application/pdf"
              onChange={(event) => {
                handleImageChange(event);
                onChangeHandler(event.target.files[0]);
              }}
            />
            <br></br>
            {form.errors.document && form.errors.document}
          </div>
        )}
      />
    </>
  );
};

DocumentWithLinkInputComponent.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  onChangeHandler: PropTypes.func,
};

export default DocumentWithLinkInputComponent;
