import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AddressesService from '../../../services/data/address_service';
import { createNotification } from '../../common/notifications';
import Modal from '../../common/ui/Modal';
import Form from './AddressForm';

const AddressEditSchema = Yup.object().shape({
  address1: Yup.string().required(),
  address2: Yup.string(),
  postal_code: Yup.string(),
  type: Yup.mixed().oneOf(['office', 'warehouse', 'port_of_landing']),
  country_code: Yup.string(),
  label: Yup.string().required(),
  name: Yup.string(),
  state: Yup.string(),
});

const AddressEditComponent = (props) => {
  const [address, setAddress] = useState();
  const {
    addressId,
    closeEditLocationModal,
    loadAddresses,
    showEditLocation,
  } = props;

  /* istanbul ignore next */
  const handleEditSubmit = (values, actions) => {
    AddressesService.updateAddress(addressId, values)
      .then((response) => {
        createNotification('success', 'You have updated the address');
        closeEditLocationModal();
        loadAddresses();
      })
      .catch((error) => {
        console.log(error);
        createNotification('danger', 'Error updating the address');
      });
  };

  /* istanbul ignore next */
  const handleCustomInputByType = (setFieldValue, key, val) => {
    setFieldValue(key, val);
  };

  /* istanbul ignore next */
  useEffect(() => {
    AddressesService.loadAddress(addressId)
      .then((response) => {
        setAddress(response.data.address);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [addressId]);

  return (
    <Formik
      initialValues={{
        address1: address && address.address1,
        address2: address && address.address2,
        postal_code: address && address.zip_code,
        type: address && address.address_type,
        city: address && address.city,
        country_code: address && address.country_code,
        label: address && address.label,
        state: address && address.state,
      }}
      validationSchema={AddressEditSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleEditSubmit(values, actions);
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeEditLocationModal}
          onCancel={closeEditLocationModal}
          title="Location Edit"
          show={showEditLocation}
        >
          <Form
            id="addressEdit"
            handleCustomInputByType={handleCustomInputByType}
            setFieldValue={setFieldValue}
            address={values}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default AddressEditComponent;
