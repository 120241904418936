import React from 'react';
import GenericlModal from './GenericModal';

const ModalComponent = (props) => {
  const { children, show, onHide, title, onCancel, loading, next, previous, position } = props;
  var buttons = [];

  if (children.type !== "img"){
    buttons.push(
      {
        id: 'close',
        show: true,
        variant: 'secondary',
        text: 'Close',
        onClick: onCancel,
      },
      {
        id: 'submit',
        show: true,
        variant: 'primary',
        type: 'submit',
        text: 'Submit',
        disabled: loading
      }
    );
  }

  return (
    <GenericlModal
      show={show}
      asForm="true"
      header={title}
      loading={loading}
      onHide={onHide}
      buttons={buttons}
      body={children}
      download={children.type === "img"}
      next={next}
      previous={previous}
      position={position}
    />
  );
};

export default ModalComponent;
