import React from 'react';
import styled from '@emotion/styled';
import { ErrorMessage } from 'formik';

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
`;

const OuterErrorMessage = ({ component, name }) => {
  return <StyledErrorMessage component={component} name={name} />;
};

export default OuterErrorMessage;
