import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  createNotification,
  delayedRedirect,
} from '../../../common/notifications';
import Modal from '../../../common/ui/Modal';
import Form from './LicenceEditForm';
import { convertToInputFormat } from '../../../common/dataTypes/dates';
import LicencesService from '../../../../services/data/licences_service';

const LicenceEditSchema = Yup.object().shape({
  name: Yup.string(),
  licence_number: Yup.string(),
  start_date: Yup.date(),
  end_date: Yup.date(),
  document: Yup.string(),
});

const LicenceEdit = ({
  licence,
  showEditLicence,
  closeEditLicence,
  loadLicenses,
}) => {
  const handleUpdateSubmit = (values, actions) => {
    LicencesService.updateLicence(licence.id, values)
      .then((response) => {
        createNotification('success', 'has been updated', 'The license');
        loadLicenses();
        closeEditLicence();
      })
      .catch((error) => {
        delayedRedirect(`/users/edit`);
        createNotification(
          'error',
          'was not possible to update',
          'The license'
        );
        closeEditLicence();
      });
  };
  return (
    <Formik
      initialValues={{
        name: licence.name,
        licence_number: licence.licence_number,
        start_date:
          licence.start_date !== null
            ? convertToInputFormat(new Date(licence.start_date))
            : '',
        end_date:
          licence.end_date !== null
            ? convertToInputFormat(new Date(licence.end_date))
            : '',
        document: '',
      }}
      validationSchema={LicenceEditSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleUpdateSubmit(values, actions);
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeEditLicence}
          onCancel={closeEditLicence}
          title="Edit Licence"
          show={showEditLicence}
        >
          <Form
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            values={values}
            licence={licence}
          />
        </Modal>
      )}
    </Formik>
  );
};
export default LicenceEdit;
