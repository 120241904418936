import React, {
  Children,
  isValidElement,
  cloneElement,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import './paginateComponent.scss';

const handlePageClick = (page, props, setCollection) => {
  const aux = [...props.list];
  const elems = aux.slice(
    page.selected * props.perPage,
    page.selected * props.perPage + props.perPage
  );
  setCollection(elems);
};

const PaginateComponent = (props) => {
  const { componentList, list, perPage, containerClassName } = props;
  const [collection, setCollection] = useState(list);

  // it will inject in componentList the paginated collection
  const children = Children.map(componentList, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { collection });
    }
    return child;
  });

  useEffect(() => {
    if (list) {
      setCollection(list.slice(0, perPage));
    }
  }, [list, perPage]);

  if (list && list.length > 0 && list.length > perPage) {
    return (
      <>
        {children}
        <ReactPaginate
          previousLabel="previous"
          previousClassName="previous page-item"
          previousLinkClassName="page-link"
          nextLabel="next"
          nextClassName="next page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={Math.ceil(list.length / perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(page) => handlePageClick(page, props, setCollection)}
          containerClassName={`pagination ${containerClassName}`}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          activeClassName="active"
        />
      </>
    );
  }
  return <>{children}</>;
};

PaginateComponent.propTypes = {
  componentList: PropTypes.node.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  perPage: PropTypes.number.isRequired,
};

export default PaginateComponent;
