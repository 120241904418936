import React, { useState, useEffect } from 'react';
import { Form, ErrorMessage } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from 'react-spinners/PulseLoader';
import ShipmentItemsService from '../../../services/data/shipment_items_service';
import ShipmentsItemListTable from './ShipmentsItemListTable';
import ShipmentsItemListForm from './ShipmentItemListForm';
import { createNotification } from '../../common/notifications';
import { addWeeks, convertToInputFormat } from '../../common/dataTypes/dates';
import Button from '../../common/ui/Button';
import Field from '../../common/ui/Field';
import Select from '../../common/ui/Select';
import BulkUpload from '../../common/ui/BulkUpload';

const ShipmentsFormComponent = ({
  products,
  addresses,
  locations,
  item_data,
  setFieldValue,
  waitingForShipmentCreation,
  isSubmitting,
  readonly,
  getCost,
  values,
  company
}) => {
  const [visibleCreateNewProduct, setVisibleCreateNewProduct] = useState(false);
  const [itemList, setItemList] = useState([]);

  /* istanbul ignore next */
  const toggleVisibilityCreateNewProduct = () => {
    if (products && products.length > 0) {
      setVisibleCreateNewProduct((oldState) => !oldState);
    } else {
      createNotification('warning', "you don't have products created ");
    }
  };

  /* istanbul ignore next */
  const deleteFromItemList = (index) => {
    let aux = [...itemList];
    aux.splice(index, 1);
    updateFormikField(aux);
  };

  /* istanbul ignore next */
  const updateFormikField = (aux) => {
    setItemList(aux);
    setFieldValue('item_data', aux);
    setFieldValue('cost', getCost(aux));
  };

  /* istanbul ignore next */
  const handleProductAdd = (values, actions) => {
    let aux = [...itemList];
    aux.push({
      productId: values.id !== ''
        ? parseInt(values.id)
        : values.productId,
      productName: values.productName,
      quantity: values.quantity,
      price: values.price,
      expiration_date: values.expiration_date,
    });
    updateFormikField(aux);
  };

  /* istanbul ignore next */
  const displaySpinner = () => {
    if (waitingForShipmentCreation) {
      return (
        <Spinner
          size={15}
          loading={waitingForShipmentCreation}
          css={{ display: 'inline', float: 'left', marginTop: '10px' }}
        />
      );
    }
  };

  /* istanbul ignore next */
  const displayFormAddItems = () => {
    if (visibleCreateNewProduct) {
      if (products && products.length === 0) {
        createNotification('warning', "You don't have products");
        return null;
      }
      return (
        <ShipmentsItemListForm
          products={products}
          handleProductAdd={handleProductAdd}
          toggleVisibilityCreateNewProduct={toggleVisibilityCreateNewProduct}
        />
      );
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    setItemList(
      item_data.map((elem) => {
        return {
          expiration_date: elem.expiration_date,
          price: elem.price,
          productId: elem.product_id,
          productName: elem.product_name,
          quantity: elem.quantity,
        };
      })
    );
  }, [item_data]);

  return (
    <Container fluid style={{ position: 'relative', padding: '0px' }}>
      <Form id="shipmentEdit">
        <Row>
          <Col md="12" lg="4">
            <div className="form-group row">
              <label className="col-sm-3" style={{ marginTop: '0px' }}>
                Picking Location
              </label>
              <Select
                options={addresses.map((address) => {
                  /* istanbul ignore next */
                  return { value: address.id, label: address.label };
                })}
                onChange={(address) => {
                  /* istanbul ignore next */
                  setFieldValue('pickingLocation', { value: address.value, label: address.label });
                }}
                className="col-sm-9 no-padding select-container"
                value={addresses.find((address) => {
                  /* istanbul ignore next */
                  if(values.pickingLocation){
                    return address.id === values.pickingLocation.value;
                  }
                  return null;
                })}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <label className="col-sm-3" style={{ marginTop: '0px' }}>
                Delivery Location
              </label>
              <Select
                options={locations.map((location) => {
                  /* istanbul ignore next */
                  return { value: location.id, label: location.label };
                })}
                onChange={(location) => {
                  /* istanbul ignore next */
                  setFieldValue('deliveryLocation', { value: location.value, label: location.label });
                }}
                className="col-sm-9 no-padding select-container"
                value={locations.find((location) => {
                  /* istanbul ignore next */
                  if(values.deliveryLocation){
                    return location.id === values.deliveryLocation.value;
                  }
                  return null;
                })}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field
                name="cost"
                type="string"
                label={`Order Value (${company?.preferred_currency || 'EUR'})`}
                disabled={true}
                labelColSize={'col-sm-3'}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field
                name="orderDate"
                type="date"
                label="Order Date"
                placeholder="Order Date"
                disabled={readonly}
                labelColSize={'col-sm-3'}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row">
              <Field
                name="pickupDate"
                type="date"
                label="Pickup Date"
                placeholder="Pickup Date"
                disabled={readonly}
                labelColSize={'col-sm-3'}
                onChange={(event) => {
                  /* istanbul ignore next */
                  setFieldValue('pickupDate', event.target.value);
                  /* istanbul ignore next */
                  const deliveryDateValue = addWeeks(
                    new Date(event.target.value),
                    6
                  );
                  /* istanbul ignore next */
                  setFieldValue(
                    'deliveryDate',
                    convertToInputFormat(deliveryDateValue)
                  );
                }}
              />
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="form-group row" style={{ paddingRight: '10px' }}>
              <Field
                name="deliveryDate"
                type="date"
                label="Delivery Date"
                placeholder="Delivery Date"
                disabled={readonly}
                labelColSize={'col-sm-3'}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              {!readonly && (
                <>
                  <Button
                    variant="primary"
                    style={{ marginBottom: '10px' }}
                    onClick={(_) => toggleVisibilityCreateNewProduct()}
                  >
                    Add Item
                  </Button>
                  <BulkUpload
                    fileTypes=".csv"
                    handleSubmit={(values, actions) => {
                      /* istanbul ignore next */
                      ShipmentItemsService.loadShipmentItemsFromCSV(
                        values.document
                      ).then((response) => {
                        response.data.shipment_items.map((si) => {
                          return handleProductAdd(si, actions);
                        });
                      });
                    }}
                    title="new item list"
                    style={{ float: 'right' }}
                    autoClose={true}
                  />
                  <Button
                    variant="primary"
                    style={{ float: 'right', marginRight: '15px' }}
                    onClick={(_) => {
                      /* istanbul ignore next */
                      setItemList([]);
                    }}
                  >
                    Clear Items
                  </Button>
                </>
              )}
              {displayFormAddItems()}
              <ShipmentsItemListTable
                itemList={itemList}
                count={itemList.length}
                products={products}
                deleteFromItemList={deleteFromItemList}
                readonly={readonly}
                company={company}
              />
              <ErrorMessage component="small" name="item_data" />
            </div>
          </Col>
        </Row>
        {!readonly && (
          <div className="actions">
            {displaySpinner()}
            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default ShipmentsFormComponent;
