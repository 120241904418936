import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import InventoryOfflineService from '../../../services/data/offline_inventory_service';
import ProductSearch from '../../common/ui/Search';
import PaginateComponent from '../../common/ui/PaginateComponent';
import FileDownload from '../../common/dataTypes/fileDownload';
import Button from '../../common/ui/Button';
import InventoryTab from './InventoryTab';
import InventoryTable from './InventoryTable';
import { createNotification } from '../../common/notifications';

const InventoryComponent = ({ loadProducts, products, company }) => {
  const [localProducts, setLocalProducts] = useState([]);
  const [productsInStock, setProductsInStock] = useState(0);
  const [productsNoStock, setProductsNoStock] = useState(0);
  const [inventoryValue, setInventoryValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [
    visibleNewStockAdjustmentModal,
    setVisibleNewStockAdjustmentModal,
  ] = useState(false);

  /* istanbul ignore next */
  const setInventory = (
    products,
    productsInStock,
    productsNoStock,
    inventoryValue
  ) => {
    setLocalProducts(products);
    setProductsInStock(productsInStock);
    setProductsNoStock(productsNoStock);
    setInventoryValue(inventoryValue);
  };

  /* istanbul ignore next */
  const setStateProducts = (products) => {
    setLocalProducts(products);
  };

  /* istanbul ignore next */
  const setSearchField = (term) => {
    setSearchTerm(term);
  }

  /* istanbul ignore next */
  const closeNewStockAdjustmentModal = () => {
    setVisibleNewStockAdjustmentModal(false);
  };

  /* istanbul ignore next */
  const showNewStockAdjustmentModal = () => {
    setVisibleNewStockAdjustmentModal(true);
  };

  /* istanbul ignore next */
  const loadProductsService = () => {
    InventoryOfflineService.getInventory().then((response) => {
      loadProducts(response.data.products);
      setLocalProducts(response.data.products);
      setProductsInStock(response.data.in_stock);
      setProductsNoStock(response.data.no_stock);
      setInventoryValue(response.data.value);
    }

    );
  };

  /* istanbul ignore next */
  useEffect(() => {
    loadProductsService();
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <InventoryTab
            allProducts={products}
            products={products}
            productsInStock={productsInStock}
            productsNoStock={productsNoStock}
            inventoryValue={inventoryValue}
            setInventory={setInventory}
            company={company}
          />
        </Col>
      </Row>
      <ProductSearch
        collection={products}
        setCollection={setStateProducts}
        searchableFields={[
          { id: 'seller_sku_code', type: 'text' },
          { id: 'name', type: 'text' },
        ]}
        allCollection={products}
        setSearchField={setSearchField}
        searchField={searchTerm}
      />
      <Row style={{ padding: '0px' }}>
        <Col style={{ padding: '0px' }}>
          <Button
            variant="primary"
            style={{ float: 'right', marginBottom: '15px' }}
            onClick={() => {
              createNotification('success', 'Your inventory is being exported. The file will be downloaded in a while');
              InventoryOfflineService.getInventoryXLSX().then((response) => {
                const download = new FileDownload('inventory.xlsx', response.data);
                download.downloadFile();
              });
            }}
          >
            Export All
          </Button>
        </Col>
      </Row>
      <PaginateComponent
        componentList={
          <InventoryTable
            visibleNewStockAdjustmentModal={visibleNewStockAdjustmentModal}
            showNewStockAdjustmentModal={showNewStockAdjustmentModal}
            closeNewStockAdjustmentModal={closeNewStockAdjustmentModal}
            loadProductsService={loadProductsService}
            company={company}
          />
        }
        list={localProducts}
        perPage={10}
      />
    </Container>
  );
};

InventoryComponent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InventoryComponent;
