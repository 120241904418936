import axios from '../axios';

/**
 * Service to manage labels in backend.
 */
class LabelsService {
  /**
   * Gets all labels
   * @returns {Promise<AxiosResponse<any>>}
   */
  static list() {
    return axios.get('/labels');
  }

  /**
   * Gets a category by its id.
   * @param key label key
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getLabel(key) {
    return axios.get(`/labels?key=${key}`);
  }

  /**
   * Updates a label
   * @param key label key
   * @param message Label message
   * @returns {Promise<AxiosResponse<any>>}
   */
  static updateLabel(label) {
    return axios.patch(
      `/labels/${label.key}`,
      this._formDataFromLabelObject(label),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  /**
   * @private
   * Get a FormData object from a label to be sent to backed.
   * @param label Object with label information
   * @returns {FormData}
   */
  static _formDataFromLabelObject(label) {
    const formData = new FormData();
    formData.append(`label[key]`, label.key);
    formData.append(`label[body]`, label.body);
    return formData;
  }
}

export default LabelsService;
