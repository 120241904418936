import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ContactService from '../../../services/data/contacts_service';
import { createNotification } from '../../common/notifications';
import Modal from '../../common/ui/Modal';
import Form from './ContactForm';
import LoginService from '../../../services/login';

const ContactEditSchema = Yup.object().shape({
  companyId: Yup.number(),
  name: Yup.string().required('Name is required'),
  position: Yup.string(),
  email: Yup.string().email().required('Contact Email is required'),
  allowLogin: Yup.boolean(),
  phone_number: Yup.string(),
});

const ContactEditComponent = (props) => {
  const [contact, setContact] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const {
    company,
    contactId,
    loadContacts,
    closeEditContactModal,
    showEditContact,
  } = props;

  /* istanbul ignore next */
  const handleEditSubmit = (values, actions) => {
    ContactService.updateContact(contactId, values)
      .then((response) => {
        actions.setSubmitting(true);
        createNotification('success', 'You have updated this contact');
        loadContacts();
        closeEditContactModal();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        createNotification('error', error.response.data);
        actions.setSubmitting(false);
      });
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (contactId) {
      ContactService.loadContact(contactId)
        .then((response) => {
          setContact(response.data.contact);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [contactId]);

  /* istanbul ignore next */
  useEffect(() => {
    // Load current user
    LoginService.getUser().then((response) => {
      setCurrentUser(response.data);
    });
  }, [currentUser.id]);

  return (
    <Formik
      initialValues={{
        companyId: company && company.id,
        name: contact.first_name,
        position: contact.position,
        email: contact.email,
        allowLogin: contact.allow_login,
        phone_number: contact.phone_number,
      }}
      validationSchema={ContactEditSchema}
      onSubmit={(values, actions) => {
        /* istanbul ignore next */
        handleEditSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Modal
          onHide={closeEditContactModal}
          onCancel={closeEditContactModal}
          title="Edit Contact"
          show={showEditContact}
        >
          <Form
            id="contactEdit"
            company={company}
            values={values}
            isCurrentUser={currentUser.email === contact.email}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default ContactEditComponent;
