import React from 'react';
import PropTypes from "prop-types";
import Product from './Product';
import Table from '../common/ui/Table';

const ProductTableComponent = (props) => {
    const { collection, deleteProduct, company, priceLists, searchTerm } = props;

  return (
    <Table>
      <thead>
        <tr>
          <th>Seller SKU Code</th>
          <th>Fluetis SKU Code</th>
          <th>Brand</th>
          <th>Name</th>
          <th>Barcode</th>
          <th>Images</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {collection.map((product) => (
          <Product key={product.id} product={product} deleteProduct={deleteProduct}
                  company={company} priceLists={priceLists} searchTerm={searchTerm}
          />
        ))}
      </tbody>
    </Table>
  );
};

ProductTableComponent.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteProduct: PropTypes.func.isRequired,
};

export default ProductTableComponent;
